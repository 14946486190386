import type { CybusDetailedService } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { mapCybusResourceDeviations, mapDetailedService, mapToStatusType } from '../../../../../Connectware'

import type { ServiceProxy } from '../../../../proxies'
import type { VrpcInstanceDomainMapperArgs } from '..'
import { BaseServiceMapper, BaseVrpcServiceProxyInstanceHandler } from './Base'

class DetailedServiceMapper extends BaseServiceMapper<CybusDetailedService> {
    async mapToDomain ({ instance }: VrpcInstanceDomainMapperArgs<ServiceProxy>): Promise<CybusDetailedService> {
        const [params, dependencies] = await Promise.all([instance.getParams(), instance.getDependencies()])

        /**
         * @deprecated update code block so resource deviations is fetched externally
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const resourceDeviations = mapCybusResourceDeviations(params.deviation)

        /**
         * @deprecated this method will no longer provide currentState field
         * @todo consider using another field
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const currentState = mapToStatusType(params.currentState)
        return mapDetailedService(params, currentState, dependencies, resourceDeviations)
    }
}

export class VrpcDetailedServiceProxyInstanceHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['service']> {
    instanceMapper = new DetailedServiceMapper()
}
