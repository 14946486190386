import type { CybusServiceDeviation } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { CybusServiceDeviationMapper } from '../../../../../Connectware'
import type { ServiceProxy } from '../../../../proxies'
import { createProxyEventsHandler, type VrpcInstanceDomainMapperArgs, type VrpcInstanceMapper } from '../Handler'
import { BaseVrpcServiceProxyInstanceHandler } from './Base'

class MapCybusServiceDeviationMapper extends CybusServiceDeviationMapper {
    readonly deviations = new Map<string, CybusServiceDeviation>()

    protected push (deviation: CybusServiceDeviation): void {
        this.deviations.set(deviation.name, deviation)
    }
}

class DeviationMapper implements VrpcInstanceMapper<ServiceProxy, CybusServiceDeviation> {
    /**
     * listen to deviation changes to update Notifications
     * listen to state changes to clear Notifications whenever a deviated Service gets disabled
     * @deprecated these handlings will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServiceProxy>('deviation', 'state')

    mapToDomain ({ instance, instanceName: id }: VrpcInstanceDomainMapperArgs<ServiceProxy>): Promise<Map<string, CybusServiceDeviation>> {
        /**
         * @deprecated this method will no longer provide info about whether service is deviated or not
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getInfo().then(({ isDeviated }) => {
            const mapper = new MapCybusServiceDeviationMapper()
            mapper.add(id, isDeviated)
            return mapper.deviations
        })
    }
}

export class VrpcServiceProxyInstanceToNotificationHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['serviceDeviations']> {
    readonly instanceMapper = new DeviationMapper()
}
