import React, { type FC } from 'react'

import {
    canServerBeDeleted,
    canServerBeDisabled,
    canServerBeEnabled,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusServer,
    type CybusService,
    selectDeletableLoadedServers,
    selectDisablableLoadedServers,
    selectEnabableLoadedServers,
    selectServersPage,
    type StatusType,
    Translation,
} from '../../../domain'

import { useTranslator } from '../Internationalization'
import { RelativeRoutePathWithId } from '../routing'
import { Status, useClearTableSelection, useTableStatusSearcher } from '../common'
import { RedirectingResourcesTable } from '../Resources'
import { DeleteModal, DisableModal, EnableModal } from './Bulk'

const Modals: FC = () => {
    const clear = useClearTableSelection()
    return (
        <>
            <DeleteModal serversSelector={selectDeletableLoadedServers} onConfirmed={clear} />
            <EnableModal serversSelector={selectEnabableLoadedServers} onConfirmed={clear} />
            <DisableModal serversSelector={selectDisablableLoadedServers} onConfirmed={clear} />
        </>
    )
}

const selectCanManageServers = createIsAuthenticatedWithCapabilitiesSelector(Capability.SERVERS_MANAGE)

export const Table: FC<Readonly<{ serviceId?: CybusService['id'] }>> = ({ serviceId = null }) => {
    const translator = useTranslator()

    const searcher = useTableStatusSearcher()
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <RedirectingResourcesTable<CybusServer, CybusServer, 'bulkDeleteServersUsecase' | 'bulkEnableServersUsecase' | 'bulkDisableServersUsecase'>
            subscriptionUsecase="manageServersUsecase"
            filter={serviceId ? { service: serviceId } : undefined}
            data={selectServersPage}
            hasManagementCapabilitiesSelector={selectCanManageServers}
            dataTableMapper={(servers) => servers}
            columns={{
                ...(serviceId ? {} : { service: { label: Translation.SERVICE_ID, sort: true } }),
                name: { label: Translation.NAME, sort: true },
                protocol: { label: translator.formatTranslation(Translation.PROTOCOL, { count: 1 }), sort: true },
                status: { label: Translation.STATUS, searcher, customCellRender, sort: true },
            }}
            short={Boolean(serviceId)}
            redirectOnRowclick={RelativeRoutePathWithId.SERVER}
            selection={{
                DELETE: { usecase: 'bulkDeleteServersUsecase', filter: canServerBeDeleted },
                DISABLE: { usecase: 'bulkDisableServersUsecase', filter: canServerBeDisabled },
                ENABLE: { usecase: 'bulkEnableServersUsecase', filter: canServerBeEnabled },
                resource: 'id',
                usecaseMethod: 'selectServers',
            }}
            translations={{ noResultsOrEmptyTable: Translation.NO_SERVERS_AVAILABLE }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
