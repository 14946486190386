import { isArrayNotEmpty } from '../../../../../../utils'
import type { CybusLinkedService } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import type { ServiceProxy } from '../../../../proxies'
import type { VrpcInstanceDomainMapperArgs } from '..'
import { ServiceMapper } from './Service'
import { BaseServiceMapper, BaseVrpcServiceProxyInstanceHandler } from './Base'

class LinkedServiceMapper extends BaseServiceMapper<CybusLinkedService> {
    private readonly serviceMapper = new ServiceMapper()

    async mapToDomain (args: VrpcInstanceDomainMapperArgs<ServiceProxy>): Promise<Map<string, CybusLinkedService>> {
        const { id, name, links } = await this.serviceMapper.mapToDomain(args)

        const serviceLinks = new Map<string, CybusLinkedService>()

        if (isArrayNotEmpty(links)) {
            serviceLinks.set(id, { id, name, links })
        }

        return serviceLinks
    }
}

export class VrpcLinkedServiceProxyInstanceHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['servicesLinks']> {
    instanceMapper = new LinkedServiceMapper()
}
