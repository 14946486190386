import type { ReadonlyRecord } from '../../../../../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusDetailedMapping, type StatusType } from '../../../../../../domain'

import { mapDetailedMapping, type MappingProxyParams } from '../../../../../Connectware'
import type { MappingProxy } from '../../../../proxies'
import { MAPPING_CLASSNAME_FILTER } from '../../../../constants'
import { createProxyEventsHandler, type VrpcHandlerConfiguration } from '../Handler'
import { BaseMappingMapper, VrpcBaseMappingProxyInstanceHandler } from './Base'

class DetailedMappingMapper extends BaseMappingMapper<'mapping', ReadonlyRecord<string, string[]>> {
    /**
     * @deprecated this will no longer be supported
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<MappingProxy>('state', 'executionError')

    protected async fetchInformation (proxy: MappingProxy): Promise<ReadonlyRecord<string, string[]>> {
        try {
            return await proxy.getMappedEndpointTopics()
        } catch (e: unknown) {
            throw new ConnectwareError(ConnectwareErrorType.SERVER_ERROR, 'Could not retrieve configured endpoints topics on mapping', {
                id: proxy._targetId,
                message: (e as Error).message,
            })
        }
    }

    protected mapMapping (params: MappingProxyParams, status: StatusType, topics: ReadonlyRecord<string, string[]>): CybusDetailedMapping {
        return mapDetailedMapping(params, status, topics)
    }
}

export class VrpcDetailedMappingProxyInstanceHandler extends VrpcBaseMappingProxyInstanceHandler<'mapping'> {
    readonly instanceMapper = new DetailedMappingMapper()

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: MAPPING_CLASSNAME_FILTER, supportedFilters: [] }
}
