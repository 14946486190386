import type { StatusType } from '../../../../../../domain'

import { mapToStatusType } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, MAPPING_CLASSNAME_FILTER, NODE_CLASSNAME_FILTER } from '../../../../constants'
import type { EndpointProxy, MappingProxy, NodeProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    type VrcpEntityHandler,
    type VrpcHandlerConfiguration,
    type VrpcInstanceDomainMapperArgs,
    type VrpcInstanceMapper,
} from '../Handler'

class VrpcProxyInstanceStatusMapper implements VrpcInstanceMapper<MappingProxy | EndpointProxy | NodeProxy, StatusType> {
    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<MappingProxy | EndpointProxy | NodeProxy>('state')

    mapToDomain ({ instance }: VrpcInstanceDomainMapperArgs<MappingProxy | EndpointProxy | NodeProxy>): Promise<StatusType> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getState().then(mapToStatusType)
    }
}

export class VrpcMappingProxyInstanceStatusHandler implements VrcpEntityHandler<MappingProxy, StatusType> {
    readonly instanceMapper = new VrpcProxyInstanceStatusMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: MAPPING_CLASSNAME_FILTER, supportedFilters: [] }
}

export class VrpcEndpointProxyInstanceStatusHandler implements VrcpEntityHandler<EndpointProxy, StatusType> {
    readonly instanceMapper = new VrpcProxyInstanceStatusMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: ENDPOINT_CLASSNAME_FILTER, supportedFilters: [] }
}

export class VrpcNodeProxyInstanceStatusHandler implements VrcpEntityHandler<NodeProxy, StatusType> {
    readonly instanceMapper = new VrpcProxyInstanceStatusMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: NODE_CLASSNAME_FILTER, supportedFilters: [] }
}
