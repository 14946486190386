import type { ConnectwareError, TopicMessageEvent, TopicPath, TopicSubscriptionResponse } from '../../domain'

export interface TopicsSubscription {
    /**
     * Listen to messages as batches
     */
    onBatch(listener: (batch: TopicMessageEvent[]) => void): VoidFunction

    /**
     * Listen to the last recieved message
     * @param message
     */
    onLastMessage(listener: (message: TopicMessageEvent) => void): VoidFunction

    /**
     * Subscription is droped after any errors
     */
    onError(listener: (error: ConnectwareError) => void): VoidFunction

    /**
     * Updates the subscribed topics
     * @param topics
     * @todo investigate alternative using TopicPath
     */
    subscribe(topics: string[]): Promise<TopicSubscriptionResponse>

    /**
     * End the subscription
     */
    end(): void
}

/**
 * Allows for the subscription of a single topic
 */
export interface TopicsService {
    /**
     * Creates a subscription to multiple topics, behaviour defined by the returned interface
     */
    create(): TopicsSubscription

    /**
     * @returns true if the given topic can be subscribed by the given source
     * @todo investigate alternative using TopicPath
     */
    isSubscribeable(topic: string, sources: string[]): boolean

    /**
     * Parses given topic so it can be easily nestable on the rest of the application
     * @todo investigate alternative using TopicPath
     */
    parseTopic(topic: string): TopicPath

    /**
     * @returns if the topic is an exact match
     */
    isExact(topic: TopicPath): boolean

    /**
     * @returns true if the given sources are started with the given prefix
     */
    isPrefix(prefix: string, sources: string[]): boolean
}
