import type { Intersection } from 'utility-types'

import type { ReadonlyRecord } from '../../../utils'
import {
    ConnectwareError,
    ConnectwareErrorType,
    type CybusDetailedMapping,
    type CybusMapping,
    type CybusPubSub,
    CybusPubSubType,
    CybusRuleEngineExecutionError,
    type StatusType,
} from '../../../domain'

import type { MappingExecutionError, MappingProxyParams, CybusPubSub as ProxyCybusPubSub } from '../proxies'
import { mapResourceNames } from './Resource'

export const removeNonStandardTopicWildcards = (topics: string): string =>
    topics
        .split('/')
        .map((p) => (p.startsWith('+') || p.startsWith('#') ? p[0] : p))
        .join('/')

const mapPubSub = (pubSub: ProxyCybusPubSub, endpointTopics: ReadonlyRecord<string, string[]>): CybusPubSub => {
    const topics = 'endpoint' in pubSub ? endpointTopics[pubSub.endpoint] : [pubSub.topic]

    if (!topics) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not find topic for given endpoint', { pubSub, endpointTopics })
    }

    return {
        type: 'endpoint' in pubSub ? CybusPubSubType.ENDPOINT : CybusPubSubType.TOPIC,
        id: 'endpoint' in pubSub ? pubSub.endpoint : pubSub.topic,
        topics: topics.map(removeNonStandardTopicWildcards),
        externalBroker: pubSub.connection || null,
        subscriptionErrors: new Set(),
    }
}

const mapMappingExecutionError = ({
    error: [messagePrefix, messageDetails],
    ruleName,
    rule,
    message,
}: MappingExecutionError): CybusRuleEngineExecutionError => {
    return new CybusRuleEngineExecutionError({ messagePrefix, messageDetails, ruleName, rule, payload: message })
}

const mapBaseMapping = ({ id }: MappingProxyParams, status: StatusType): Intersection<CybusDetailedMapping, CybusMapping> => {
    const [service, name] = mapResourceNames(id)
    return { id, status, name, service }
}

export const mapMapping = (param: MappingProxyParams, status: StatusType): CybusMapping => ({
    ...mapBaseMapping(param, status),
    entriesCount: param.mappings.length,
})

export const mapDetailedMapping = (params: MappingProxyParams, status: StatusType, topics: ReadonlyRecord<string, string[]>): CybusDetailedMapping => ({
    ...mapBaseMapping(params, status),
    entries: params.mappings.map(({ subscribe, publish, rules, executionError }) => ({
        subscribe: (Array.isArray(subscribe) ? subscribe : [subscribe]).reduce<CybusPubSub[]>((r, sub) => [...r, mapPubSub(sub, topics)], []),
        publish: mapPubSub(publish, topics),
        rules: rules ?? null,
        executionError: executionError ? mapMappingExecutionError(executionError) : null,
    })),
})
