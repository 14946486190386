import { mapDetailedConnection } from '../../../../../Connectware'
import { CONNECTION_CLASSNAME_FILTER } from '../../../../constants'
import type { VrpcHandlerConfiguration } from '../Handler'

import { BaseConnectionMapper, VrpcBaseConnectionProxyInstanceHandler } from './Base'

class DetailedMapper extends BaseConnectionMapper<'connection'> {
    protected readonly mapConnection = mapDetailedConnection
}

export class VrpcDetailedConnectionProxyInstanceHandler extends VrpcBaseConnectionProxyInstanceHandler<'connection'> {
    readonly instanceMapper = new DetailedMapper()

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: CONNECTION_CLASSNAME_FILTER, supportedFilters: [] }
}
