import type { ObjectOrArray, ReadonlyRecord } from '../../../utils'

export type CybusPubSub = (Readonly<{ endpoint: string, label?: string }> | Readonly<{ topic: string }>) &
    Readonly<{ connection?: string, qos?: number, retain?: boolean }>

type Rule = ReadonlyRecord<string, unknown>

export type MappingExecutionError = Readonly<{ error: [string | null, string], rule: Rule, ruleName: string, message: ReadonlyRecord<string, unknown> }>

type Mapping = Readonly<{
    subscribe: ObjectOrArray<CybusPubSub>
    publish: CybusPubSub
    /**
     * @see /docs/user/services/structure/resources/rules.html#user-services-structure-resources-rules
     */
    rules?: Rule[]
    executionError?: MappingExecutionError
}>

/**
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/Mapping.js#lines-120
 * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/swagger/swagger.yaml#lines-540
 */
export type MappingProxyParams = Readonly<{
    id: string
    mappings: Mapping[]
    /**
     * @deprecated this will no longer be relevant once RST is introduced
     * @todo consider no longer using this
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    currentState: string
    targetState: string
    agentName?: string
    inputBuffering?: unknown
}>
