import type { Intersection } from 'utility-types'

import type { StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { type MappingProxyParams, mapToStatusType } from '../../../../../Connectware'
import type { MappingProxy } from '../../../../proxies'
import type { VrcpEntityHandler, VrpcHandlerConfiguration, VrpcInstanceDomainMapperArgs, VrpcInstanceMapper } from '../Handler'

type BaseMapping = Intersection<SubscriptionsTypes['mapping'], SubscriptionsTypes['mappings']>

export abstract class BaseMappingMapper<T extends keyof SubscriptionsTypes, FetchedInfo = void>
    implements VrpcInstanceMapper<MappingProxy, SubscriptionsTypes[T]> {
    /**
     * Async custom loading is done here
     */
    protected abstract fetchInformation (proxy: MappingProxy): Promise<FetchedInfo>

    /**
     * Custom mapping is done here
     */
    protected abstract mapMapping (
        params: MappingProxyParams,
        statusType: StatusType,
        extraInformation: FetchedInfo
    ): Omit<SubscriptionsTypes[T], keyof BaseMapping>

    async mapToDomain ({ instance }: VrpcInstanceDomainMapperArgs<MappingProxy>): Promise<SubscriptionsTypes[T]> {
        /**
         * Fetch all information at the same time
         *
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const [params, info] = await Promise.all([instance.getParams(), this.fetchInformation(instance)])

        /**
         * @deprecated update code block so status is fetched externally
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        const currentStatus = mapToStatusType(params.currentState)

        return this.mapMapping(params, currentStatus, info) as SubscriptionsTypes[T]
    }
}

export abstract class VrpcBaseMappingProxyInstanceHandler<T extends keyof SubscriptionsTypes>
    implements VrcpEntityHandler<MappingProxy, SubscriptionsTypes[T]> {
    abstract readonly instanceMapper: VrpcInstanceMapper<MappingProxy, SubscriptionsTypes[T]>

    abstract readonly configuration: VrpcHandlerConfiguration

    readonly remoteMapper = null
}
