import { areArrayEquals, createInterval, delay, isArrayNotEmpty } from '../../../utils'

import type { ManagedVrpcRemote } from '.'

/**
 * There is a short (allthough inconsistent) timespan
 * Where the VrpcRemote has emitted a connected event
 * But has not loaded any agents
 * This handles that period
 */
export const awaitAgentsLoad = async (remote: ManagedVrpcRemote, timeout: number): Promise<void> => {
    if (isArrayNotEmpty(remote.getAvailableAgents())) {
        return Promise.resolve()
    }

    return Promise.race([delay(timeout), new Promise<void>((resolve) => remote.once('agent', () => resolve()))])
}

const hashAgents = (remote: ManagedVrpcRemote): string[] => Object.keys(remote.getSystemInformation()).sort()

/**
 * Vrpc does have an event for when an agent is added, but not when it is removed
 * This handles both scenarios
 *
 * @param timeout in milliseconds, needs to be less than 32-bit integer
 * @see https://developer.mozilla.org/en-US/docs/Web/API/setInterval#sect1
 */
export const listenToAgentsChange = (remote: ManagedVrpcRemote, timeout: number, onChange: VoidFunction): VoidFunction => {
    let agents = hashAgents(remote)

    return createInterval(() => {
        const newAgents = hashAgents(remote)

        if (areArrayEquals(newAgents, agents)) {
            return
        }

        agents = newAgents
        onChange()
    }, timeout)
}
