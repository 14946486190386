import { areCybusServersEquals, Capability, type CybusServer } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { mapCybusServer, mapToStatusType, type ServerProxyParams } from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

export class ServerStrategy extends BaseSubscriptionStrategy<'servers' | 'server'> {
    readonly requiredPermissions = [Capability.SERVERS_READ, Capability.SERVER_READ]

    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service']

    protected readonly areEquals = areCybusServersEquals

    retrieveAll ({ service }: SubscriptionFilterArgs): Promise<CybusServer[]> {
        return this.retrieve({
            capability: Capability.SERVERS_READ,
            method: 'GET',
            path: '/api/servers',
            mapper: (data: ServerProxyParams[]) =>
                data.reduce<CybusServer[]>((r, server) => {
                    /**
                     * @deprecated update code block so status is fetched externally
                     * @see https://cybusio.atlassian.net/browse/CC-1251
                     */
                    const currentStatus = mapToStatusType(server.currentState)

                    const cybusServer = mapCybusServer(server, currentStatus)
                    return service && service !== cybusServer.service ? r : [...r, cybusServer]
                }, []),
        })
    }

    retrieveOne (id: string): Promise<CybusServer> {
        return this.retrieve({
            capability: Capability.SERVER_READ,
            method: 'GET',
            path: '/api/servers/+',
            pathParams: [id],
            mapper: (server: ServerProxyParams) => {
                /**
                 * @deprecated update code block so status is fetched externally
                 * @see https://cybusio.atlassian.net/browse/CC-1251
                 */
                const currentStatus = mapToStatusType(server.currentState)

                return mapCybusServer(server, currentStatus)
            },
            handleNotFound: true,
        })
    }
}
