import type { CybusDetailedEndpoint } from '../../../../../../domain'

import { mapDetailedEndpoint, mapToStatusType } from '../../../../../Connectware'

import { ENDPOINT_CLASSNAME_FILTER } from '../../../../constants'
import type { EndpointProxy } from '../../../../proxies'
import { createProxyEventsHandler, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceDomainMapperArgs, type VrpcInstanceMapper } from '..'

class VrpcDetailedCEndpointProxyInstanceMapper implements VrpcInstanceMapper<EndpointProxy, CybusDetailedEndpoint> {
    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<EndpointProxy>('state')

    async mapToDomain ({ instance }: VrpcInstanceDomainMapperArgs<EndpointProxy>): Promise<CybusDetailedEndpoint> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return Promise.all([instance.getParams(), instance.getTopics()]).then(([params, topics]) => {
            /**
             * @deprecated update code block so status is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(params.currentState)

            return mapDetailedEndpoint(params, currentStatus, topics)
        })
    }
}

export class VrpcDetailedCEndpointProxyInstanceHandler implements VrcpEntityHandler<EndpointProxy, CybusDetailedEndpoint> {
    readonly instanceMapper = new VrpcDetailedCEndpointProxyInstanceMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: ENDPOINT_CLASSNAME_FILTER, supportedFilters: [] }
}
