import type { SubscriptionData, SubscriptionsTypes } from '../../../../../application'

import type { ManagedVrpcRemote } from '../../../utils'
import type { UnsubFromRemoteFunction, VrpcRemoteMapper } from '../handlers'
import { EntitiesManager, EntityManager } from './Manager'

class RemoteManager<T extends keyof SubscriptionsTypes> extends EntityManager<T, Parameters<UnsubFromRemoteFunction>> {
    protected readonly errorMessage: string = 'Error while interacting with remote'

    protected readonly internallyDroppedArgs: Parameters<UnsubFromRemoteFunction> = []

    constructor (private readonly remote: ManagedVrpcRemote, private readonly mapper: VrpcRemoteMapper<SubscriptionData<T>>, handler: VoidFunction) {
        super(handler)

        void this.safelyAddListener()
        void this.safelySetValue()
    }

    protected addListener (): Promise<UnsubFromRemoteFunction | void> | void {
        const { remote } = this
        return this.mapper.onChange?.({ remote, listener: () => void this.safelySetValue() })
    }

    protected generateValue (): Promise<SubscriptionData<T> | Map<string, SubscriptionData<T>>> {
        const { remote } = this
        return this.mapper.mapToDomain({ remote })
    }
}

/**
 * Some of our subscription mappers interact with the remotes instead of the instances
 * This class deals with those mapper scenarios
 */

export class RemotesManager<T extends keyof SubscriptionsTypes> extends EntitiesManager<T, ManagedVrpcRemote, Parameters<UnsubFromRemoteFunction>> {
    protected readonly internallyDroppedArgs: Parameters<UnsubFromRemoteFunction> = []

    constructor (private readonly mapper: VrpcRemoteMapper<SubscriptionData<T>>, handler: RemotesManager<T>['handler']) {
        super(handler)
    }

    addRemote (remote: ManagedVrpcRemote): Promise<void> {
        return this.add(remote, () => new RemoteManager(remote, this.mapper, () => this.emitChange()))
    }
}
