import type { Optional } from 'utility-types'
import React, { type FC, memo, type PropsWithChildren, useCallback, useEffect } from 'react'
import { createExtendedState } from 'react-extended-state'
import { isPending, usePromise } from 'react-sync-promise'
import type { Dayjs } from 'dayjs'

import { createEqualityChecker, isArrayNotEmpty, nameFunction, toggleReadonlySet } from '../../../utils'
import type { ConnectwareError, CybusLog, CybusLogLevel, CybusLogSource } from '../../../domain'
import type { LogOptions } from '../../../application'

import { useAppUsecase } from '../State'

type LogsState = Readonly<{
    // Filters
    source: CybusLogSource
    levels: CybusLogLevel[]
    startDate: Dayjs | null
    endDate: Dayjs | null

    // The logs that are currently loaded
    logsPromise: Promise<CybusLog[]> | null

    // User selected logs
    selectedLog: CybusLog | null
}> &
    Pick<LogOptions, 'resource' | 'lines'>

const { Provider: LogsStateProvider, useExtendedState: useLogsState, useExtendedStateDispatcher: useLogsDispatcher } = createExtendedState<LogsState>()

const createLogStateDispatcherCreator = <Args extends unknown[],>(
    stateMapper: ((currentState: LogsState, ...args: Args) => Partial<LogsState>) | null,
    updateLogsPromise = true
): (() => (...args: Args) => void) => {
    /**
     * This dispatcher creator function has 3 nests in order to force whoever is calling them
     * to pass the dependencies (EG the mapper) in a way that every render wouldn't cause a further
     * re-render on its useCallback
     */

    return () => {
        const logsUsecase = useAppUsecase('containerLogsUsecase')
        const dispatch = useLogsDispatcher()

        return useCallback(
            (...args) => {
                dispatch((currentState) => {
                    /** First remap any of the parameters */
                    const stateUpdate = stateMapper ? stateMapper(currentState, ...args) : {}
                    const newState = { ...currentState, ...stateUpdate }

                    let logsPromise

                    if (updateLogsPromise) {
                        /** Fetch logs again */
                        const { source, resource, lines, startDate, endDate, levels } = newState
                        logsPromise = logsUsecase.fetchLogs(source, {
                            resource,
                            lines,
                            start: startDate?.toDate() ?? null,
                            end: endDate?.toDate() ?? null,
                            levels: isArrayNotEmpty(levels) ? levels : null,
                        })
                    } else {
                        /** Don't fetch logs and use previous ones */
                        logsPromise = newState.logsPromise
                    }

                    /** Finally, just dispatch the changes */
                    return { ...stateUpdate, logsPromise }
                })
            },
            [logsUsecase, stateMapper, updateLogsPromise]
        )
    }
}

export const useLogsLoader = createLogStateDispatcherCreator(null)

export const useLevelTogglerDispatcher = createLogStateDispatcherCreator(({ levels }, level: CybusLogLevel) => ({
    levels: Array.from(toggleReadonlySet(new Set(levels), level)),
}))

export const useStartDateDispatcher = createLogStateDispatcherCreator(({ endDate }, newStartDate: LogsState['startDate']) =>
    /** if newStartDate > endDate then set endDate to null */
    ({ startDate: newStartDate, endDate: newStartDate?.isAfter(endDate) ? null : endDate })
)

export const useEndDateDispatcher = createLogStateDispatcherCreator(({ startDate }, newEndDate: LogsState['endDate']) =>
    /** if newEndDate < startDate then set endDate to startDate */
    ({ endDate: startDate?.isAfter(newEndDate) ? startDate : newEndDate })
)

export const useSelectedLogDispatcher = createLogStateDispatcherCreator((_, selectedLog: LogsState['selectedLog']) => ({ selectedLog }), false)

const createLogsStateHook = <Prop extends keyof LogsState,>(prop: Prop): (() => LogsState[Prop]) =>
    nameFunction(() => useLogsState((s) => s[prop]), `useLogState${prop}`)

export const useSelectedLog = createLogsStateHook('selectedLog')
export const useResource = createLogsStateHook('resource')
export const useSource = createLogsStateHook('source')
export const useLevels = createLogsStateHook('levels')
export const useStartDate = createLogsStateHook('startDate')
export const useEndDate = createLogsStateHook('endDate')
const useLogsPromise = createLogsStateHook('logsPromise')

export const useLogs = (): CybusLog[] | ConnectwareError | null => {
    const promise = useLogsPromise()
    const sync = usePromise<CybusLog[] | null, ConnectwareError>(promise)
    return isPending(sync) ? null : sync.value
}

const intialState = { levels: [], logsPromise: null, selectedLog: null }
type ProviderProps = Optional<Omit<LogsState, keyof typeof intialState>, 'lines' | 'startDate' | 'endDate'>
const areProviderPropsEqual = createEqualityChecker<PropsWithChildren<ProviderProps>>({
    children: null,
    lines: null,
    resource: null,
    source: null,
    startDate: null,
    endDate: null,
})

const Loader: FC = () => {
    const promise = useLogsPromise()
    const loadLogs = useLogsLoader()

    const hasNotLoaded = promise === null

    useEffect(() => {
        if (hasNotLoaded) {
            loadLogs()
        }
    }, [loadLogs, hasNotLoaded])
    return null
}

export const LogsProvider: FC<ProviderProps> = memo(
    ({ lines = 'limited', startDate = null, endDate = null, resource, source, children }) => (
        <LogsStateProvider value={{ ...intialState, startDate, endDate, source, lines, resource }}>
            <Loader />
            {children}
        </LogsStateProvider>
    ),
    /** This prevents useless internal re-renders */
    areProviderPropsEqual
)
