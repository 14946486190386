import type { NonEmptyArray, ReadonlyRecord } from '../../utils'

import type { RuleEngineEndpoint, TopicConfiguration } from '../../domain'

import { type ConfigurationService, DocumentationType } from '../../application'

import type { ConnectwareHTTPTopicsService } from '../http'

/**
 * @todo
 * @deprecated move this to the backend
 * @see https://cybusio.atlassian.net/browse/CYB-3798
 */
const documentationPaths: ReadonlyRecord<DocumentationType, [absolute: boolean, path: string]> = {
    [DocumentationType.CYBUS_DOCS]: [false, ''],
    [DocumentationType.CYBUS_LEARN]: [true, 'https://www.cybus.io/learn/service-basics/'],
    [DocumentationType.LICENSE_OFFLINE_ACTIVATION]: [false, '/user/licensing.html#how-to-perform-an-offline-activation'],
    [DocumentationType.LICENSING]: [false, '/user/licensing.html'],
    [DocumentationType.MQTT_PREFIX]: [false, '/user/user_management.html#adding-a-mqtt-publish-prefix-for-a-user'],
    [DocumentationType.RULE_ENGINE]: [false, '/user/services/structure/resources/rules.html#expression'],
    [DocumentationType.RULE_ENGINE_PARSE_RULE]: [false, '/user/services/structure/resources/rules.html#parse'],
    [DocumentationType.SERVICES_COMMISSIONING_FILE]: [false, '/user/services/structure/index.html#structure-of-the-commissioning-file'],
    [DocumentationType.SERVICES]: [false, '/user/services/'],
    [DocumentationType.UI_ACCESS_PERMISSIONS]: [false, '/user/ui-access.html'],
}

type Config = Readonly<{ salesEmail: string, supportEmail: string, mqttEndpoints: URL[] } & Pick<Location, 'origin' | 'host'>>

export class CybusConfigurationService implements ConfigurationService {
    constructor (private readonly config: Config, private readonly topicsService: ConnectwareHTTPTopicsService) {}

    getResourcesPaginationSizes (): [short: number, medium: number, large: number] {
        return [15, 50, 100]
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getClientRegistryRefreshRate (): number {
        return 5_000
    }

    getApplicationMetadata (): ReadonlyRecord<string, string> {
        return {
            'Content-Security-Policy': ['connect-src', "'self'", `https://${this.config.host}`, ...new Set(this.config.mqttEndpoints)].join(' '),
        }
    }

    getDocumentationURL (type: DocumentationType): URL {
        const [absolute, path] = documentationPaths[type]
        return absolute ? new URL(path) : new URL(`/docs${path}`, this.config.origin)
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getWorkbenchURL (): URL {
        return new URL('/workbench', this.config.origin)
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getCertificateFileTypes (): NonEmptyArray<string> {
        return ['pem']
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getSupportedServiceCommissioningFileTypes (): NonEmptyArray<string> {
        return ['yml', 'yaml']
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getSupportedLicenseFileTypes (): NonEmptyArray<string> {
        return ['lic']
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getServiceCommissioningFileType (): string {
        return 'yaml'
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getServiceCommissioningFileEncodingType (): string {
        return 'text/yaml;charset=utf-8'
    }

    /**
     *
     * @deprecated move this to the backend
     */
    getSupportedRestoreBackupFileExtensions (): NonEmptyArray<string> {
        return ['tgz']
    }

    getSalesEmail (): string {
        return this.config.salesEmail
    }

    getSupportEmail (): string {
        return this.config.supportEmail
    }

    /**
     * @todo
     * @deprecated move this to the backend
     * @see https://cybusio.atlassian.net/browse/CYB-3798
     */
    getInfoPageRefreshRate (): number {
        return 20_000
    }

    getUserManagementDelay (): number {
        return 200
    }

    fetchTopicsConfiguration (): Promise<TopicConfiguration[]> {
        return this.topicsService.fetchTopics()
    }

    fetchRuleEngineEndpointsConfiguration (): Promise<RuleEngineEndpoint[]> {
        return this.topicsService.fetchEndpoints()
    }
}
