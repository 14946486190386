import { entries } from '../../../../../utils'

import type { CybusAgent } from '../../../../../domain'

import { mapAgents } from '../../../../Connectware'
import { listenToAgentsChange, VrpcDomainType } from '../../../utils'
import type {
    UnsubFromRemoteFunction,
    VrcpEntityHandler,
    VrpcHandlerConfiguration,
    VrpcRemoteChangeArgs,
    VrpcRemoteDomainMapperArgs,
    VrpcRemoteMapper,
} from '.'

class VrpcRemoteToCybusAgentMapper implements VrpcRemoteMapper<CybusAgent> {
    mapToDomain ({ remote }: VrpcRemoteDomainMapperArgs): Promise<Map<string, CybusAgent>> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using RST method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-2118
         * @see https://cybusio.atlassian.net/browse/CC-2201 (FE ticket)
         */

        const systemInfo = remote.getSystemInformation()

        /**
         * @deprecated update code block so status is fetched externally
         * @see https://cybusio.atlassian.net/browse/CC-2118
         */
        const statusMap = new Map(entries(systemInfo).map(([name, s]) => [name, s.status]))

        return Promise.resolve(mapAgents(entries(systemInfo), statusMap, ([name, agent]) => [name, agent]))
    }

    onChange ({ remote, listener }: VrpcRemoteChangeArgs): Promise<UnsubFromRemoteFunction> {
        return Promise.resolve(listenToAgentsChange(remote, 200, listener))
    }
}

export class VrpcRemoteToCybusAgentHandler implements VrcpEntityHandler<never, CybusAgent> {
    readonly remoteMapper = new VrpcRemoteToCybusAgentMapper()

    readonly instanceMapper = null

    readonly configuration: VrpcHandlerConfiguration = { domains: [VrpcDomainType.DEFAULT, VrpcDomainType.EDGE], supportedFilters: [] }
}
