import type { CybusService } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { mapService, mapToStatusType } from '../../../../../Connectware'
import type { ServiceProxy } from '../../../../proxies'
import type { VrpcInstanceDomainMapperArgs } from '..'

import { BaseServiceMapper, BaseVrpcServiceProxyInstanceHandler } from './Base'

export class ServiceMapper extends BaseServiceMapper<CybusService> {
    mapToDomain ({ instance }: VrpcInstanceDomainMapperArgs<ServiceProxy>): Promise<CybusService> {
        return Promise.all([instance.getInfo(), instance.getLinks()]).then(([info, links]) => {
            /**
             * @deprecated update code block so deviation is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const isDeviated = info.isDeviated

            /**
             * @note currently, the 'state' field contains the value of currentState
             * In the future, it will contains the targeState value instead
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(info.state)
            return mapService(info, currentStatus, isDeviated, links)
        })
    }
}

export class VrpcServiceProxyInstanceHandler extends BaseVrpcServiceProxyInstanceHandler<SubscriptionsTypes['services']> {
    readonly instanceMapper = new ServiceMapper()
}
