import type { InstanceInfo, VrpcRemoteEventsMap } from 'vrpc'

import { Droppable, EventListener, isArrayNotEmpty } from '../../../../../utils'

import { ConnectwareError, ConnectwareErrorType } from '../../../../../domain'

import type { SubscriptionData, SubscriptionFilterArgs, SubscriptionsTypes } from '../../../../../application'

import { listenToRemote, type ManagedVrpcRemote, VrpcDomainType, type VrpcRemoteManager } from '../../../utils'
import { getVrpcEntityHandler, type VrcpEntityHandler, type VrpcInstanceMapper, type VrpcInstanceTypes, type VrpcRemoteMapper } from '../handlers'

export abstract class VrpcSubscription<T extends keyof SubscriptionsTypes> {
    /**
     * Handlers of instance events
     */
    private readonly errorListeners = new EventListener<ConnectwareError>()

    private readonly agent: string | null

    private readonly domains: VrpcDomainType[]

    private readonly classNameFilter: RegExp | string | null

    private readonly instanceNameFilter: RegExp | string | null

    protected readonly shouldExcludeInstance: ((instanceName: string, args: SubscriptionFilterArgs) => boolean) | null

    protected readonly instanceMapper: VrpcInstanceMapper<VrpcInstanceTypes[T], SubscriptionData<T>> | null

    protected readonly remoteMapper: VrpcRemoteMapper<SubscriptionsTypes[T]> | null

    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[]

    protected instanceName: string | null

    constructor (eventName: T, private readonly remote: VrpcRemoteManager) {
        /**
         * Get the custom configuration for the given types of instnace
         */
        const { instanceMapper, remoteMapper, configuration } = this.getVrpcEntityHandler(eventName)

        if (Boolean(instanceMapper) === Boolean(remoteMapper)) {
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'You have to exclusively use an instance mapper or a remote mapper', {
                isRemoteMapper: Boolean(remoteMapper),
                isInstanceMapper: Boolean(instanceMapper),
            })
        }

        this.agent = 'agent' in configuration ? configuration.agent : null
        this.domains = 'domains' in configuration ? configuration.domains : []
        this.classNameFilter = 'classNameFilter' in configuration ? configuration.classNameFilter : null
        this.instanceNameFilter = 'instanceNameFilter' in configuration ? configuration.instanceNameFilter : null
        this.supportedFilters = configuration.supportedFilters
        this.shouldExcludeInstance = 'excludeByInstanceName' in configuration ? configuration.excludeByInstanceName : null
        this.instanceName = typeof this.instanceNameFilter === 'string' ? this.instanceNameFilter : null
        this.instanceMapper = instanceMapper
        this.remoteMapper = remoteMapper
    }

    private isClassNameRelevant (className: string): boolean {
        const { classNameFilter } = this
        return classNameFilter !== null && (typeof classNameFilter === 'string' ? classNameFilter === className : classNameFilter.test(className))
    }

    private onInstanceEvent (
        callback: (remote: ManagedVrpcRemote, instances: string[], options: InstanceInfo) => void,
        remote: ManagedVrpcRemote,
        instances: string[],
        options: InstanceInfo
    ): void {
        if (this.isClassNameRelevant(options.className)) {
            const relevantInstances = instances.filter((i) => this.isInstanceRelevant(i))

            if (isArrayNotEmpty(relevantInstances)) {
                /** There are still relevant instances */
                callback(remote, relevantInstances, options)
            }
        }
    }

    private async retrieveConnectedRemotes (): Promise<[remotes: ManagedVrpcRemote[], disuseCallback: VoidFunction]> {
        const droppable = new Droppable()

        /** Retrieve all the domains relevant for this subscription */
        const domains = new Set(this.domains).add(VrpcDomainType.DEFAULT)

        /** Get all remotes at once */
        const remoteTupples = await Promise.all(Array.from(domains).map((domain) => this.remote.getManagedVrpcRemote(domain)))

        const remotes: ManagedVrpcRemote[] = []

        remoteTupples.forEach(([remote, notifyDisuse]) => {
            remotes.push(remote)
            droppable.onDrop(notifyDisuse)
        })

        return [remotes, () => droppable.drop()]
    }

    protected isInstanceRelevant (instanceName: string): boolean {
        const { instanceNameFilter } = this

        // Either there is no filter
        // Or the match is of a string
        // Or of the regex
        return (
            instanceNameFilter === null ||
            (typeof instanceNameFilter === 'string' ? instanceName === instanceNameFilter : instanceNameFilter.test(instanceName))
        )
    }

    /**
     * Called when the there is a new instance that is relevant to the subscriber
     */
    protected abstract onRelevantNewInstances (remote: ManagedVrpcRemote, ...args: VrpcRemoteEventsMap['instanceNew']): void

    /**
     * Called when an instance is deleted that is relevant to the subscriber
     */
    protected abstract onRelevantGoneInstances (...args: VrpcRemoteEventsMap['instanceGone']): void

    /** Whenever the connection is made, passing the currently relevant existing instances  */
    protected abstract onConnected (remote: ManagedVrpcRemote): Promise<void> | void

    /** Called when the subscription is dropped */
    protected abstract onDropped? (): Promise<void> | void

    protected getVrpcEntityHandler (eventName: T): VrcpEntityHandler<VrpcInstanceTypes[T], SubscriptionsTypes[T]> {
        return getVrpcEntityHandler<T>(eventName)
    }

    protected getInstanceConfigurations (remote: ManagedVrpcRemote, targetId: string | null = null): Parameters<ManagedVrpcRemote['getInstance']>[] {
        const props: Parameters<ManagedVrpcRemote['getInstance']>[] = []

        // If agent is not specified use all agents
        const agents = this.agent ? [this.agent] : remote.getAvailableAgents()

        agents.forEach((agent) =>
            remote
                // All classes
                .getAvailableClasses({ agent })
                .forEach((className) => {
                    if (this.isClassNameRelevant(className)) {
                        // Only relevant classes
                        remote.getAvailableInstances(className, { agent }).forEach((i) => {
                            if (targetId ? targetId === i : this.isInstanceRelevant(i)) {
                                // Only relevant instances
                                props.push([i, { className, agent }])
                            }
                        })
                    }
                })
        )

        return props
    }

    protected triggerError (error: ConnectwareError): void {
        this.errorListeners.trigger(error)
    }

    onError (listener: (error: ConnectwareError) => void): this {
        this.errorListeners.on(listener)
        return this
    }

    /** Actually starts to listen */
    async start (): Promise<VoidFunction> {
        /** Helper to drop unused objects once subscription is lifted */
        const droppable = new Droppable()

        /** Get connected remotes */
        const [remotes, notifyDisuse] = await this.retrieveConnectedRemotes()

        /** For each remote, attach listeners */
        await Promise.all(
            remotes.map(async (remote) => {
                /** If there is ever an error on vrpc, pass it to the subscription */
                droppable.onDrop(
                    listenToRemote(remote, 'error', (err) => {
                        switch (err.message) {
                            case 'Connection refused: Not authorized':
                                this.triggerError(new ConnectwareError(ConnectwareErrorType.AUTHENTICATION, 'Could not authenticate on VRPC'))
                                break
                            case 'Connection refused: Server unavailable':
                                this.triggerError(new ConnectwareError(ConnectwareErrorType.SERVER_NOT_AVAILABLE, 'Server is not available'))
                                break
                            default:
                                this.triggerError(
                                    new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected error thrown by VRPC', { message: err.message })
                                )
                        }
                    })
                )

                if (this.instanceMapper) {
                    /** Gets newly created instances */
                    droppable.onDrop(
                        listenToRemote(remote, 'instanceNew', (...args) =>
                            this.onInstanceEvent((...args) => this.onRelevantNewInstances(...args), remote, ...args)
                        )
                    )

                    /** Gets newly deleted instances */
                    droppable.onDrop(
                        listenToRemote(remote, 'instanceGone', (...args) =>
                            this.onInstanceEvent((_, ...args) => this.onRelevantGoneInstances(...args), remote, ...args)
                        )
                    )
                }

                /** Notify that connection is about to be made */
                await this.onConnected(remote)
            })
        )

        /** Handle custom drop */
        droppable.onDrop(() => this.onDropped?.())

        /** Finally drop the remote */
        droppable.onDrop(notifyDisuse)

        return () => droppable.drop()
    }
}
