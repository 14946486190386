import type { ResourceType } from '../../domain'

export interface ConnectwareResourcesService {
    /**
     * @throws `ConnectwareError`
     */
    delete(type: ResourceType, ids: string[]): Promise<void>

    /**
     * @throws `ConnectwareError`
     */
    disable(type: ResourceType, ids: string[]): Promise<void>

    /**
     * @throws `ConnectwareError`
     */
    enable(type: ResourceType, ids: string[]): Promise<void>

    /**
     * @throws `ConnectwareError`
     */
    reenable(type: ResourceType, ids: string[]): Promise<void>
}
