import type { CybusServer } from '../../../../../../domain'

import { mapCybusServer, mapToStatusType } from '../../../../../Connectware'
import { OPCUA_SERVER_CLASSNAME_FILTER } from '../../../../constants'
import type { ServerProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    excludeInstanceByServiceName,
    type VrcpEntityHandler,
    type VrpcHandlerConfiguration,
    type VrpcInstanceDomainMapperArgs,
    type VrpcInstanceMapper,
} from '..'

class VrpcServerProxyToCybusServerMapper implements VrpcInstanceMapper<ServerProxy, CybusServer> {
    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServerProxy>('state')

    mapToDomain ({ instance }: VrpcInstanceDomainMapperArgs<ServerProxy>): Promise<CybusServer> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getParams().then((params) => {
            /**
             * @deprecated update code block so status is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(params.currentState)

            return mapCybusServer(params, currentStatus)
        })
    }
}

abstract class VrpcBaseServerProxyToCybusServerHandler implements VrcpEntityHandler<ServerProxy, CybusServer> {
    readonly instanceMapper = new VrpcServerProxyToCybusServerMapper()

    readonly remoteMapper = null

    constructor (readonly configuration: VrpcHandlerConfiguration) {}
}

export class VrpcServerProxyToCybusServersHandler extends VrpcBaseServerProxyToCybusServerHandler {
    constructor () {
        super({ classNameFilter: OPCUA_SERVER_CLASSNAME_FILTER, supportedFilters: ['service'], excludeByInstanceName: excludeInstanceByServiceName })
    }
}

export class VrpcServerProxyToCybusServerHandler extends VrpcBaseServerProxyToCybusServerHandler {
    constructor () {
        super({ classNameFilter: OPCUA_SERVER_CLASSNAME_FILTER, supportedFilters: [] })
    }
}
