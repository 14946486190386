import type { NonUndefined, ValuesType } from 'utility-types'

import { entries, executeOnce, type NonEmptyArray, type ReadonlyRecord } from '../../../../utils'
import { Capability, ConnectwareError, ConnectwareErrorType, Translation } from '../../../../domain'

import {
    AbsoluteRouteOnlyPath,
    type AbsoluteRoutePath,
    AbsoluteRoutePathWithId,
    AbsoluteRoutePathWithServiceAndResourceId,
    AbsoluteRoutePathWithServiceId,
    RelativeRoutePathWithId,
} from '../Domain'

type RelativeContext = 'service' | 'resource'

type BaseRouteConfig<P extends string, E> = Readonly<
    {
        /**
         * The path to be used with-in react-router-dom
         */
        path: `/${P}`

        /**
         * The translation ready name of the page (will recieve a count value when being translated)
         */
        title: Translation

        /**
         * @description If isTitleSingular is `true`, will pass count of 1, otherwise 0
         * @default false
         */
        isTitleSingular?: true

        /**
         * The capabilities needed in order to access the route
         */
        capabilities: (path: AbsoluteRoutePath) => NonEmptyArray<ReadonlyRecord<'optional' | 'required', Capability[]>>

        /**
         * When redirecting the user using a relative route
         * This conext needs to be configured, so the user may be taken to the correct place
         */
        relativeContext?: RelativeContext

        /**
         * Set true if the route effectively acts only a proxy to take the user elsewhere in or outside of the application
         */
        proxy?: true
    } & E
>

type OnlyPathRouteConfig = BaseRouteConfig<
    string,
    {
        /**
         * The translation ready description of the route
         */
        description?: Translation

        /**
         * The possible parent of said route
         */
        parent?: AbsoluteRouteOnlyPath

        /**
         * Use to separate the display in the menu
         */
        isSecondLevel?: true
    }
>

type PathWithIdConfig<F extends string, Parent, Context extends RelativeContext> = BaseRouteConfig<
    F,
    {
        /**
         * The possible parent of said route
         */
        parent: Parent

        /**
         * Now the context is required to be given
         */
        relativeContext: Context
    }
>

type PathWithResourceIdConfig = PathWithIdConfig<`${string}/:resourceId`, AbsoluteRouteOnlyPath, 'resource'>
type PathWithServiceIdConfig = PathWithIdConfig<`${string}/:serviceId`, AbsoluteRouteOnlyPath, 'service'>
type PathWithResourceAndServiceIdConfig = PathWithIdConfig<`services/:serviceId/${string}/:resourceId`, AbsoluteRoutePathWithServiceId, 'service'>

type Routes = ReadonlyRecord<AbsoluteRouteOnlyPath, OnlyPathRouteConfig> &
    ReadonlyRecord<AbsoluteRoutePathWithId, PathWithResourceIdConfig> &
    ReadonlyRecord<AbsoluteRoutePathWithServiceAndResourceId, PathWithResourceAndServiceIdConfig> &
    ReadonlyRecord<AbsoluteRoutePathWithServiceId, PathWithServiceIdConfig>

export type RouteParentPath = NonUndefined<ValuesType<Routes>['parent']>

/**
 * A lot of configurations duplicate a lot of things
 * This helps keeping them less duplicated
 */
const createDerivedConfigs = <K extends keyof Routes, Base extends keyof Routes[K]>(
    base: Pick<Routes[K], Base>,
    derivations: ReadonlyRecord<K, Omit<Routes[K], Base>>
): Pick<Routes, K> => entries(derivations).reduce((r, [route, derivation]) => ({ ...r, [route]: { ...base, ...derivation } }), {} as Pick<Routes, K>)

/**
 * This function is expected to always be called with a route that has actual children
 * Otherwise it will lie that is has a non empty response
 *
 * @returns all the alternatives as given by the route's children
 */
const getRouteChildrenCapabilities: typeof getRouteCapabilities = (route) => {
    const childrenCapabilities = entries(routes).flatMap(([otherRoute, config]) => (config.parent === route ? config.capabilities(otherRoute) : []))

    return childrenCapabilities as ReturnType<typeof getRouteCapabilities>
}

/**
 * Use this object to define the routes of the application
 * Its a map to be used by 'react-router-dom' and the Connectware react components
 */
const routes: Routes = {
    /** System */
    [AbsoluteRouteOnlyPath.SYSTEM]: {
        path: '/system',
        title: Translation.SYSTEM,
        capabilities: executeOnce(getRouteChildrenCapabilities),
        proxy: true,
    },
    [AbsoluteRouteOnlyPath.SYSTEM_STATUS]: {
        path: '/system/status',
        title: Translation.SYSTEM_STATUS,
        description: Translation.NAVIGATION_SYSTEM_STATUSES_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SYSTEM,
        capabilities: executeOnce(getRouteChildrenCapabilities),
    },
    [AbsoluteRouteOnlyPath.SYSTEM_CONTAINERS]: {
        path: '/system/status/container',
        title: Translation.SYSTEM_CONTAINER,
        parent: AbsoluteRouteOnlyPath.SYSTEM_STATUS,
        capabilities: executeOnce(() => [
            { required: [Capability.CORE_CONTAINERS_READ], optional: [Capability.CORE_CONTAINER_READ, Capability.CORE_CONTAINERS_MANAGE] },
        ]),
    },
    [AbsoluteRoutePathWithId.SYSTEM_CONTAINER]: {
        path: '/system/status/container/:resourceId',
        title: Translation.CONTAINER,
        isTitleSingular: true,
        parent: AbsoluteRouteOnlyPath.SYSTEM_CONTAINERS,
        capabilities: executeOnce(() => [{ required: [Capability.CORE_CONTAINER_READ], optional: [Capability.CORE_CONTAINERS_MANAGE, Capability.LOGS_READ] }]),
        relativeContext: 'resource',
    },
    [AbsoluteRouteOnlyPath.SYSTEM_INTERNET_CONNECTIVITY]: {
        path: '/system/status/internet-connectivity',
        title: Translation.INTERNET_CONNECTIVITY,
        parent: AbsoluteRouteOnlyPath.SYSTEM_STATUS,
        capabilities: executeOnce(() => [{ required: [Capability.MINIMUM], optional: [] }]),
    },
    [AbsoluteRouteOnlyPath.SYSTEM_LICENSE]: {
        path: '/system/status/license',
        title: Translation.LICENSE,
        description: Translation.NAVIGATION_SYSTEM_LICENSE_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SYSTEM_STATUS,
        capabilities: executeOnce(() => [{ required: [Capability.MINIMUM], optional: [Capability.SYSTEM_LICENSE_MANAGE] }]),
    },
    [AbsoluteRouteOnlyPath.SYSTEM_INFORMATION]: {
        path: '/system/status/information',
        title: Translation.SYSTEM_INFORMATION,
        parent: AbsoluteRouteOnlyPath.SYSTEM_STATUS,
        capabilities: executeOnce(() => [{ required: [Capability.MINIMUM], optional: [Capability.SERVICES_READ] }]),
    },
    [AbsoluteRouteOnlyPath.SYSTEM_METRICS]: {
        path: '/system/status/metrics',
        title: Translation.METRICS,
        description: Translation.NAVIGATION_SYSTEM_METRICS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SYSTEM_STATUS,
        capabilities: executeOnce(() => [{ required: [Capability.SYSTEM_METRICS_READ], optional: [Capability.SYSTEM_METRICS_MANAGE] }]),
    },
    [AbsoluteRouteOnlyPath.SYSTEM_AGENTS]: {
        path: '/system/status/agents',
        title: Translation.AGENT,
        description: Translation.NAVIGATION_SYSTEM_AGENTS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SYSTEM_STATUS,
        capabilities: executeOnce(() => [{ required: [Capability.AGENTS_READ], optional: [Capability.AGENTS_MANAGE] }]),
    },
    [AbsoluteRouteOnlyPath.SYSTEM_MAINTENANCE]: {
        path: '/system/backup-and-restore',
        title: Translation.MAINTENANCE,
        description: Translation.MAINTENANCE_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SYSTEM,
        capabilities: executeOnce(() => [{ required: [Capability.SYSTEM_BACKUP_MANAGE], optional: [] }]),
    },
    /** Data */
    [AbsoluteRouteOnlyPath.DATA]: {
        path: '/data',
        title: Translation.DATA,
        capabilities: executeOnce(getRouteChildrenCapabilities),
        proxy: true,
    },
    [AbsoluteRouteOnlyPath.DATA_EXPLORER]: {
        path: '/data/explorer',
        title: Translation.DATA_EXPLORER,
        description: Translation.NAVIGATION_EXPLORER_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.DATA,
        capabilities: executeOnce(() => [
            {
                required: [Capability.TOPICS_SUBSCRIPTION, Capability.MAPPING_STATE_READ, Capability.ENDPOINT_STATE_READ, Capability.NODE_STATE_READ],
                optional: [Capability.TOPICS_SUBSCRIPTION_METADATA],
            },
        ]),
    },
    /** Services */
    [AbsoluteRouteOnlyPath.SERVICES]: {
        path: '/services',
        title: Translation.SERVICE,
        capabilities: executeOnce(getRouteChildrenCapabilities),
        proxy: true,
    },
    [AbsoluteRouteOnlyPath.SERVICES_OVERVIEW]: {
        path: '/services/overview',
        title: Translation.SERVICE_OVERVIEW,
        description: Translation.NAVIGATION_SERVICES_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES,
        capabilities: executeOnce(() => [
            {
                required: [Capability.SERVICES_READ],
                optional: [Capability.SERVICE_READ, Capability.SERVICES_MANAGE, Capability.SERVICES_CREATE_OR_UPDATE],
            },
        ]),
        relativeContext: 'service',
    },
    [AbsoluteRouteOnlyPath.SERVICES_TEMPLATE_EDIT]: {
        path: '/services/edit-template',
        title: Translation.EDIT_SERVICE_TEMPLATE,
        parent: AbsoluteRouteOnlyPath.SERVICES_OVERVIEW,
        capabilities: executeOnce(() => [{ required: [Capability.SERVICE_TEMPLATE_EDIT], optional: [] }]),
    },
    ...createDerivedConfigs(
        {
            title: Translation.SERVICE_DETAILS,
            isTitleSingular: true,
            capabilities: executeOnce(() => [
                {
                    required: [Capability.SERVICE_READ],
                    optional: [
                        Capability.SERVICES_MANAGE,
                        Capability.SERVICES_CREATE_OR_UPDATE,
                        Capability.LOGS_READ,
                        Capability.CONNECTIONS_READ,
                        Capability.SERVICE_CONTAINERS_READ,
                        Capability.ENDPOINTS_READ,
                        Capability.MAPPINGS_READ,
                        Capability.SERVERS_READ,
                        Capability.VOLUMES_READ,
                    ],
                },
            ]),
        },
        {
            [AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE]: {
                path: '/services/overview/:serviceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_OVERVIEW,
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.SERVICES_RESOURCES_SERVICE]: {
                path: '/services/resources/service/:resourceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES_LINKS,
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICES_LOGS]: {
        path: '/services/logs',
        title: Translation.SERVICE_LOGS,
        parent: AbsoluteRouteOnlyPath.SERVICES,
        capabilities: executeOnce(() => [{ required: [Capability.LOGS_READ], optional: [] }]),
        relativeContext: 'service',
    },
    [AbsoluteRouteOnlyPath.SERVICES_WORKBENCH]: {
        path: '/services/workbench',
        title: Translation.WORKBENCH_TITLE,
        isTitleSingular: true,
        parent: AbsoluteRouteOnlyPath.SERVICES,
        capabilities: executeOnce(() => [{ required: [Capability.WORKBENCH], optional: [] }]),
        proxy: true,
        isSecondLevel: true,
    },
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES]: {
        path: '/services/resources',
        title: Translation.SERVICE_RESOURCES,
        description: Translation.NAVIGATION_MAPPINGS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES,
        capabilities: executeOnce(getRouteChildrenCapabilities),
        relativeContext: 'resource',
    },
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES_LINKS]: {
        path: '/services/resources/links',
        title: Translation.SERVICE_LINK,
        description: Translation.NAVIGATION_SERVICES_LINKS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
        capabilities: executeOnce(() => [{ required: [Capability.SERVICES_READ], optional: [Capability.SERVICE_READ] }]),
        relativeContext: 'resource',
    },
    [AbsoluteRouteOnlyPath.SERVICES_CATALOG]: {
        path: '/services/catalog',
        title: Translation.SERVICES_CATALOG,
        description: Translation.NAVIGATION_SERVICES_CATALOG_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES,
        capabilities: executeOnce(() => [{ required: [Capability.SERVICES_CATALOG_READ, Capability.SERVICES_CREATE_OR_UPDATE], optional: [] }]),
    },
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES_SERVERS]: {
        path: '/services/resources/servers',
        title: Translation.SERVER,
        description: Translation.NAVIGATION_SERVERS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
        capabilities: executeOnce(() => [{ required: [Capability.SERVERS_READ], optional: [Capability.SERVER_READ, Capability.SERVERS_MANAGE] }]),
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.SERVER,
            isTitleSingular: true,
            capabilities: executeOnce(() => [
                { required: [Capability.SERVER_READ], optional: [Capability.SERVERS_MANAGE, Capability.NODES_READ, Capability.LOGS_READ] },
            ]),
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_SERVER]: {
                path: '/services/overview/:serviceId/servers/:resourceId',
                parent: AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE,
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.SERVICES_RESOURCES_SERVER]: {
                path: '/services/resources/servers/:resourceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES_SERVERS,
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONTAINERS]: {
        path: '/services/resources/containers',
        title: Translation.CONTAINER,
        description: Translation.NAVIGATION_CONTAINERS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
        capabilities: executeOnce(() => [
            { required: [Capability.SERVICE_CONTAINERS_READ], optional: [Capability.SERVICE_CONTAINER_READ, Capability.SERVICE_CONTAINERS_MANAGE] },
        ]),
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.CONTAINER,
            isTitleSingular: true,
            capabilities: executeOnce(() => [
                { required: [Capability.SERVICE_CONTAINER_READ], optional: [Capability.SERVICE_CONTAINERS_MANAGE, Capability.LOGS_READ] },
            ]),
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONTAINER]: {
                path: '/services/overview/:serviceId/containers/:resourceId',
                parent: AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE,
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.SERVICES_RESOURCES_CONTAINER]: {
                path: '/services/resources/containers/:resourceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONTAINERS,
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES_VOLUMES]: {
        path: '/services/resources/volumes',
        title: Translation.VOLUME,
        description: Translation.NAVIGATION_VOLUMES_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
        capabilities: executeOnce(() => [{ required: [Capability.VOLUMES_READ], optional: [Capability.VOLUME_READ, Capability.VOLUMES_MANAGE] }]),
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.VOLUME,
            isTitleSingular: true,
            capabilities: executeOnce(() => [{ required: [Capability.VOLUME_READ], optional: [Capability.VOLUMES_MANAGE, Capability.LOGS_READ] }]),
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_VOLUME]: {
                path: '/services/overview/:serviceId/volumes/:resourceId',
                parent: AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE,
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.SERVICES_RESOURCES_VOLUME]: {
                path: '/services/resources/volumes/:resourceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES_VOLUMES,
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONNECTIONS]: {
        path: '/services/resources/connections',
        title: Translation.CONNECTION,
        description: Translation.NAVIGATION_CONNECTIONS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
        capabilities: executeOnce(() => [{ required: [Capability.CONNECTIONS_READ], optional: [Capability.CONNECTION_READ, Capability.CONNECTIONS_MANAGE] }]),
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.CONNECTION,
            isTitleSingular: true,
            capabilities: executeOnce(() => [{ required: [Capability.CONNECTION_READ], optional: [Capability.CONNECTIONS_MANAGE, Capability.LOGS_READ] }]),
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONNECTION]: {
                path: '/services/overview/:serviceId/connections/:resourceId',
                parent: AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE,
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.SERVICES_RESOURCES_CONNECTION]: {
                path: '/services/resources/connections/:resourceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES_CONNECTIONS,
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES_ENDPOINTS]: {
        path: '/services/resources/endpoints',
        title: Translation.ENDPOINT,
        description: Translation.NAVIGATION_ENDPOINTS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
        capabilities: executeOnce(() => [{ required: [Capability.ENDPOINTS_READ], optional: [Capability.ENDPOINT_READ, Capability.ENDPOINTS_MANAGE] }]),
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.ENDPOINT,
            isTitleSingular: true,
            capabilities: executeOnce(() => [{ required: [Capability.ENDPOINT_READ], optional: [Capability.ENDPOINTS_MANAGE, Capability.LOGS_READ] }]),
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_ENDPOINT]: {
                path: '/services/overview/:serviceId/endpoints/:resourceId',
                parent: AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE,
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.SERVICES_RESOURCES_ENDPOINT]: {
                path: '/services/resources/endpoints/:resourceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES_ENDPOINTS,
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICES_RESOURCES_MAPPINGS]: {
        path: '/services/resources/mappings',
        title: Translation.MAPPING,
        description: Translation.NAVIGATION_MAPPINGS_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES,
        capabilities: executeOnce(() => [{ required: [Capability.MAPPINGS_READ], optional: [Capability.MAPPING_READ, Capability.MAPPINGS_MANAGE] }]),
        relativeContext: 'resource',
    },
    ...createDerivedConfigs(
        {
            title: Translation.MAPPING,
            isTitleSingular: true,
            capabilities: executeOnce(() => [
                { required: [Capability.MAPPING_READ], optional: [Capability.MAPPINGS_MANAGE, Capability.LOGS_READ, Capability.TOPICS_SUBSCRIPTION] },
            ]),
        },
        {
            [AbsoluteRoutePathWithServiceAndResourceId.SERVICE_MAPPING]: {
                path: '/services/overview/:serviceId/mappings/:resourceId',
                parent: AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE,
                relativeContext: 'service',
            },
            [AbsoluteRoutePathWithId.SERVICES_RESOURCES_MAPPING]: {
                path: '/services/resources/mappings/:resourceId',
                parent: AbsoluteRouteOnlyPath.SERVICES_RESOURCES_MAPPINGS,
                relativeContext: 'resource',
            },
        }
    ),
    [AbsoluteRouteOnlyPath.SERVICES_RULE_ENGINE_SANDBOX]: {
        path: '/services/rule-engine-sandbox',
        title: Translation.RULE_ENGINE_SANDBOX,
        description: Translation.NAVIGATION_RULE_ENGINE_SANDBOX_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.SERVICES,
        capabilities: executeOnce(() => [
            { required: [Capability.TOPICS_SUBSCRIPTION, Capability.RULE_ENGINE_SANDBOX_USE], optional: [Capability.TOPICS_SUBSCRIPTION_METADATA] },
        ]),
        isSecondLevel: true,
    },
    /** User */
    [AbsoluteRouteOnlyPath.USER]: {
        path: '/user',
        title: Translation.USER,
        isTitleSingular: true,
        capabilities: executeOnce(getRouteChildrenCapabilities),
        proxy: true,
    },
    [AbsoluteRouteOnlyPath.USER_MANAGEMENT]: {
        path: '/user/management',
        title: Translation.USER_MANAGEMENT,
        parent: AbsoluteRouteOnlyPath.USER,
        capabilities: executeOnce(getRouteChildrenCapabilities),
        proxy: true,
    },
    [AbsoluteRouteOnlyPath.USER_MANAGEMENT_USERS]: {
        path: '/user/management/users',
        title: Translation.USER,
        parent: AbsoluteRouteOnlyPath.USER_MANAGEMENT,
        capabilities: executeOnce(() => [{ required: [Capability.MANAGE_USERS, Capability.RETRIEVE_PASSWORD_POLICY], optional: [] }]),
    },
    [AbsoluteRouteOnlyPath.USER_MANAGEMENT_ROLES]: {
        path: '/user/management/roles',
        title: Translation.ROLE,
        parent: AbsoluteRouteOnlyPath.USER_MANAGEMENT,
        capabilities: executeOnce(() => [{ required: [Capability.MANAGE_ROLES], optional: [] }]),
    },
    [AbsoluteRouteOnlyPath.USER_MANAGEMENT_PERMISSIONS]: {
        path: '/user/management/permissions',
        title: Translation.PERMISSION,
        parent: AbsoluteRouteOnlyPath.USER_MANAGEMENT,
        capabilities: executeOnce(() => [{ required: [Capability.MANAGE_PERMISSIONS], optional: [] }]),
    },
    [AbsoluteRouteOnlyPath.USER_CLIENT_REGISTRY]: {
        path: '/user/client-registry',
        title: Translation.CLIENT_REGISTRY,
        description: Translation.NAVIGATION_CLIENT_REGISTRY_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.USER,
        capabilities: executeOnce(() => [{ required: [Capability.CLIENT_REGISTRY_MANAGE], optional: [] }]),
    },
    [AbsoluteRouteOnlyPath.USER_CERTIFICATES]: {
        path: '/user/certificates',
        title: Translation.USER_CERTIFICATES,
        description: Translation.NAVIGATION_CERTIFICATES_DESCRIPTION,
        parent: AbsoluteRouteOnlyPath.USER,
        capabilities: executeOnce(() => [{ required: [Capability.CERTIFICATES_MANAGE], optional: [] }]),
    },
    /** Settings */
    [AbsoluteRouteOnlyPath.SETTINGS]: {
        path: '/settings',
        title: Translation.SETTINGS,
        capabilities: executeOnce(getRouteChildrenCapabilities),
        proxy: true,
    },
    [AbsoluteRouteOnlyPath.SETTINGS_LOGIN]: {
        path: '/settings/login',
        title: Translation.LOGIN_SETTINGS,
        parent: AbsoluteRouteOnlyPath.SETTINGS,
        capabilities: executeOnce(() => [
            { required: [], optional: [] },
            { required: [Capability.MANAGE_OWN_PASSWORD, Capability.RETRIEVE_PASSWORD_POLICY], optional: [] },
            { required: [Capability.USE_MFA], optional: [] },
        ]),
    },
}

const relativeRoutes: ReadonlyRecord<
    RelativeContext,
    ReadonlyRecord<RelativeRoutePathWithId, AbsoluteRoutePathWithServiceId | AbsoluteRoutePathWithId | AbsoluteRoutePathWithServiceAndResourceId>
> = {
    resource: {
        [RelativeRoutePathWithId.SERVER]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_SERVER,
        [RelativeRoutePathWithId.CONTAINER]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_CONTAINER,
        [RelativeRoutePathWithId.VOLUME]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_VOLUME,
        [RelativeRoutePathWithId.CONNECTION]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_CONNECTION,
        [RelativeRoutePathWithId.ENDPOINT]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_ENDPOINT,
        [RelativeRoutePathWithId.MAPPING]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_MAPPING,
        [RelativeRoutePathWithId.SERVICE]: AbsoluteRoutePathWithId.SERVICES_RESOURCES_SERVICE,
    },
    service: {
        [RelativeRoutePathWithId.SERVER]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_SERVER,
        [RelativeRoutePathWithId.CONTAINER]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONTAINER,
        [RelativeRoutePathWithId.VOLUME]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_VOLUME,
        [RelativeRoutePathWithId.CONNECTION]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_CONNECTION,
        [RelativeRoutePathWithId.ENDPOINT]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_ENDPOINT,
        [RelativeRoutePathWithId.MAPPING]: AbsoluteRoutePathWithServiceAndResourceId.SERVICE_MAPPING,
        [RelativeRoutePathWithId.SERVICE]: AbsoluteRoutePathWithServiceId.SERVICES_OVERVIEW_SERVICE,
    },
}

export const getRouteConfig = <P extends AbsoluteRoutePath>(path: P): Routes[P] => routes[path]

/**
 * Check if the route is a child route of the parent route.
 * @param childRoute - AbsoluteRoutePath
 * @param parentRoute - AbsoluteRoutePath
 */
export const isRouteParent = (childRoute: AbsoluteRoutePath, parentRoute: AbsoluteRoutePath): boolean => getRouteConfig(childRoute).parent === parentRoute

/**
 * Check if the route is on the 2-nd level
 * @param route - AbsoluteRoutePath
 */
export const isSecondLevelRoute = (route: AbsoluteRoutePath): boolean => {
    const config = getRouteConfig(route)
    return 'isSecondLevel' in config && config.isSecondLevel === true
}

export const getRouteInternalPath = (path: AbsoluteRoutePath): string => getRouteConfig(path).path

export const getRouteCapabilities = (path: AbsoluteRoutePath): ReturnType<Routes[AbsoluteRoutePath]['capabilities']> => getRouteConfig(path).capabilities(path)

export const getContext = (route: AbsoluteRoutePath): RelativeContext => {
    const { relativeContext } = getRouteConfig(route)

    if (!relativeContext) {
        throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Could not find relative context', { route })
    }

    return relativeContext
}

export const isRouteProxy = (route: AbsoluteRoutePath): boolean => Boolean(getRouteConfig(route).proxy)

export const getAbsoluteRoute = (path: RelativeRoutePathWithId, context: RelativeContext): (typeof relativeRoutes)[typeof context][typeof path] =>
    relativeRoutes[context][path]
