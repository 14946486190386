import type { ValuesType } from 'utility-types'

import { areArrayEquals, isArrayNotEmpty } from '../../../utils'
import { type Explorer, selectLoadedExplorer, type TopicPath, type TopicPathConfiguration, TopicType } from '../../../domain'

import type { PersistedTopics } from '../..'

import { Usecase } from '../Usecase'

export const initialExplorerState: Explorer = {
    topics: [],
    filter: { types: Object.values(TopicType), resources: [] },
    latestValues: {},
    tailSize: 50,
    isTailing: true,
    tail: [],
    configurationLoadError: null,
}

export abstract class ExplorerUsecase extends Usecase {
    protected static areTopicPathsEquals = (pathA: TopicPath, pathB: TopicPath): boolean => {
        return areArrayEquals(pathA, pathB, { sort: false })
    }

    protected static isParentOrPathOf = (parent: TopicPath, childOrSelf: TopicPath): boolean => {
        return parent.length <= childOrSelf.length && ExplorerUsecase.areTopicPathsEquals(parent, childOrSelf.slice(0, parent.length))
    }

    private generateDerivedValues (topics: Explorer['topics'], newLatestValues?: Explorer['latestValues']): Pick<Explorer, 'latestValues'> & PersistedTopics {
        const explorer = selectLoadedExplorer(this.getState())

        const selectedPaths: TopicPath[] = []
        const selected: TopicPathConfiguration[] = []
        const custom: TopicPathConfiguration[] = []
        const latestValues = { ...explorer.latestValues, ...newLatestValues }

        topics.forEach((topic) => {
            if (topic.selected) {
                selectedPaths.push(topic.path)
                selected.push({ path: topic.path, rawPath: topic.rawPath })
                /**
                 * If there is no value set yet
                 * And the topic can only receive messages from one place
                 * Then show it even without payload
                 */
                if (!latestValues[topic.rawPath] && this.topicsService.isExact(topic.path)) {
                    latestValues[topic.rawPath] = { payload: null, sources: [topic.path] }
                }
            }

            if (topic.source === TopicType.CUSTOM) {
                custom.push({ path: topic.path, rawPath: topic.rawPath })
            }
        })

        const toRemove = Object.keys(latestValues).filter(
            (topic) =>
                !(latestValues[topic] as ValuesType<Explorer['latestValues']>).sources.some((s) =>
                    selectedPaths.some((topics) => ExplorerUsecase.areTopicPathsEquals(topics, s))
                )
        )

        /**
         * This block deals with removing the last orphaned topic lastValues
         */

        if (isArrayNotEmpty(toRemove)) {
            toRemove.forEach((topic) => delete latestValues[topic])
        }

        return { custom, latestValues, selected }
    }

    protected setExplorer (newExplorer: Partial<Explorer>): void {
        const explorer = selectLoadedExplorer(this.getState())

        /**
         * Handling topics sub/unsub process
         */
        if (newExplorer.topics !== undefined) {
            // get variables from topics
            const { latestValues, custom, selected } = this.generateDerivedValues(newExplorer.topics, newExplorer.latestValues)

            /**
             * This persists the topics for when the user leaves the page
             * Or it gets reloaded
             */
            this.topicsPersistenceService.persistTopics({ custom, selected })

            newExplorer = { ...newExplorer, latestValues }
        }

        /**
         * Handling tail changing
         */
        if (newExplorer.tail !== undefined || newExplorer.tailSize !== undefined) {
            const tail = newExplorer.tail || explorer.tail
            const tailSize = newExplorer.tailSize === undefined ? explorer.tailSize : newExplorer.tailSize

            newExplorer = { ...newExplorer, tail: tail.slice(0, tailSize) }
        }

        this.setState({ explorer: { ...explorer, ...newExplorer } })
    }
}
