import React, { type FC } from 'react'

import { type CybusNode, type CybusServer, selectNodesPage, type StatusType, Translation } from '../../../domain'

import { ResourcesTable } from '../Resources'
import { useTranslator } from '../Internationalization'
import { Status, useTableStatusSearcher } from '../common'

export const Table: FC<Readonly<{ serverId: CybusServer['id'] }>> = ({ serverId }) => {
    const translator = useTranslator()

    const searcher = useTableStatusSearcher()
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <ResourcesTable<CybusNode>
            subscriptionUsecase="manageNodesUsecase"
            filter={{ server: serverId }}
            data={selectNodesPage}
            dataTableMapper={(nodes) => nodes}
            columns={{
                service: { label: Translation.SERVICE_ID, sort: true },
                name: { label: Translation.NAME, sort: true },
                parent: { label: Translation.PARENT, sort: true },
                protocol: { label: translator.formatTranslation(Translation.PROTOCOL, { count: 1 }), sort: true },
                operation: { label: translator.formatTranslation(Translation.OPERATION, { count: 1 }), sort: true },
                status: { label: Translation.STATUS, searcher, customCellRender, sort: true },
            }}
            translations={{ noResultsOrEmptyTable: Translation.NO_NODES_AVAILABLE }}
        />
    )
}
