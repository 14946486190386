import React, { type FC } from 'react'

import { Box, type SxProps, type Theme } from '@mui/material'

import {
    canAgentBeDeleted,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusAgent,
    ResourceType,
    selectAgentsPage,
    selectDeletableLoadedAgents,
    type StatusType,
    Translation,
} from '../../../../domain'

import { FormattedTranslation } from '../../Internationalization'
import { useAppUsecase } from '../../State'
import { Status, useClearTableSelection, useTableStatusSearcher } from '../../common'
import { DeleteResourceModal, ResourcesTable } from '../../Resources'

const Modals: FC = () => {
    const clear = useClearTableSelection()
    const bulkDelete = useAppUsecase('bulkDeleteAgentsUsecase')

    return (
        <DeleteResourceModal
            title={Translation.DELETE_AGENTS}
            subtitle={Translation.DELETE_AGENTS_SUBTITLE}
            confirm={Translation.DELETE}
            resourceSelector={selectDeletableLoadedAgents}
            dimension={ResourceType.AGENT}
            onRenderLineContent="name"
            onCancel={() => bulkDelete.selectAgents(null)}
            onConfirm={async () => {
                await bulkDelete.delete()
                clear()
            }}
        />
    )
}

const selectCanManageAgents = createIsAuthenticatedWithCapabilitiesSelector(Capability.AGENTS_MANAGE)

const wrapperStyle: SxProps<Theme> = { py: 2 }

export const Agents: FC = () => {
    const searcher = useTableStatusSearcher()
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <Box sx={wrapperStyle}>
            <ResourcesTable<
                CybusAgent,
                Omit<CybusAgent, 'hostname' | 'containerId'> & Readonly<{ hostnameOrContainer: Pick<CybusAgent, 'hostname' | 'containerId'> }>,
                'bulkDeleteAgentsUsecase'
            >
                subscriptionUsecase="manageAgentsUsecase"
                data={selectAgentsPage}
                hasManagementCapabilitiesSelector={selectCanManageAgents}
                dataTableMapper={(agents) => agents.map(({ hostname, containerId, ...a }) => ({ ...a, hostnameOrContainer: { hostname, containerId } }))}
                columns={{
                    name: { label: Translation.NAME, sort: true },
                    version: { label: Translation.VERSION, sort: true },
                    status: { label: Translation.STATUS, searcher, customCellRender, sort: true },
                    hostnameOrContainer: {
                        label: Translation.HOSTNAME,
                        customCellRender: ({ hostname, containerId }) =>
                            hostname || (
                                <>
                                    {containerId} (<FormattedTranslation id={Translation.CONTAINER_ID} />)
                                </>
                            ),
                    },
                }}
                selection={{ DELETE: { usecase: 'bulkDeleteAgentsUsecase', filter: canAgentBeDeleted }, resource: 'name', usecaseMethod: 'selectAgents' }}
                translations={{ noResultsOrEmptyTable: Translation.NO_AGENTS_RUNNING }}
            >
                <Modals />
            </ResourcesTable>
        </Box>
    )
}
