import type { GetInstanceOptions, InstanceInfo } from 'vrpc'

import { isArrayNotEmpty } from '../../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../../domain'
import type { PageSubscriptionsTypes, SubscriptionFilterArgs } from '../../../../../application'

import { getInstance, type ManagedVrpcRemote, type VrpcRemoteManager } from '../../../utils'
import { VrpcEntitiesSubscription } from './Entities'

/**
 * This class reflects the behaviours of the VrpcRemote while trying to create a "subscription" to a list of domain entities
 *
 * There are basically **three** flows here
 *
 * 1. Mapping domain instances from the remotes themselves
 *
 *  The VrpcRemote itself is used to yield domain entities, it is mutually exclusive to the other 2 flows
 *
 * 2. Mapping domains instances from Vrpc Instances that recieved through events
 *
 *  If the remote is already connected and new instances are added,
 *  the `onRelevantNewInstances` method will be called
 *  From there change events will be triggered internally through the instanceManager
 *
 * 3. Mapping domain instances from Vrpc Instances that were already loaded
 *
 *  If the instanceNew listener was hooked after the connection,
 *  this means the `onRelevantNewInstances` method will not be triggered
 *  so to handle this flow we call the method `getInstanceConfigurations`
 *  Note that it may yield empty config arrays during the initial load,
 *  that is why a manual trigger of change event needs to be made.
 */
export class ListSubscription<T extends keyof PageSubscriptionsTypes> extends VrpcEntitiesSubscription<T> {
    constructor (private readonly filter: SubscriptionFilterArgs, eventName: T, remote: VrpcRemoteManager) {
        super(eventName, remote)

        if ((Object.keys(filter) as (keyof SubscriptionFilterArgs)[]).some((f) => !this.supportedFilters.includes(f))) {
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Filter type not supported', {
                eventName,
                supported: this.supportedFilters,
                filter,
            })
        }
    }

    private async addInstance (remote: ManagedVrpcRemote, instanceName: string, options?: GetInstanceOptions): Promise<void> {
        await this.withInstanceManager((manager) =>
            manager.addInstance(ListSubscription.mapInstanceAddress(instanceName, options), this.filter, getInstance(remote, instanceName, options))
        )
    }

    private async removeInstance (instanceName: string, options?: GetInstanceOptions): Promise<void> {
        await this.withInstanceManager((manager) => manager.removeInstance(ListSubscription.mapInstanceAddress(instanceName, options), true))
    }

    protected isInstanceRelevant (instanceName: string): boolean {
        return super.isInstanceRelevant(instanceName) && (this.shouldExcludeInstance ? !this.shouldExcludeInstance(instanceName, this.filter) : true)
    }

    protected onRelevantNewInstances (remote: ManagedVrpcRemote, addedInstances: string[], options: InstanceInfo): void {
        for (const instanceName of addedInstances) {
            void this.addInstance(remote, instanceName, options)
        }
    }

    protected onRelevantGoneInstances (goneInstances: string[], options: InstanceInfo): void {
        for (const instanceName of goneInstances) {
            void this.removeInstance(instanceName, options)
        }
    }

    protected async onConnected (remote: ManagedVrpcRemote): Promise<void> {
        await Promise.all([
            this.withInstanceManager(async (manager) => {
                const instances = this.getInstanceConfigurations(remote).map(([instanceName, options]) => this.addInstance(remote, instanceName, options))

                if (!isArrayNotEmpty(await Promise.all(instances))) {
                    /** If nothing was added, then there will be no change process being triggered, so we need to manually doe it */
                    manager.emitChange()
                }
            }),
            this.withRemoteManager((manager) => manager.addRemote(remote)),
        ])
    }
}
