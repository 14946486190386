import { DEFAULT_SCHEMA, dump, load, Type, YAMLException } from 'js-yaml'

import { areArrayEquals, executeOnce } from '../../../../utils'
import { ConnectwareError, ConnectwareErrorType } from '../../../../domain'

abstract class CustomType<Serialized, Deserialized> extends Type {
    protected readonly tag: string

    private static buildCustomTag (name: string): string {
        return `!${name}`
    }

    constructor (name: string, kind: NonNullable<Type['kind']>) {
        const tag = CustomType.buildCustomTag(name)

        super(tag, {
            kind,
            resolve: (data) => this.shouldDeserialize(data),
            construct: (data) => this.deserialize(data),
            predicate: (data) => this.shouldSerialize(data),
            represent: (data) => this.serialize(data as Deserialized),
        })

        this.tag = tag
    }

    protected abstract shouldDeserialize (value: unknown): value is Serialized

    protected abstract deserialize (value: Serialized): Deserialized

    protected abstract shouldSerialize (value: unknown): value is Deserialized

    protected abstract serialize (value: Deserialized): Serialized
}

/**
 * Serializes custom Commissioning file yaml strings back and from yaml to json
 */
class StringCustomType extends CustomType<string, string> {
    private readonly emptyEntry: string = `${this.tag} `

    constructor (name: string) {
        super(name, 'scalar')
    }

    // eslint-disable-next-line class-methods-use-this
    protected shouldDeserialize (value: unknown): value is string {
        return typeof value === 'string'
    }

    protected deserialize (value: string): string {
        return [this.emptyEntry, value].join('')
    }

    protected shouldSerialize (value: unknown): value is string {
        return this.shouldDeserialize(value) && value.startsWith(this.emptyEntry)
    }

    protected serialize (value: string): string {
        return value.substring(this.emptyEntry.length)
    }
}

/**
 * Serializes custom Commissioning file yaml objects back and from yaml to json
 */
class ObjectCustomType extends CustomType<object, object> {
    constructor (name: string) {
        super(name, 'mapping')
    }

    // eslint-disable-next-line class-methods-use-this
    protected shouldDeserialize (value: unknown): value is object {
        return typeof value === 'object'
    }

    protected deserialize (value: object): object {
        return { [this.tag]: value }
    }

    protected shouldSerialize (value: unknown): value is object {
        /**
         * Only return true if the object is { [this.tag]: .... }
         * This filters out other mapping types
         */
        return this.shouldDeserialize(value) && this.tag in value && areArrayEquals(Object.keys(value), [this.tag])
    }

    protected serialize (value: object): object {
        return value[this.tag as keyof typeof value] as object
    }
}

const getCommissioningFileParserSchema = executeOnce(
    () => DEFAULT_SCHEMA.extend([new StringCustomType('ref'), new StringCustomType('sub'), new ObjectCustomType('merge')]),
    true
)

/**
 * Converts the given commissioning file into a json
 */
export const toJson = (yamlContent: string): unknown => {
    try {
        return load(yamlContent, { schema: getCommissioningFileParserSchema() })
    } catch (e: unknown) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not parse yaml', {
            reason: e instanceof YAMLException ? e.reason : null,
            content: yamlContent,
        })
    }
}

/**
 * Converts the given object into a comissioning file
 */
export const fromJson = (json: unknown): string => dump(json, { schema: getCommissioningFileParserSchema() })
