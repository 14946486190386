import { mapMapping } from '../../../../../Connectware'
import { MAPPING_CLASSNAME_FILTER } from '../../../../constants'
import { type MappingProxy } from '../../../../proxies'
import { createProxyEventsHandler, excludeInstanceByServiceName, type VrpcHandlerConfiguration } from '../Handler'

import { BaseMappingMapper, VrpcBaseMappingProxyInstanceHandler } from './Base'

class MappingMapper extends BaseMappingMapper<'mappings'> {
    readonly onChange = createProxyEventsHandler<MappingProxy>('state')

    protected readonly mapMapping = mapMapping

    protected fetchInformation (): Promise<void> {
        return Promise.resolve()
    }
}

export class VrpcMappingProxyInstanceHandler extends VrpcBaseMappingProxyInstanceHandler<'mappings'> {
    readonly instanceMapper = new MappingMapper()

    readonly configuration: VrpcHandlerConfiguration = {
        classNameFilter: MAPPING_CLASSNAME_FILTER,
        supportedFilters: ['service'],
        excludeByInstanceName: excludeInstanceByServiceName,
    }
}
