import type { ValuesType } from 'utility-types'

import { areArrayEquals, copyObjectWithoutKeys, entries, isArrayNotEmpty, type NonEmptyArray, type ReadonlyRecord } from '../../../../utils'
import {
    type BooleanCommissioningFileField,
    type CommissioningFileField,
    type CommissioningFileFields,
    CommissioningFileFieldType,
    ConnectwareError,
    ConnectwareErrorType,
    type IntegerCommissioningFileField,
    type NumberCommissioningFileField,
    type OptionsCommissioningFileField,
    type StringArrayCommissioningFileField,
    type StringCommissioningFileField,
    type StringIndexCommissioningFileField,
} from '../../../../domain'

import type { AllSchemasResponse } from '../../../Connectware'
import {
    isExpectedBooleanField,
    isExpectedIntegerField,
    isExpectedStringField,
    isMinimalDefinitionsFileSchema,
    isMinimalObjectSchema,
    isMinimalRequiredBaseSchema,
    isMinimalRequiredOrchestratingSchema,
    type MinimalRequiredBaseSchema,
    type MinimalRequiredOrchestratingSchema,
    type RefSchema,
} from '../types'
import { ObjectSchemaMapper, type ObjectSchemaMapperConfig } from './Schema'
import { ENDPOINT_MODE_FIELD_NAME, INDEX_FIELD_NAME } from './Strategies'

/**
 * Retrieves from the given collection the first item only if there is one item to be retrieved
 */
const mapOnlyFirst = <T>(collection: T[]): T => {
    if (!isArrayNotEmpty(collection) || collection.length !== 1) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not map only first', { collection })
    }

    return collection[0]
}

const extractTypedSchema = <T>(schema: unknown, validator: (schema: unknown) => schema is T): T => {
    if (!validator(schema)) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Provided schema is invalid', { schema, validator: validator.name })
    }

    return schema
}

const extractProperties = (obj: ReadonlyRecord<string, unknown>, extraction: NonEmptyArray<string>): ReadonlyRecord<string, unknown> => {
    const left = [...extraction]
    let target = obj

    while (isArrayNotEmpty(left)) {
        const removed = left.shift() as string
        const newTarget = target[removed]

        if (!newTarget || typeof newTarget !== 'object') {
            throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not extract object sub properties', { obj, extraction })
        }

        target = newTarget as typeof target
    }

    return target
}

const extractDefinitionByRef = <S extends ReadonlyRecord<string, unknown>>(
    schemas: AllSchemasResponse,
    { $ref }: RefSchema,
    schemaValidator: (schema: unknown) => schema is S
): [S, unknown] => {
    const [filename, definitionPath = ''] = $ref.split('#')

    if (!filename) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not extract filename from ref', { $ref })
    }

    if (!(filename in schemas)) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Filename does not exist on schemas', { filename, schemas: Object.keys(schemas) })
    }

    const otherSchema = extractTypedSchema(schemas[filename as keyof typeof schemas], schemaValidator)
    const path = definitionPath.split('/').filter((p) => p)

    if (!isArrayNotEmpty(path)) {
        /** No path to fetech */
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Path in reference is missing', { $ref })
    }

    return [otherSchema, extractProperties(otherSchema, path)]
}

const addField = <F extends CommissioningFileField, N extends Extract<CommissioningFileField, Readonly<{ name: string }>>>(
    fields: ReadonlyRecord<string, F>,
    field: N
): ReadonlyRecord<string, F | N> => {
    if (field.name in fields) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Field collection already has a field with the same name', {
            fields: Object.keys(fields),
            name: field.name,
        })
    }

    return { [field.name]: field, ...fields }
}

const mapResourceNameField = (baseSchema: MinimalRequiredBaseSchema): StringIndexCommissioningFileField => {
    /** Retrieve the resources field */
    const { resources: field } = new ObjectSchemaMapper({
        ignoreProperty: ({ context, property }) =>
            !areArrayEquals(['resources'], context, { sort: false }) ||
            /** These type checks ensure that the casting below is not a lie */
            typeof property !== 'object' ||
            !property ||
            !('type' in property) ||
            property.type !== 'object',
        prepareProperty: ({ property }) => ({ ...(property as ReadonlyRecord<string, unknown>), $id: INDEX_FIELD_NAME }),
    }).mapTypedFields(baseSchema, [CommissioningFileFieldType.STRING_INDEX])

    if (!field) {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Index field could not be found', { schema: baseSchema })
    }

    return field
}

type NestedOptions<F> = ReadonlyRecord<string, ReadonlyRecord<string, F>>
type SupportedOrchestratingOptionsFields =
    | StringCommissioningFileField
    | NumberCommissioningFileField
    | IntegerCommissioningFileField
    | BooleanCommissioningFileField
    | StringArrayCommissioningFileField

abstract class OrchestratingSchemaFieldsMapper {
    constructor (private readonly schema: MinimalRequiredOrchestratingSchema, private readonly schemas: AllSchemasResponse) {}

    private mapAditionalOrchestratorNestedOptions (): NestedOptions<BooleanCommissioningFileField | NumberCommissioningFileField> {
        const { oneOf = [] } = this.schema
        const mapper = new ObjectSchemaMapper({ recursive: true, ignoreProperty: ({ optional }) => !optional })

        return oneOf.reduce<ReturnType<OrchestratingSchemaFieldsMapper['mapAditionalOrchestratorNestedOptions']>>(
            (alternatives, alternative) => ({
                ...alternatives,
                [mapOnlyFirst(alternative.required)]: mapper.mapTypedFields({ type: 'object', ...alternative }, [
                    CommissioningFileFieldType.NUMBER,
                    CommissioningFileFieldType.BOOLEAN,
                ]),
            }),
            {}
        )
    }

    private mapOrchestratedFields (propName: string, ref: RefSchema): ReadonlyRecord<string, SupportedOrchestratingOptionsFields> {
        const [properties, definition] = extractDefinitionByRef(this.schemas, ref, isMinimalDefinitionsFileSchema)

        /**
         * Some properties have conditional schemas attached to them
         * Which make then other props go from optional to required
         * In order to not let that happen, both affecting and affected props then need to be removed
         */
        const complexPropsToIgnore = new Set<string>(
            Object.values(properties.definitions)
                .flatMap((props) => [props.if, props.then])
                .flatMap(({ required = [] } = {}) => required)
        )

        const ignoreComplexProps = (context: NonEmptyArray<string>): boolean => {
            if (!complexPropsToIgnore.size) {
                /** Nothing to ignore */
                return false
            }

            const [lastProp] = context.slice(-1)
            return complexPropsToIgnore.has(lastProp as string)
        }

        /**
         * Certain props are optional, but have required subprops
         * This means a complex implementation so we are dropping it
         */
        const ignoreOptionalParentWithRequiredChild = (optional: boolean, parent: unknown): boolean =>
            Boolean(optional && isMinimalObjectSchema(parent) && parent.required?.length)

        return new ObjectSchemaMapper({
            recursive: true,
            ignoreProperty: (args) =>
                ignoreComplexProps(args.context) ||
                ignoreOptionalParentWithRequiredChild(args.optional, args.property) ||
                this.ignoreOrchestratedProperties(args),
            prepareProperty: ({ property, optional }) => {
                if (
                    property &&
                    typeof property === 'object' &&
                    'type' in property &&
                    optional &&
                    Array.isArray(property.type) &&
                    areArrayEquals(property.type, ['string', 'null'], { sort: false }) &&
                    'default' in property &&
                    property.default === null
                ) {
                    /** This branch converts a nullable string entry into a valid string entry */
                    return copyObjectWithoutKeys({ ...property, type: 'string' }, 'default')
                }

                return property
            },
        }).mapTypedFields({ type: 'object', properties: { [propName]: definition }, required: [propName] }, [
            CommissioningFileFieldType.STRING,
            CommissioningFileFieldType.INTEGER,
            CommissioningFileFieldType.NUMBER,
            CommissioningFileFieldType.BOOLEAN,
            CommissioningFileFieldType.STRING_ARRAY,
        ])
    }

    private mapAllOrchestratorNestedOptions (): NestedOptions<NestedOptions<SupportedOrchestratingOptionsFields>> {
        const { allOf } = this.schema

        /**
         * Certain orchestrator schemas have additional fields
         * that need to be appended to the main hinge options
         */
        const additionalFieldOptions = this.mapAditionalOrchestratorNestedOptions()

        /**
         * First we need to iterate through the conditionals schemas on the orchestrator schema
         * This will generate a structure of:
         *
         * { [firstLevelHinges]: { [secondLevelHinges]: { ['fieldNames']: fields } } }
         */
        return allOf.reduce<ReturnType<OrchestratingSchemaFieldsMapper['mapAllOrchestratorNestedOptions']>>((hingeOptions, outerAlternative) => {
            const hingeFieldName = mapOnlyFirst(outerAlternative.if.required)
            const hingeFieldOption = outerAlternative.if.properties[hingeFieldName]?.const
            if (!hingeFieldOption) {
                throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Schema does not have a property that can act as a hinge', {
                    hingeFieldName,
                    alternative: outerAlternative.if.properties,
                })
            }

            return {
                ...hingeOptions,
                [hingeFieldName]: {
                    ...hingeOptions[hingeFieldName],
                    [hingeFieldOption]: entries(outerAlternative.then.properties).reduce<NestedOptions<SupportedOrchestratingOptionsFields>>(
                        (r, [propName, ref]) => ({
                            ...r,
                            [propName]: {
                                ...this.mapOrchestratedFields(propName, ref),
                                /** Append additional fields if there is any to append */
                                ...((propName in additionalFieldOptions && additionalFieldOptions[propName]) || {}),
                            },
                        }),
                        {}
                    ),
                },
            }
        }, {})
    }

    private mapOrchestratorFields (): ReadonlyRecord<string, CommissioningFileField> {
        const mapper = new ObjectSchemaMapper({ recursive: true, ignoreProperty: (args) => this.ignoreOrchestratorProperties(args) })

        return mapper.mapTypedFields(this.schema, [CommissioningFileFieldType.STRING, CommissioningFileFieldType.INTEGER, CommissioningFileFieldType.BOOLEAN])
    }

    protected abstract mapHingeOption (hingeSubOptions: NestedOptions<SupportedOrchestratingOptionsFields>): ValuesType<OptionsCommissioningFileField['options']>

    // eslint-disable-next-line class-methods-use-this
    protected ignoreOrchestratorProperties (...[{ property }]: Parameters<ObjectSchemaMapperConfig['ignoreProperty']>): boolean {
        return ![isExpectedStringField, isExpectedIntegerField, isExpectedBooleanField, isMinimalObjectSchema].some((validate) => validate(property))
    }

    // eslint-disable-next-line class-methods-use-this
    protected ignoreOrchestratedProperties (..._: Parameters<ObjectSchemaMapperConfig['ignoreProperty']>): boolean {
        return false
    }

    map (): ReadonlyRecord<string, CommissioningFileField> {
        const allOptions = this.mapAllOrchestratorNestedOptions()

        const [hingeName, hingeOptions] = mapOnlyFirst(entries(allOptions))
        const { [hingeName]: baseHingeField, ...topFields } = this.mapOrchestratorFields()

        if (!baseHingeField || baseHingeField.type !== CommissioningFileFieldType.STRING) {
            throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not find string hinge field', {
                name: hingeName,
                fields: Object.keys(topFields),
            })
        }

        return addField(topFields, {
            ...copyObjectWithoutKeys(baseHingeField, 'maxLength', 'minLength'),
            type: CommissioningFileFieldType.OPTIONS,
            name: hingeName,
            ...entries(hingeOptions).reduce<Pick<OptionsCommissioningFileField, 'allowableValues' | 'options'>>(
                ({ allowableValues, options }, [optionName, hingeSubOptions]) => ({
                    allowableValues: [...(allowableValues ?? []), optionName] as NonEmptyArray<string>,
                    options: { ...options, [optionName]: this.mapHingeOption(hingeSubOptions) },
                }),
                { allowableValues: null, options: {} }
            ),
        })
    }
}

class ConnectionOrchestratingSchemaFieldsMapper extends OrchestratingSchemaFieldsMapper {
    protected ignoreOrchestratorProperties (...[args]: Parameters<ObjectSchemaMapperConfig['ignoreProperty']>): boolean {
        /**
         * Ignores the host at the top level as it is only noise
         */
        return areArrayEquals(args.context, ['connection', 'host'], { sort: false }) || super.ignoreOrchestratorProperties(args)
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapHingeOption (options: NestedOptions<SupportedOrchestratingOptionsFields>): ValuesType<OptionsCommissioningFileField['options']> {
        /** Connections always have only one entry */
        const [, fields] = mapOnlyFirst(entries(options))
        return fields
    }
}

class EndpointOrchestratingSchemaFieldsMapper extends OrchestratingSchemaFieldsMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapHingeOption (options: NestedOptions<SupportedOrchestratingOptionsFields>): ValuesType<OptionsCommissioningFileField['options']> {
        const fields = entries(options)

        if (fields.length === 1) {
            /**
             * If there is only one option, no reason why a mode should be rendered
             * So just pass the internal parameters
             */
            const [, firstOptions] = mapOnlyFirst(fields)
            return firstOptions
        }

        return {
            mode: {
                type: CommissioningFileFieldType.OPTIONS,
                name: ENDPOINT_MODE_FIELD_NAME,
                allowableValues: fields.map(([n]) => n) as NonEmptyArray<string>,
                options,
                optional: false,
                default: null,
                description: null,
            },
        }
    }

    protected ignoreOrchestratedProperties (...[args]: Parameters<ObjectSchemaMapperConfig['ignoreProperty']>): boolean {
        const { property } = args

        if (
            property &&
            typeof property === 'object' &&
            'type' in property &&
            property.type === 'array' &&
            'items' in property &&
            property.items &&
            typeof property.items === 'object' &&
            !('type' in property.items)
        ) {
            /** Ignore properties that are arrays without a defined single primitive type */
            return true
        }

        return super.ignoreOrchestratedProperties(args)
    }
}

const mapMetadata = (baseSchema: MinimalRequiredBaseSchema): CommissioningFileFields['metadata'] => {
    const rootMetadaDataMapper = new ObjectSchemaMapper({
        /**
         * This exclusion is here to prevent objects with a format parameter,
         * as that is not supported on the domain of the application
         */
        ignoreProperty: ({ property }) => Boolean(property && typeof property === 'object' && 'format' in property && typeof property.format === 'string'),
    })

    const properMetadaDataMapper = new ObjectSchemaMapper({
        /** Only accept the description */
        ignoreProperty: ({ context }) => !areArrayEquals(['description'], context, { sort: false }),
    })

    return {
        ...rootMetadaDataMapper.mapTypedFields(baseSchema.properties.metadata, [CommissioningFileFieldType.STRING]),
        ...properMetadaDataMapper.mapTypedFields(baseSchema, [CommissioningFileFieldType.STRING]),
    }
}

export const mapCommissioningFileFields = (schemas: AllSchemasResponse): CommissioningFileFields => {
    const baseSchema = extractTypedSchema(schemas.baseSchema, isMinimalRequiredBaseSchema)
    const connectionSchema = extractTypedSchema(schemas.connectionSchema, isMinimalRequiredOrchestratingSchema)
    const endpointSchema = extractTypedSchema(schemas.endpointSchema, isMinimalRequiredOrchestratingSchema)

    const nameField = mapResourceNameField(baseSchema)

    return {
        metadata: mapMetadata(baseSchema),
        connection: addField(new ConnectionOrchestratingSchemaFieldsMapper(connectionSchema, schemas).map(), nameField),
        endpoint: addField(new EndpointOrchestratingSchemaFieldsMapper(endpointSchema, schemas).map(), nameField),
    }
}
