import { type DependencyList, useCallback } from 'react'

import { normalize, type ReadonlyRecord, search } from '../../../../utils'
import { ConnectwareError, ConnectwareErrorType, type StatusType } from '../../../../domain'

import { useStatusTranslator } from '../Status'
import type { CustomizedState, Searcher } from './Types'

export const useStatusSearcher = (): Searcher<StatusType> => {
    const translator = useStatusTranslator()
    return (status, { normalizedTerms }) => search(normalize(translator(status)), normalizedTerms)
}

/**
 * Callback for handling page changes for the table component
 *
 * @param callback - Function to execute when the 'page' property changes
 * @param deps - Dependencies that control when the callback should be triggered
 */
export const usePageChangeCallback = <T>(
    callback: (state: Extract<CustomizedState<T>, ReadonlyRecord<'page', unknown>>) => void,
    deps: DependencyList
): ((s: CustomizedState<T>) => void) => {
    return useCallback((s: CustomizedState<T>) => {
        if ('page' in s) {
            callback(s)
        } else {
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Unexpected table customization', s)
        }
    }, deps)
}
