import type { PickByValueExact } from 'utility-types'

import { Capability, type StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { mapToStatusType } from '../../../../../Connectware'
import { type BaseStrategyRequestArgs, BaseSubscriptionStrategy } from '../Base'

type Config = Pick<BaseStrategyRequestArgs<never, never>, 'path' | 'method' | 'capability'>

abstract class StatusStrategy<K extends keyof PickByValueExact<SubscriptionsTypes, StatusType>> extends BaseSubscriptionStrategy<K> {
    protected abstract readonly config: Config

    /**
     * @deprecated this will no longer be relevant once RST is introduced
     * @todo consider no longer using this
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    retrieveOne (id: string): Promise<SubscriptionsTypes[K]> {
        return this.retrieve({ ...this.config, pathParams: [id], mapper: (data: string | null) => mapToStatusType(data), handleNotFound: true })
    }
}

export class MappingsStatusStrategy extends StatusStrategy<'mappingStatus'> {
    readonly requiredPermissions = [Capability.MAPPING_STATE_READ]

    /**
     * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/swagger/swagger.yaml#lines-286
     */
    protected readonly config: Config = { capability: Capability.MAPPING_STATE_READ, method: 'GET', path: '/api/mappings/+/state' }
}

export class NodesStatusStrategy extends StatusStrategy<'nodeStatus'> {
    readonly requiredPermissions = [Capability.NODE_STATE_READ]

    /**
     * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/swagger/swagger.yaml#lines-410
     */
    protected readonly config: Config = { capability: Capability.NODE_STATE_READ, method: 'GET', path: '/api/nodes/+/state' }
}

export class EndpointsStatusStrategy extends StatusStrategy<'endpointStatus'> {
    readonly requiredPermissions = [Capability.ENDPOINT_STATE_READ]

    /**
     * @see https://bitbucket.org/cybusio/cybus/src/624d6b87cbeb24b8a53bc0e25ae894f68b1c6234/protocol-mapper/src/swagger/swagger.yaml#lines-150
     */
    protected readonly config: Config = { capability: Capability.ENDPOINT_STATE_READ, method: 'GET', path: '/api/endpoints/+/state' }
}
