import type { StatusType } from '../../../../../../domain'
import type { SubscriptionsTypes } from '../../../../../../application'

import { type ConnectionProxyParams, mapToStatusType } from '../../../../../Connectware'
import type { ConnectionProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    type VrcpEntityHandler,
    type VrpcHandlerConfiguration,
    type VrpcInstanceDomainMapperArgs,
    type VrpcInstanceMapper,
} from '../Handler'

export abstract class BaseConnectionMapper<T extends keyof SubscriptionsTypes> implements VrpcInstanceMapper<ConnectionProxy, SubscriptionsTypes[T]> {
    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ConnectionProxy>('state')

    protected abstract mapConnection (connection: ConnectionProxyParams, statusType: StatusType): SubscriptionsTypes[T]

    mapToDomain ({ instance }: VrpcInstanceDomainMapperArgs<ConnectionProxy>): Promise<SubscriptionsTypes[T]> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getParams().then((params) => {
            const currentStatus = mapToStatusType(params.currentState)

            return this.mapConnection(params, currentStatus)
        })
    }
}

export abstract class VrpcBaseConnectionProxyInstanceHandler<T extends keyof SubscriptionsTypes>
    implements VrcpEntityHandler<ConnectionProxy, SubscriptionsTypes[T]> {
    abstract readonly instanceMapper: VrpcInstanceMapper<ConnectionProxy, SubscriptionsTypes[T]>

    readonly remoteMapper = null

    abstract readonly configuration: VrpcHandlerConfiguration
}
