import React, { type FC } from 'react'
import { FormControlLabel, Input, Switch, type SxProps, type Theme } from '@mui/material'
import { Close, Search } from '@mui/icons-material'

import { selectUserManagementSelectedTabSearch, selectUserManagementSelectedTabShowInternal, Translation, type UserManagementTabsTypes } from '../../../domain'

import { FormattedTranslation, useTranslator } from '../Internationalization'
import { useAppState, useAppUsecase } from '../State'

const searchStyle: SxProps<Theme> = { mb: 1 }
const resetIconStyle: SxProps<Theme> = { cursor: 'pointer' }

export const Filter: FC<{ selected: keyof UserManagementTabsTypes }> = ({ selected }) => {
    const usecase = useAppUsecase('filterUserManagementUsecase')
    const isShow = useAppState((s) => selectUserManagementSelectedTabShowInternal(s, selected), [selected])

    return (
        <FormControlLabel
            control={<Switch data-testid="show-internal" checked={isShow} onChange={() => usecase.toggleShowInternal(selected)} />}
            label={<FormattedTranslation id={Translation.SHOW_USER_MANAGEMENT_RESULTS} values={{ type: selected }} />}
        />
    )
}

export const SearchField: FC<{ selected: keyof UserManagementTabsTypes }> = ({ selected }) => {
    const translator = useTranslator()
    const usecase = useAppUsecase('filterUserManagementUsecase')
    const search = useAppState((s) => selectUserManagementSelectedTabSearch(s, selected), [selected])

    return (
        <Input
            data-testid="search-input"
            sx={searchStyle}
            fullWidth
            endAdornment={search ? <Close data-testid="clear-search-input" sx={resetIconStyle} onClick={() => usecase.search(selected, '')} /> : <Search />}
            placeholder={translator.formatTranslation(Translation.USER_MANAGEMENT_SEARCH_PLACEHOLDER, { type: selected })}
            value={search}
            onChange={(e) => usecase.search(selected, e.target.value)}
        />
    )
}
