import React, { type FC } from 'react'

import {
    canMappingBeDeleted,
    canMappingBeDisabled,
    canMappingBeEnabled,
    Capability,
    createIsAuthenticatedWithCapabilitiesSelector,
    type CybusMapping,
    selectDeletableLoadedMappings,
    selectDisabableLoadedMappings,
    selectEnabableLoadedMappings,
    selectMappingsPage,
    type StatusType,
    Translation,
} from '../../../domain'

import { useTranslator } from '../Internationalization'
import { RelativeRoutePathWithId } from '../routing'
import { RedirectingResourcesTable } from '../Resources'
import { Status, useClearTableSelection, useTableStatusSearcher } from '../common'

import { DeleteModal, DisableModal, EnableModal } from './Bulk'

const Modals: FC = () => {
    const clear = useClearTableSelection()
    return (
        <>
            <DeleteModal mappingSelector={selectDeletableLoadedMappings} onConfirmed={clear} />
            <EnableModal mappingSelector={selectEnabableLoadedMappings} onConfirmed={clear} />
            <DisableModal mappingSelector={selectDisabableLoadedMappings} onConfirmed={clear} />
        </>
    )
}

const selectCanManageMappings = createIsAuthenticatedWithCapabilitiesSelector(Capability.MAPPINGS_MANAGE)

export const Table: FC<Readonly<{ serviceId?: string }>> = ({ serviceId = null }) => {
    const translator = useTranslator()

    const searcher = useTableStatusSearcher()
    const customCellRender = (status: StatusType): JSX.Element => <Status status={status} />

    return (
        <RedirectingResourcesTable<CybusMapping, CybusMapping, 'bulkDeleteMappingsUsecase' | 'bulkDisableMappingsUsecase' | 'bulkEnableMappingsUsecase'>
            subscriptionUsecase="manageMappingsUsecase"
            filter={serviceId ? { service: serviceId } : undefined}
            data={selectMappingsPage}
            hasManagementCapabilitiesSelector={selectCanManageMappings}
            dataTableMapper={(mappings) => mappings}
            columns={{
                ...(serviceId ? {} : { service: { label: Translation.SERVICE_ID, sort: true } }),
                name: { label: Translation.NAME, sort: true },
                entriesCount: { label: translator.formatTranslation(Translation.ENTRY, { count: 2 }), sort: true },
                status: { label: Translation.STATUS, searcher, customCellRender, sort: true },
            }}
            short={Boolean(serviceId)}
            redirectOnRowclick={RelativeRoutePathWithId.MAPPING}
            selection={{
                DELETE: { usecase: 'bulkDeleteMappingsUsecase', filter: canMappingBeDeleted },
                DISABLE: { usecase: 'bulkDisableMappingsUsecase', filter: canMappingBeDisabled },
                ENABLE: { usecase: 'bulkEnableMappingsUsecase', filter: canMappingBeEnabled },
                resource: 'id',
                usecaseMethod: 'selectMappings',
            }}
            translations={{ noResultsOrEmptyTable: Translation.NO_MAPPINGS_AVAILABLE }}
        >
            <Modals />
        </RedirectingResourcesTable>
    )
}
