import type { Mutable, ValuesType } from 'utility-types'

import type { ReadonlyRecord } from '../../utils'
import type { CustomizedTable } from '../../domain'
import type { TablePersistenceService } from '../../application'

import { BaseWebStorage } from './Base'

type SerializedCustomTable<L extends ReadonlyRecord<string, unknown>> = [
    page: CustomizedTable<L>['page'],
    rowsPerPage: CustomizedTable<L>['pageSize'],
    sort: [NonNullable<CustomizedTable<L>['sort']>['column'], number] | number,
    search: CustomizedTable<L>['search']
]

export type AllPersistedTables = ReadonlyRecord<string, Partial<CustomizedTable<ReadonlyRecord<string, unknown>>>>
export type AllSerializedCustomTables = ReadonlyRecord<string, SerializedCustomTable<ReadonlyRecord<string, unknown>>>

export class WebStorageTablePersistenceService
    extends BaseWebStorage<AllPersistedTables, AllPersistedTables, AllSerializedCustomTables>
    implements TablePersistenceService {
    protected readonly fallbackValue = {}

    protected readonly key = 'cwTables'

    // eslint-disable-next-line class-methods-use-this
    protected toSerializable (allTables: AllPersistedTables): AllSerializedCustomTables {
        return Object.entries(allTables).reduce<AllSerializedCustomTables>(
            (r, [id, { page, pageSize, sort, search }]) => ({
                ...r,
                [id]: [page !== undefined ? page : 0, pageSize !== undefined ? pageSize : 0, sort ? [sort.column, Number(sort.asc)] : 0, search || ''],
            }),
            {}
        )
    }

    // eslint-disable-next-line class-methods-use-this
    protected isValidSerializable (unknownValue: unknown): unknownValue is AllSerializedCustomTables {
        if (!unknownValue || typeof unknownValue !== 'object') {
            return false
        }

        return Object.entries(unknownValue).every(([id, unknownValue]) => {
            if (typeof id !== 'string' || !Array.isArray(unknownValue) || unknownValue.length !== 4) {
                return false
            }

            const [page, pageSize, sort, search] = unknownValue

            if (typeof page !== 'number') {
                return false
            }

            if (typeof pageSize !== 'number') {
                return false
            }

            if (typeof sort !== 'number' && (!Array.isArray(sort) || sort.length !== 2 || typeof sort[0] !== 'string' || typeof sort[1] !== 'number')) {
                return false
            }

            if (typeof search !== 'string') {
                return false
            }

            return true
        }, {})
    }

    // eslint-disable-next-line class-methods-use-this
    protected fromSerializable (serialized: AllSerializedCustomTables): AllPersistedTables {
        return Object.entries(serialized).reduce((r, [id, unknownValue]) => {
            const [page, pageSize, sort, search] = unknownValue

            const table: Mutable<ValuesType<AllPersistedTables>> = {}

            if (page > 0) {
                table.page = page
            }

            if (pageSize > 0) {
                table.pageSize = pageSize
            }

            if (Array.isArray(sort) && sort.length === 2 && typeof sort[0] === 'string' && typeof sort[1] === 'number') {
                table.sort = { column: sort[0], asc: Boolean(sort[1]) }
            }

            if (search) {
                table.search = search
            }

            return { ...r, [id]: table }
        }, {})
    }

    retrieveTable<L extends ReadonlyRecord<string, unknown>> (id: string): Partial<CustomizedTable<L>> {
        return this.retrieveDeserialize()[id] ?? {}
    }

    persistTable<L extends ReadonlyRecord<string, unknown>> (id: string, table: Partial<CustomizedTable<L>>): void {
        const deserialized = this.retrieveDeserialize()
        this.persistSerialize({ ...deserialized, [id]: table as Partial<CustomizedTable<ReadonlyRecord<string, unknown>>> })
    }
}
