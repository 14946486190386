import { ConnectwareError, ConnectwareErrorType, type CybusDetailedVolume, type CybusVolume } from '../../../../../../domain'

import { CONTAINER_MANAGER_AGENT, mapDetailedVolume, mapVolumes } from '../../../../../Connectware'
import { CONTAINER_MANAGER_CLASSNAME, CONTAINER_MANAGER_DOCKER_INSTANCE } from '../../../../constants'
import type { ContainerManagerOrchestratorProxy } from '../../../../proxies'
import type { VrcpEntityHandler, VrpcHandlerConfiguration, VrpcInstanceDomainMapperArgs, VrpcInstanceMapper } from '..'
import { VrpcContainerManagerOrchestratorProxyBaseMapper } from './Base'

class VrpcContainerManagerOrchestratorProxyToVolumeMapper
    extends VrpcContainerManagerOrchestratorProxyBaseMapper<CybusVolume>
    implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, CybusVolume> {
    async mapToDomain ({ instance, filter }: VrpcInstanceDomainMapperArgs<ContainerManagerOrchestratorProxy>): Promise<Map<string, CybusVolume>> {
        const { Volumes } = await instance.listVolumes()
        return mapVolumes(Volumes, filter).reduce<Map<string, CybusVolume>>((r, volume) => r.set(volume.id, volume), new Map())
    }
}

class VrpcContainerManagerOrchestratorProxyToDetailedVolumeMapper
    extends VrpcContainerManagerOrchestratorProxyBaseMapper<CybusDetailedVolume>
    implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, CybusDetailedVolume> {
    async mapToDomain ({ instance, instanceName }: VrpcInstanceDomainMapperArgs<ContainerManagerOrchestratorProxy>): Promise<CybusDetailedVolume> {
        const volume = await instance.inspectVolume(instanceName)

        if (!volume) {
            throw new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Could not find volume', { id: instanceName })
        }

        return mapDetailedVolume(volume)
    }
}

const configuration = { classNameFilter: CONTAINER_MANAGER_CLASSNAME, agent: CONTAINER_MANAGER_AGENT, instanceNameFilter: CONTAINER_MANAGER_DOCKER_INSTANCE }

export class VrpcContainerManagerOrchestratorProxyToVolumeHandler implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusVolume> {
    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToVolumeMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { ...configuration, supportedFilters: ['service'] }
}

export class VrpcContainerManagerOrchestratorProxyToDetailedVolumeHandler implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusDetailedVolume> {
    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToDetailedVolumeMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { ...configuration, supportedFilters: [] }
}
