import type { PickByValue } from 'utility-types'
import React, { type FC, type PropsWithChildren, useCallback, useEffect, useMemo } from 'react'

import type { ReadonlyRecord } from '../../../../utils'
import { type AppState, arePagesEquals, ConnectwareError, type Page, type PaginationParameters, type SortableColumn } from '../../../../domain'
import type { PageSubscriptionArgs, SubscriptionFilterArgs, Usecases } from '../../../../application'

import { type AbsoluteRoutePathWithId, type RelativeRoutePathWithId, useRouteWithIdRedirect } from '../../routing'
import { useAppState, useAppUsecase } from '../../State'
import { ErrorMessage } from '../../ErrorMessage'
import { CircularLoader, Table, type TableColumn, type TableCustomizedState, type TableLine, type TablePagination, type TableProps } from '../../common'

import { useSelection, type UseSelectionProps } from './Selection'

/** Includes only supported columns */
type Column<Value, Line,> = Pick<TableColumn<Value, Line>, 'customCellRender' | 'label' | 'searcher'>
export type Columns<Resource, TableResource,> = Partial<Readonly<{ [P in keyof TableResource]: Column<TableResource[P], TableResource> }>> &
    /** This type forces the sorting to now be a boolean, and only if the original resource also contains that column */
    Partial<Readonly<{ [P in SortableColumn<Resource>]: Readonly<{ sort?: true }> }>>

type Props<Resource, TableResource extends TableLine, UsecaseSelectNames extends keyof Usecases,> = Readonly<{
    /**
     * The usecase the table will use to fetch data
     */
    subscriptionUsecase: keyof PickByValue<
        Usecases,
        {
            /** Used to subscribe to changes */
            subscribe(args: PageSubscriptionArgs): VoidFunction
            /** Used to update the pagination parameters */
            updateParameters(update: Partial<PaginationParameters<Resource>>): void
            getPaginationSizes(): number[]
        }
    >

    /**
     * Filter parameters for the resources to be fetched
     */
    filter?: SubscriptionFilterArgs

    /** How data should be provided for the table to be rendered */
    data: (s: AppState) => Page<Resource>

    /**
     * Once errors/loading states have been filtered out
     * This function will be called to assist to creation of the contents of the table itself
     */
    dataTableMapper: (resource: Resource[]) => TableResource[]

    /** The columns of the table */
    columns: Columns<Resource, TableResource>

    /**
     * If the list should be short
     */
    short?: boolean
}> &
    Pick<TableProps<TableResource>, 'onRowClick' | 'translations'> &
    UseSelectionProps<TableResource, UsecaseSelectNames>

/**
 * This component has a bult-in structure to render a table that
 *
 * - While rendered, subscribes to the given usecase (if the same is subscribable)
 * - Handles state changes (loading, error, success yielf) of the given resources in order to properly render the contents
 * - Has a standardized look and feel
 *
 * Children will be rendered with-in the Table component
 */
export const ResourcesTable = <Resource extends TableLine, TableResource extends TableLine = Resource, UsecaseSelectNames extends keyof Usecases = never,>({
    subscriptionUsecase: subscriptionUsecaseName,
    filter = {},
    data: dataSelector,
    hasManagementCapabilitiesSelector,
    dataTableMapper,
    translations,
    selection,
    short = false,
    children,
    ...props
}: PropsWithChildren<Props<Resource, TableResource, UsecaseSelectNames>>): ReturnType<FC> => {
    const subscriptionUsecase = useAppUsecase(subscriptionUsecaseName)

    const resourcesPage = useAppState<Page<Resource>>(dataSelector, arePagesEquals)
    const selectionConfig = useSelection({ hasManagementCapabilitiesSelector, selection })

    /** Subscribe to changes of resources */
    useEffect(
        () => subscriptionUsecase.subscribe({ ...filter, short, throttle: 200 }),
        [subscriptionUsecase, filter.connection, filter.server, filter.service, short]
    )

    /** Load the possible page sizes to be used */
    const pageSizeOptions = useMemo(() => subscriptionUsecase.getPaginationSizes(), [])

    const onCustomized = useCallback(
        (s: TableCustomizedState<TableResource>) => {
            const update =
                'search' in s || 'page' in s || 'pageSize' in s
                    ? s
                    : { sort: { column: s.sortOrder.name as SortableColumn<unknown>, asc: s.sortOrder.direction === 'asc' } }
            subscriptionUsecase.updateParameters(update)
        },
        [subscriptionUsecase]
    )

    if (resourcesPage === null) {
        /* Resources have not loaded */
        return <CircularLoader data-testid="no-data-loader" />
    }

    if (ConnectwareError.is(resourcesPage.data)) {
        return <ErrorMessage data-testid="general-error-message" error={resourcesPage.data} stack extras="section" />
    }

    const pagination: TablePagination | undefined = resourcesPage.data
        ? { ...resourcesPage.pagination, pageSizeOptions, totalCount: resourcesPage.data.totalCount }
        : undefined

    return (
        <Table
            data-testid={subscriptionUsecaseName}
            selection={selectionConfig}
            search={resourcesPage.search === null || resourcesPage.search}
            data={resourcesPage.data === null ? [] : dataTableMapper(resourcesPage.data.current)}
            loading={resourcesPage.data === null}
            pagination={pagination}
            sortOrder={{ name: resourcesPage.sort.column, direction: resourcesPage.sort.asc ? 'asc' : 'desc' }}
            translations={translations}
            onCustomized={onCustomized}
            {...props}
        >
            {children}
        </Table>
    )
}

type RedirectingProps<Resource extends TableLine, TableResource extends TableLine, UsecaseSelectNames extends keyof Usecases = never,> = Omit<
    Props<Resource, TableResource, UsecaseSelectNames>,
    'onRowClick'
> &
    Readonly<{ redirectOnRowclick: AbsoluteRoutePathWithId | RelativeRoutePathWithId }>

/**
 * The base resource needs an id for redirecting else where
 */
type BaseResource = TableLine & ReadonlyRecord<'id', string>

export const RedirectingResourcesTable = <
    Resource extends BaseResource,
    TableResource extends BaseResource = Resource,
    UsecaseSelectNames extends keyof Usecases = never,
>({
    redirectOnRowclick,
    ...props
}: PropsWithChildren<RedirectingProps<Resource, TableResource, UsecaseSelectNames>>): ReturnType<FC> => {
    const onRowClick = useRouteWithIdRedirect<TableResource>(redirectOnRowclick, ({ id }) => id)
    return onRowClick ? <ResourcesTable {...props} onRowClick={onRowClick} /> : <ResourcesTable {...props} />
}
