import { entries, type ReadonlyRecord } from '../../../../../utils'
import {
    type CommissioningFileField,
    type CommissioningFileFields,
    CommissioningFileFieldType,
    type CommissioningFileValue,
    type CommissioningFileValues,
    doCommissioningFileFieldAndValueMatch,
} from '../../../../../domain'
import type { MinimalExpectedServiceData } from '../../types'
import { INDEX_FIELD_NAME, resourceStrategies } from '../Strategies'

const mapCommissioningFileValuesMetadata = (data: MinimalExpectedServiceData, fields: CommissioningFileFields): CommissioningFileValues['metadata'] => {
    const metadata = { ...data.metadata, description: data.description }

    return Object.keys(fields.metadata).reduce((r, name) => ({ ...r, [name]: (name in metadata && metadata[name as keyof typeof metadata]) || null }), {})
}

class PropertiesByFieldsMapper {
    private readonly flattened: Map<string, unknown>

    /**
     * Flattens nested property objects into a flat structure for easier processing.
     *
     * @param properties: properties object to be flattened
     * { protocol: "Modbus", targetState: "connected", connection: { host: "connectware", port: 10504 }}
     *
     * @param depth: A list that shows how deep we've gone into the object. Initially it called with an empty array
     *
     * @returns: { protocol: "Modbus", targetState: "connected", connection.host: "connectware", connection.port: 10504}
     */
    private static flattenProperties (properties: object, depth: string[] = []): ReadonlyRecord<string, unknown> {
        return entries(properties).reduce<ReturnType<typeof PropertiesByFieldsMapper.flattenProperties>>(
            (flattened, [name, property]) => ({
                ...flattened,
                /**
                 * Checks if property is an object. if so, recursively flattens it
                 */
                ...(property && typeof property === 'object'
                    ? PropertiesByFieldsMapper.flattenProperties(property, [...depth, name])
                    : { [[...depth, name].join('.')]: property }), // Otherwise, adds the property to the flattened map
            }),
            {}
        )
    }

    /** Initializes the properties field mapper with fields, properties and overrides */
    constructor (private readonly fields: ReadonlyRecord<string, CommissioningFileField>, properties: object) {
        /** Flattens the properties and stores them */
        this.flattened = new Map(entries(PropertiesByFieldsMapper.flattenProperties(properties)))
    }

    /** Retrieves a value for a given field, first checking overrides & then flattened properties */
    private useValue (name: string): CommissioningFileValue<CommissioningFileField> | undefined {
        if (!this.flattened.has(name)) {
            return undefined
        }

        const value = this.flattened.get(name)
        this.flattened.delete(name)

        return value as CommissioningFileValue<CommissioningFileField>
    }

    /** Maps each field to its value based on the fields */
    private mapByFields (fields: ReadonlyRecord<string, CommissioningFileField>): ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>> {
        return entries(fields).reduce<ReturnType<PropertiesByFieldsMapper['mapByFields']>>((values, [name, field]) => {
            const value = this.useValue(name)

            /** Deal with nested options if present */
            const furtherOptions =
                field.type === CommissioningFileFieldType.OPTIONS && value && doCommissioningFileFieldAndValueMatch(field, value) && field.options[value]
            if (furtherOptions) {
                values = { ...values, ...this.mapByFields(furtherOptions) }
            }

            if (value !== undefined || !field.optional) {
                /** If value is set or required, add the value */
                return { ...values, [name]: value ?? field.default }
            }

            return values
        }, {})
    }

    map (): ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>> {
        return this.mapByFields(this.fields)
    }
}

const mapCommissioningFileResources = (
    { resources }: MinimalExpectedServiceData,
    fields: CommissioningFileFields
): Pick<CommissioningFileValues, 'connections' | 'endpoints'> =>
    entries(resources).reduce<ReturnType<typeof mapCommissioningFileResources>>(
        (resources, [resourceId, resource]) => {
            const mappingConfig = resource.type in resourceStrategies && resourceStrategies[resource.type as keyof typeof resourceStrategies]

            if (mappingConfig) {
                const { properties = {} } = resource
                const { valueName, fieldName, fromJsonToDomain: { remapProperties } = {} } = mappingConfig
                const { [fieldName]: field } = fields
                const remappedProperties = remapProperties ? remapProperties(field, properties) : properties

                return {
                    ...resources,
                    [valueName]: [
                        ...resources[valueName],
                        new PropertiesByFieldsMapper(field, { [INDEX_FIELD_NAME]: resourceId, ...remappedProperties }).map(),
                    ],
                }
            }

            return resources
        },
        { connections: [], endpoints: [] }
    )

export const mapJsonToCommissioningFileValues = (data: MinimalExpectedServiceData, fields: CommissioningFileFields): CommissioningFileValues => ({
    metadata: mapCommissioningFileValuesMetadata(data, fields),
    ...mapCommissioningFileResources(data, fields),
})
