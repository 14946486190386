import React, { type FC } from 'react'
import { type SxProps, type Theme } from '@mui/material'

import { ConnectwareError, selectLicense, StatusType, Translation } from '../../../../../domain'

import { FormattedDateTime, FormattedTranslation } from '../../../Internationalization'
import { CircularLoader, Status, Table } from '../../../common'
import { ErrorMessage } from '../../../ErrorMessage'
import { NoLicense } from './NoLicense'
import { useAppState } from '../../../State'

const errorStyle: SxProps<Theme> = { py: 2 }
const titleStyle: SxProps<Theme> = { width: 250 }

export const LicenseDetails: FC = () => {
    const license = useAppState(selectLicense)
    if (!license) {
        return <CircularLoader />
    }

    if (ConnectwareError.is(license)) {
        return <ErrorMessage sx={errorStyle} error={license} extras="popover" />
    }

    if (!license.activated) {
        return <NoLicense />
    }

    return (
        <Table
            hideHeader
            columns={{
                title: { customCellRender: (value) => <FormattedTranslation id={value} />, sx: titleStyle },
                data: {
                    customCellRender: (value) => {
                        if (typeof value === 'boolean') {
                            return <Status status={value ? StatusType.VALID : StatusType.INVALID} />
                        }

                        if (value instanceof Date) {
                            return <FormattedDateTime date={value} format="dateOnly" />
                        }

                        return value || <FormattedTranslation id={Translation.NOT_AVAILABLE} />
                    },
                },
            }}
            data={[
                { title: Translation.LICENSE_NAME, data: license.name },
                { title: Translation.LICENSE_ACCOUNT, data: license.account },
                { title: Translation.EXPIRATION_DATE, data: license.expiration },
                { title: Translation.LICENSE_CLASS, data: license.class },
                { title: Translation.LICENSE_ID, data: license.id },
                { title: Translation.LICENSE_STATUS, data: license.valid },
            ]}
        />
    )
}
