import type { NonEmptyArray, ReadonlyRecord } from '../../../utils'
import { ConnectwareError, type ConnectwareErrorExtras, ConnectwareErrorType } from '../../Error'

export enum CommissioningFileFieldType {
    BOOLEAN = 'BOOLEAN',
    BOOLEAN_ARRAY = 'BOOLEAN_ARRAY',
    INTEGER = 'INTEGER',
    INTEGER_ARRAY = 'INTEGER_ARRAY',
    NUMBER = 'NUMBER',
    NUMBER_ARRAY = 'NUMBER_ARRAY',
    OPTIONS = 'OPTIONS',
    STRING = 'STRING',
    STRING_ARRAY = 'STRING_ARRAY',
    STRING_INDEX = 'STRING_INDEX',
}

type BaseCommissioningFileField<Type extends CommissioningFileFieldType, Value, P = ReadonlyRecord<never, never>> = Readonly<{
    type: Type
    description: string | null
    optional: boolean
    default: Value | null
    allowableValues: NonEmptyArray<Value> | null
}> &
    Readonly<P>

type BaseArrayCommissioningFileField<Type extends CommissioningFileFieldType, Value> = BaseCommissioningFileField<Type, Value[], { uniqueItems: boolean }>
type BaseNumberCommissioningFileField<Type extends CommissioningFileFieldType> = BaseCommissioningFileField<
    Type,
    number,
    { min: number | null, max: number | null }
>

export type StringIndexCommissioningFileField = BaseCommissioningFileField<CommissioningFileFieldType.STRING_INDEX, string, { name: string, pattern: string }>

export type StringCommissioningFileField = BaseCommissioningFileField<
    CommissioningFileFieldType.STRING,
    string,
    { minLength: number | null, maxLength: number | null, pattern: string | null }
>
export type StringArrayCommissioningFileField = BaseArrayCommissioningFileField<CommissioningFileFieldType.STRING_ARRAY, string>

export type IntegerCommissioningFileField = BaseNumberCommissioningFileField<CommissioningFileFieldType.INTEGER>
export type IntegerArrayCommissioningFileField = BaseArrayCommissioningFileField<CommissioningFileFieldType.INTEGER_ARRAY, number>

export type NumberCommissioningFileField = BaseNumberCommissioningFileField<CommissioningFileFieldType.NUMBER>
export type NumberArrayCommissioningFileField = BaseArrayCommissioningFileField<CommissioningFileFieldType.NUMBER_ARRAY, number>

export type BooleanCommissioningFileField = BaseCommissioningFileField<CommissioningFileFieldType.BOOLEAN, boolean>
export type BooleanArrayCommissioningFileField = BaseArrayCommissioningFileField<CommissioningFileFieldType.BOOLEAN_ARRAY, boolean>
export type OptionsCommissioningFileField = BaseCommissioningFileField<
    CommissioningFileFieldType.OPTIONS,
    string,
    /** For now indexes inside options is not needed */
    { name: string, options: ReadonlyRecord<string, ReadonlyRecord<string, Exclude<CommissioningFileField, StringIndexCommissioningFileField>>> }
>

/**
 * The structural description of a field on a commissioning file
 */
export type CommissioningFileField =
    | BooleanArrayCommissioningFileField
    | BooleanCommissioningFileField
    | IntegerArrayCommissioningFileField
    | IntegerCommissioningFileField
    | NumberArrayCommissioningFileField
    | NumberCommissioningFileField
    | OptionsCommissioningFileField
    | StringArrayCommissioningFileField
    | StringCommissioningFileField
    | StringIndexCommissioningFileField

/**
 * Describes all of the structure of a commissioning file
 */
export type CommissioningFileFields = Readonly<{
    metadata: ReadonlyRecord<string, StringCommissioningFileField>
    connection: ReadonlyRecord<string, CommissioningFileField>
    endpoint: ReadonlyRecord<string, CommissioningFileField>
}>

/**
 * Type extractor, to be used to retrieve the value from CommissioningFileField
 * @see {CommissioningFileField}
 */
export type CommissioningFileValue<F extends CommissioningFileField> = F extends BaseCommissioningFileField<CommissioningFileFieldType, infer U>
    ? U | null
    : never

/**
 * If there are issues with validating a field against a value, this error is created
 */
export class CommissioningFileValueValidationError extends ConnectwareError<
    ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
    Readonly<{
        reason: 'REQUIRED' | 'NOT_IN_ALLOWABLE_VALUES' | 'MIN_LENGTH' | 'MAX_LENGTH' | 'MIN' | 'MAX' | 'INVALID_PATTERN' | 'ITEMS_NOT_UNIQUE' | 'INVALID_TYPE'
        field: CommissioningFileField
    }>
> {
    static is (e: unknown): e is CommissioningFileValueValidationError {
        return e instanceof CommissioningFileValueValidationError
    }

    constructor (reason: ConnectwareErrorExtras<CommissioningFileValueValidationError>['reason'], field: CommissioningFileField) {
        super(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, 'Value is invalid', { reason, field })
    }
}

export type CommissioningFileValidation = CommissioningFileValueValidationError | true
type CommissioningFileValidationWrapper<F> = Readonly<{ value: F, validation: CommissioningFileValidation }>
export type CommissioningFileValidatedValue<F extends CommissioningFileField> = CommissioningFileValidationWrapper<CommissioningFileValue<F>>

/**
 * Describes all the values of a commissioning file
 */
export type CommissioningFileValues = Readonly<{
    metadata: ReadonlyRecord<string, CommissioningFileValue<StringCommissioningFileField>>
    connections: ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>>[]
    endpoints: ReadonlyRecord<string, CommissioningFileValue<CommissioningFileField>>[]
}>

/**
 * Handy recursive type to convert types
 */
type TransformToValidatedValues<T> = {
    [P in keyof T]: T[P] extends CommissioningFileValue<CommissioningFileField> ? CommissioningFileValidationWrapper<T[P]> : TransformToValidatedValues<T[P]>
}

export type CommissioningFileValidatedValues = TransformToValidatedValues<CommissioningFileValues>
