import { areCybusEndpointEquals, Capability, type CybusEndpoint } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { CybusEndpointMapper, type EndpointProxyParams, mapToStatusType } from '../../../../../Connectware'
import { BaseSubscriptionStrategy } from '../Base'

class ArrayCybusEndpointMapper extends CybusEndpointMapper {
    readonly endpoints: CybusEndpoint[] = []

    protected collect (endpoint: CybusEndpoint): void {
        this.endpoints.push(endpoint)
    }
}

export class EndpointsStrategy extends BaseSubscriptionStrategy<'endpoints'> {
    readonly requiredPermissions = [Capability.ENDPOINTS_READ]

    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['service', 'connection']

    protected readonly areEquals = areCybusEndpointEquals

    retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusEndpoint[]> {
        return this.retrieve({
            capability: Capability.ENDPOINTS_READ,
            method: 'GET',
            path: '/api/endpoints',
            mapper: (data: EndpointProxyParams[]) => {
                const mapper = new ArrayCybusEndpointMapper(filter)

                data.forEach((params) => {
                    /**
                     * @deprecated update code block so status is fetched externally
                     * @see https://cybusio.atlassian.net/browse/CC-1251
                     */
                    const currentStatus = mapToStatusType(params.currentState)

                    mapper.push(params, currentStatus)
                })

                return mapper.endpoints
            },
        })
    }
}
