import { areCybusNodesEquals, Capability, type CybusNode } from '../../../../../../domain'
import type { SubscriptionFilterArgs } from '../../../../../../application'

import { CybusNodeMapper, mapToStatusType, type NodeProxyParams } from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

class ArrayCybusNodeMapper extends CybusNodeMapper {
    readonly nodes: CybusNode[] = []

    protected collect (node: CybusNode): void {
        this.nodes.push(node)
    }
}

export class NodesStrategy extends BaseSubscriptionStrategy<'nodes'> {
    readonly requiredPermissions = [Capability.NODES_READ]

    protected readonly supportedFilters: (keyof SubscriptionFilterArgs)[] = ['server']

    protected readonly areEquals = areCybusNodesEquals

    retrieveAll (filter: SubscriptionFilterArgs): Promise<CybusNode[]> {
        return this.retrieve({
            capability: Capability.NODES_READ,
            method: 'GET',
            /**
             * @deprecated this endpoint will no longer provide useful statuses
             * @todo consider using another endpoint
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            path: '/api/nodes',
            mapper: (data: NodeProxyParams[]) => {
                const mapper = new ArrayCybusNodeMapper(filter)
                data.forEach((node) => {
                    /**
                     * @deprecated update code block so status is fetched externally
                     * @see https://cybusio.atlassian.net/browse/CC-1251
                     */
                    const currentStatus = mapToStatusType(node.currentState)

                    mapper.push(node, currentStatus)
                })
                return mapper.nodes
            },
        })
    }
}
