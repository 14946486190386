import { isArrayNotEmpty } from '../../../../../../utils'
import { areLinkedCybusServiceEquals, Capability, type CybusLinkedService } from '../../../../../../domain'
import type { LinkedService } from '../../../../../Connectware'

import { BaseSubscriptionStrategy } from '../Base'

export class ServicesLinksStrategy extends BaseSubscriptionStrategy<'servicesLinks'> {
    readonly requiredPermissions = [Capability.SERVICES_READ]

    protected readonly areEquals = areLinkedCybusServiceEquals

    retrieveAll (): Promise<CybusLinkedService[]> {
        return this.retrieve({
            capability: Capability.SERVICES_READ,
            method: 'GET',
            path: '/api/services/all-links',
            mapper: (data: LinkedService[]) => data.filter((s) => isArrayNotEmpty(s.links)),
        })
    }
}
