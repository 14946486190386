import { ExtendedMap, isArrayNotEmpty, type NonEmptyArray, type NullableValues, type ReadonlyRecord } from '../../utils'

import { Capability, CybusPermissionContext, type CybusPersistedPermission } from '../../domain'
import type { OrchestratorResponse } from './Types'

export type AuthenticatedResource<Path extends CybusPersistedPermission['resource']> = Readonly<{
    /**
     * The path template of the url
     */
    path: Path

    /**
     * The associated capability of the request
     * Nullable if there shouldn't be one
     */
    capability: Capability | null
}>

/**
 * This represents a map of a permission requirement
 * For every Admin UI permission, a bunch of permission requirements need to be full-filled
 *
 * Some do not require an specific resource (so it is nullable)
 */
export type PermissionRequirement = Pick<CybusPersistedPermission, 'context' | 'read' | 'write'> & NullableValues<Pick<CybusPersistedPermission, 'resource'>>

type ExclusivePermissionRequirement<T> = PermissionRequirement & Readonly<{ context: T }>

const httpCapabilitiesAlternatives: ReadonlyRecord<Capability, ExclusivePermissionRequirement<CybusPermissionContext.HTTP>[]> = {
    [Capability.LEGACY_ADMIN_PERMISSION]: [],

    [Capability.AGENTS_MANAGE]: [],
    [Capability.AGENTS_READ]: [{ resource: '/api/system/agents', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.CONNECTION_READ]: [{ resource: '/api/connections/+', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.CONNECTIONS_MANAGE]: [{ resource: '/api/connections/+/operation', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.CONNECTIONS_READ]: [{ resource: '/api/connections', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.CORE_CONTAINER_READ]: [{ resource: '/api/core-containers/+/inspect', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.CORE_CONTAINERS_MANAGE]: [{ resource: '/api/core-containers/+/operation', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.CORE_CONTAINERS_READ]: [
        { resource: '/api/core-containers', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/core-containers/+/inspect', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/core-containers/orchestrator', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],

    [Capability.SERVICE_CONTAINER_READ]: [{ resource: '/api/containers/+/inspect', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.SERVICE_CONTAINERS_MANAGE]: [{ resource: '/api/containers/+/operation', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.SERVICE_CONTAINERS_READ]: [
        { resource: '/api/containers', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/containers/+/inspect', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/containers/orchestrator', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],

    [Capability.ENDPOINT_STATE_READ]: [{ resource: '/api/endpoints/+/state', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.ENDPOINT_READ]: [
        { resource: '/api/endpoints/+', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/endpoints/+/topics', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.ENDPOINTS_MANAGE]: [{ resource: '/api/endpoints/+/operation', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.ENDPOINTS_READ]: [{ resource: '/api/endpoints', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.MAPPING_STATE_READ]: [{ resource: '/api/mappings/+/state', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.MAPPING_READ]: [
        { resource: '/api/mappings/+', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/mappings/+/endpoint-topics', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.MAPPINGS_MANAGE]: [{ resource: '/api/mappings/+/operation', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.MAPPINGS_READ]: [{ resource: '/api/mappings', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.NODE_STATE_READ]: [{ resource: '/api/nodes/+/state', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.NODES_READ]: [{ resource: '/api/nodes', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.SERVER_READ]: [{ resource: '/api/servers/+', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.SERVERS_MANAGE]: [{ resource: '/api/servers/+/operation', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.SERVERS_READ]: [{ resource: '/api/servers', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.SERVICE_READ]: [
        { resource: '/api/services/+', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/services/+/dependencies', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.SERVICES_MANAGE]: [
        { resource: '/api/services/+/operation', context: CybusPermissionContext.HTTP, read: false, write: true },
        { resource: '/api/services/+', context: CybusPermissionContext.HTTP, read: false, write: true },
    ],
    [Capability.SERVICES_CREATE_OR_UPDATE]: [
        { resource: '/api/services/+', context: CybusPermissionContext.HTTP, read: false, write: true },
        { resource: '/api/services', context: CybusPermissionContext.HTTP, read: false, write: true },
    ],
    [Capability.SERVICE_TEMPLATE_EDIT]: [
        { resource: '/api/resources/schemas', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/validate/service', context: CybusPermissionContext.HTTP, read: false, write: true },
    ],
    [Capability.SERVICES_READ]: [{ resource: '/api/services/+', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.SERVICES_CATALOG_READ]: [
        { resource: '/api/marketplace/app/meta', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/marketplace/apps', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/marketplace/app', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.SERVICE_DEVIATIONS_READ]: [{ resource: '/api/services/+', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.VOLUME_READ]: [{ resource: '/api/volumes/+/inspect', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.VOLUMES_MANAGE]: [],
    [Capability.VOLUMES_READ]: [{ resource: '/api/volumes', context: CybusPermissionContext.HTTP, read: true, write: false }],

    [Capability.RETRIEVE_PASSWORD_POLICY]: [{ resource: '/api/policy/password', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.MANAGE_USERS]: [
        { resource: '/api/users/#', context: CybusPermissionContext.HTTP, read: true, write: true },
        { resource: '/api/auth/ldap', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/listUsers', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/auth/mfa', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.MANAGE_ROLES]: [
        { resource: '/api/roles/#', context: CybusPermissionContext.HTTP, read: true, write: true },
        { resource: '/api/roles', context: CybusPermissionContext.HTTP, read: false, write: true },
    ],
    [Capability.MANAGE_PERMISSIONS]: [{ resource: '/api/permissions/#', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.CLIENT_REGISTRY_MANAGE]: [
        { resource: '/api/client-registry/+', context: CybusPermissionContext.HTTP, read: true, write: true },
        { resource: '/api/client-registry', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.CERTIFICATES_MANAGE]: [
        { resource: '/api/certificates', context: CybusPermissionContext.HTTP, read: true, write: true },
        { resource: '/api/certificates/+', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],

    [Capability.TOPICS_SUBSCRIPTION]: [],
    [Capability.TOPICS_SUBSCRIPTION_METADATA]: [{ resource: '/api/topics', context: CybusPermissionContext.HTTP, read: true, write: false }],
    [Capability.WORKBENCH]: [],
    [Capability.RULE_ENGINE_SANDBOX_USE]: [
        { resource: '/api/rule-engine/+', context: CybusPermissionContext.HTTP, read: false, write: true },
        { resource: '/api/endpoints', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],

    [Capability.LOGS_READ]: [
        { resource: '/api/core-containers/orchestrator', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/core-containers', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/core-containers/+/logs', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/system/agents', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],

    [Capability.SYSTEM_LICENSE_MANAGE]: [
        { resource: '/api/system/refresh', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/system/licensefile', context: CybusPermissionContext.HTTP, read: false, write: true },
    ],
    [Capability.MANAGE_OWN_PASSWORD]: [{ resource: '/api/users/change-password', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.MINIMUM]: [
        { resource: '/api/permissions', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/system/preflight', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/system/info', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.SYSTEM_METRICS_MANAGE]: [{ resource: '/api/system/metrics', context: CybusPermissionContext.HTTP, read: false, write: true }],
    [Capability.SYSTEM_METRICS_READ]: [
        { resource: '/api/system/preflight', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/system/metrics', context: CybusPermissionContext.HTTP, read: true, write: false },
    ],
    [Capability.SYSTEM_BACKUP_MANAGE]: [
        { resource: '/api/maintenance/db', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/maintenance/db/+', context: CybusPermissionContext.HTTP, read: true, write: true },
    ],
    [Capability.USE_MFA]: [
        { resource: '/api/mfa/login', context: CybusPermissionContext.HTTP, read: false, write: true },
        { resource: '/api/mfa/isenrolled', context: CybusPermissionContext.HTTP, read: true, write: false },
        { resource: '/api/mfa/enable', context: CybusPermissionContext.HTTP, read: false, write: true },
        { resource: '/api/mfa/disable', context: CybusPermissionContext.HTTP, read: false, write: true },
        { resource: '/api/mfa/validate', context: CybusPermissionContext.HTTP, read: false, write: true },
        { resource: '/api/mfa/regenerate/backupcodes', context: CybusPermissionContext.HTTP, read: false, write: true },
    ],
}

const externalHttpCapabilitiesAlternatives: ReadonlyRecord<Capability, ExclusivePermissionRequirement<CybusPermissionContext.HTTP>[]> = {
    [Capability.LEGACY_ADMIN_PERMISSION]: [],

    [Capability.AGENTS_MANAGE]: [],
    [Capability.AGENTS_READ]: [],

    [Capability.CONNECTION_READ]: [],
    [Capability.CONNECTIONS_MANAGE]: [],
    [Capability.CONNECTIONS_READ]: [],

    [Capability.CORE_CONTAINER_READ]: [],
    [Capability.CORE_CONTAINERS_MANAGE]: [],
    [Capability.CORE_CONTAINERS_READ]: [],

    [Capability.SERVICE_CONTAINER_READ]: [],
    [Capability.SERVICE_CONTAINERS_MANAGE]: [],
    [Capability.SERVICE_CONTAINERS_READ]: [],

    [Capability.ENDPOINT_STATE_READ]: [],
    [Capability.ENDPOINT_READ]: [],
    [Capability.ENDPOINTS_MANAGE]: [],
    [Capability.ENDPOINTS_READ]: [],

    [Capability.MAPPING_STATE_READ]: [],
    [Capability.MAPPING_READ]: [],
    [Capability.MAPPINGS_MANAGE]: [],
    [Capability.MAPPINGS_READ]: [],

    [Capability.NODE_STATE_READ]: [],
    [Capability.NODES_READ]: [],

    [Capability.SERVER_READ]: [],
    [Capability.SERVERS_MANAGE]: [],
    [Capability.SERVERS_READ]: [],

    [Capability.SERVICE_READ]: [],
    [Capability.SERVICES_MANAGE]: [],
    [Capability.SERVICES_CREATE_OR_UPDATE]: [],
    [Capability.SERVICE_TEMPLATE_EDIT]: [],
    [Capability.SERVICES_READ]: [],
    [Capability.SERVICES_CATALOG_READ]: [],
    [Capability.SERVICE_DEVIATIONS_READ]: [],

    [Capability.VOLUME_READ]: [],
    [Capability.VOLUMES_MANAGE]: [],
    [Capability.VOLUMES_READ]: [],

    [Capability.RETRIEVE_PASSWORD_POLICY]: [],
    [Capability.MANAGE_USERS]: [],
    [Capability.MANAGE_ROLES]: [],
    [Capability.MANAGE_PERMISSIONS]: [],
    [Capability.CLIENT_REGISTRY_MANAGE]: [],
    [Capability.CERTIFICATES_MANAGE]: [],

    [Capability.TOPICS_SUBSCRIPTION]: [],
    [Capability.TOPICS_SUBSCRIPTION_METADATA]: [],
    [Capability.WORKBENCH]: [
        { resource: '/workbench', context: CybusPermissionContext.HTTP, read: true, write: true },
        { resource: '/workbench/#', context: CybusPermissionContext.HTTP, read: true, write: true },
    ],
    [Capability.RULE_ENGINE_SANDBOX_USE]: [],

    [Capability.LOGS_READ]: [],

    [Capability.SYSTEM_LICENSE_MANAGE]: [],
    [Capability.MINIMUM]: [],
    [Capability.SYSTEM_METRICS_MANAGE]: [],
    [Capability.SYSTEM_METRICS_READ]: [],
    [Capability.SYSTEM_BACKUP_MANAGE]: [],
    [Capability.USE_MFA]: [],

    [Capability.MANAGE_OWN_PASSWORD]: [],
}

const mixedHttpCapabilitiesAlternatives: ReadonlyRecord<Capability, ExclusivePermissionRequirement<CybusPermissionContext.HTTP>[]> = {
    [Capability.LEGACY_ADMIN_PERMISSION]: [],

    [Capability.AGENTS_MANAGE]: [],
    [Capability.AGENTS_READ]: [],

    [Capability.CONNECTION_READ]: [],
    [Capability.CONNECTIONS_MANAGE]: [],
    [Capability.CONNECTIONS_READ]: [],

    [Capability.CORE_CONTAINER_READ]: [],
    [Capability.CORE_CONTAINERS_MANAGE]: [],
    [Capability.CORE_CONTAINERS_READ]: [],

    [Capability.SERVICE_CONTAINER_READ]: [],
    [Capability.SERVICE_CONTAINERS_MANAGE]: [],
    [Capability.SERVICE_CONTAINERS_READ]: [],

    [Capability.ENDPOINT_STATE_READ]: [],
    [Capability.ENDPOINT_READ]: [],
    [Capability.ENDPOINTS_MANAGE]: [],
    [Capability.ENDPOINTS_READ]: [],

    [Capability.MAPPING_STATE_READ]: [],
    [Capability.MAPPING_READ]: [],
    [Capability.MAPPINGS_MANAGE]: [],
    [Capability.MAPPINGS_READ]: [],

    [Capability.NODE_STATE_READ]: [],
    [Capability.NODES_READ]: [],

    [Capability.SERVER_READ]: [],
    [Capability.SERVERS_MANAGE]: [],
    [Capability.SERVERS_READ]: [],

    [Capability.SERVICE_READ]: [],
    [Capability.SERVICES_MANAGE]: [],
    [Capability.SERVICES_CREATE_OR_UPDATE]: [],
    [Capability.SERVICE_TEMPLATE_EDIT]: [],
    [Capability.SERVICES_READ]: [],
    [Capability.SERVICES_CATALOG_READ]: [],
    [Capability.SERVICE_DEVIATIONS_READ]: [],

    [Capability.VOLUME_READ]: [],
    [Capability.VOLUMES_MANAGE]: [],
    [Capability.VOLUMES_READ]: [],

    [Capability.RETRIEVE_PASSWORD_POLICY]: [],
    [Capability.MANAGE_USERS]: [],
    [Capability.MANAGE_ROLES]: [],
    [Capability.MANAGE_PERMISSIONS]: [],
    [Capability.CLIENT_REGISTRY_MANAGE]: [],
    [Capability.CERTIFICATES_MANAGE]: [],

    [Capability.TOPICS_SUBSCRIPTION]: [],
    [Capability.TOPICS_SUBSCRIPTION_METADATA]: [],
    [Capability.WORKBENCH]: [],
    [Capability.RULE_ENGINE_SANDBOX_USE]: [],

    [Capability.LOGS_READ]: [],

    [Capability.SYSTEM_LICENSE_MANAGE]: [],
    [Capability.MINIMUM]: [],
    [Capability.SYSTEM_METRICS_MANAGE]: [],
    [Capability.SYSTEM_METRICS_READ]: [],
    [Capability.SYSTEM_BACKUP_MANAGE]: [],
    [Capability.USE_MFA]: [],

    [Capability.MANAGE_OWN_PASSWORD]: [],
}

const mixedMqttCapabilitiesAlternatives: ReadonlyRecord<Capability, ExclusivePermissionRequirement<CybusPermissionContext.MQTT>[]> = {
    [Capability.LEGACY_ADMIN_PERMISSION]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.AGENTS_MANAGE]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.AGENTS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.CONNECTION_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.CONNECTIONS_MANAGE]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.CONNECTIONS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.CORE_CONTAINER_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.CORE_CONTAINERS_MANAGE]: [],
    [Capability.CORE_CONTAINERS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.SERVICE_CONTAINER_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.SERVICE_CONTAINERS_MANAGE]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.SERVICE_CONTAINERS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.ENDPOINT_STATE_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.ENDPOINT_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.ENDPOINTS_MANAGE]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.ENDPOINTS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.MAPPING_STATE_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.MAPPING_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.MAPPINGS_MANAGE]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.MAPPINGS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.NODE_STATE_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.NODES_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.SERVER_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.SERVERS_MANAGE]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.SERVERS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.SERVICE_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.SERVICES_MANAGE]: [],
    [Capability.SERVICES_CREATE_OR_UPDATE]: [],
    [Capability.SERVICE_TEMPLATE_EDIT]: [],
    [Capability.SERVICES_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.SERVICES_CATALOG_READ]: [],
    [Capability.SERVICE_DEVIATIONS_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.VOLUME_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.VOLUMES_MANAGE]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],
    [Capability.VOLUMES_READ]: [{ resource: '#', context: CybusPermissionContext.MQTT, read: true, write: true }],

    [Capability.RETRIEVE_PASSWORD_POLICY]: [],
    [Capability.MANAGE_USERS]: [],
    [Capability.MANAGE_ROLES]: [],
    [Capability.MANAGE_PERMISSIONS]: [],
    [Capability.CLIENT_REGISTRY_MANAGE]: [],
    [Capability.CERTIFICATES_MANAGE]: [],

    [Capability.TOPICS_SUBSCRIPTION]: [{ resource: null, context: CybusPermissionContext.MQTT, read: true, write: false }],
    [Capability.TOPICS_SUBSCRIPTION_METADATA]: [],
    [Capability.WORKBENCH]: [],
    [Capability.RULE_ENGINE_SANDBOX_USE]: [],

    [Capability.LOGS_READ]: [],

    [Capability.SYSTEM_LICENSE_MANAGE]: [],
    [Capability.MINIMUM]: [],
    [Capability.SYSTEM_METRICS_MANAGE]: [],
    [Capability.SYSTEM_METRICS_READ]: [],
    [Capability.SYSTEM_BACKUP_MANAGE]: [],
    [Capability.USE_MFA]: [],

    [Capability.MANAGE_OWN_PASSWORD]: [],
}

const mixedCapabilitiesAlternatives = Object.values(Capability).reduce((r, capability) => {
    return { ...r, [capability]: [...mixedHttpCapabilitiesAlternatives[capability], ...mixedMqttCapabilitiesAlternatives[capability]] }
}, {} as ReadonlyRecord<Capability, ExclusivePermissionRequirement<CybusPermissionContext>[]>)

export const capabilities = Object.values(Capability).reduce(
    (r, capability) => ({
        ...r,
        /** Iterate through all alternatives and pick all that it can */
        [capability]: [httpCapabilitiesAlternatives, externalHttpCapabilitiesAlternatives, mixedCapabilitiesAlternatives].flatMap(
            ({ [capability]: alternative }) => (isArrayNotEmpty<ExclusivePermissionRequirement<CybusPermissionContext>>(alternative) ? [alternative] : [])
        ),
    }),
    {} as ReadonlyRecord<Capability, NonEmptyArray<NonEmptyArray<PermissionRequirement>>>
)

/**
 * Having Kubernetes or Docker in the backend result in different Connectware capabililities
 * This are the permissions that are missing on each
 */
export const unavailableByContainerImplementation = new ExtendedMap<OrchestratorResponse['orchestrator'] | null, Capability[]>([
    [
        'Kubernetes',
        [
            /** No cabiltiy to interact with volumes */
            Capability.VOLUMES_MANAGE,
            Capability.VOLUMES_READ,
            Capability.VOLUME_READ,
            /** No capability to interact with service containers */
            Capability.SERVICE_CONTAINERS_MANAGE,
            Capability.SERVICE_CONTAINERS_READ,
            Capability.SERVICE_CONTAINER_READ,
            /** No capability to manage core containers */
            Capability.CORE_CONTAINERS_MANAGE,
        ],
    ],
    ['Docker', []],
])

/**
 * If mfa is disable (false) then remove the following values
 */
export const unavailableMfaConfiguration = new ExtendedMap([
    [false, [Capability.USE_MFA]],
    [true, []],
])
