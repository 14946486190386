import type { NonEmptyArray, ReadonlyRecord } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, type CybusLog, CybusLogLevel } from '../../../domain'

type LogCollectors<T> = {
    [P in keyof T]: (args: Readonly<{ parsed: ReadonlyRecord<string, unknown>, rawTimestamp: string, rawMessage: string }>) => T[P]
}

class IgnoreSignal extends ConnectwareError<ConnectwareErrorType.UNEXPECTED> {
    constructor () {
        super(ConnectwareErrorType.UNEXPECTED)
    }
}

export class LogsProcessor {
    private static readonly logLevelMap: ReadonlyRecord<string | number, CybusLogLevel> = {
        fatal: CybusLogLevel.FATAL,
        60: CybusLogLevel.FATAL,

        error: CybusLogLevel.ERROR,
        50: CybusLogLevel.ERROR,

        warn: CybusLogLevel.WARN,
        40: CybusLogLevel.WARN,

        info: CybusLogLevel.INFO,
        30: CybusLogLevel.INFO,

        debug: CybusLogLevel.DEBUG,
        20: CybusLogLevel.DEBUG,

        trace: CybusLogLevel.TRACE,
        10: CybusLogLevel.TRACE,
    }

    private readonly search: string | null

    private readonly levels: Set<CybusLogLevel> | null

    private readonly collectors: LogCollectors<CybusLog> = {
        timestamp: ({ parsed: { time }, rawTimestamp }) => {
            const timestamp = LogsProcessor.parseTimestamp(rawTimestamp) || LogsProcessor.parseTimestamp(time) || null

            if (!timestamp) {
                throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, 'Could not map log', { rawTimestamp, time })
            }

            if (this.end && this.end < timestamp) {
                throw new IgnoreSignal()
            }

            return timestamp
        },
        level: ({ parsed: { level } }) => {
            const mappedLevel = ((typeof level === 'string' || typeof level === 'number') && LogsProcessor.logLevelMap[level]) || CybusLogLevel.INFO
            if (this.levels && !this.levels.has(mappedLevel)) {
                throw new IgnoreSignal()
            }
            return mappedLevel
        },
        message: ({ parsed: { msg }, rawMessage }) => {
            const message = typeof msg === 'string' ? msg : rawMessage

            if (this.search !== null && !message.toLowerCase().includes(this.search)) {
                throw new IgnoreSignal()
            }

            return message
        },
        className: ({ parsed: { className } }) => (typeof className === 'string' ? className : null),
        pid: ({ parsed: { pid } }) => (typeof pid === 'number' ? pid : null),
        hostname: ({ parsed: { hostname } }) => (typeof hostname === 'string' ? hostname : null),
        serviceId: ({ parsed: { id } }) => (typeof id === 'string' ? id : null),
    }

    private readonly collectionsNames: (keyof typeof this.collectors)[]

    private static parseTimestamp (source: unknown): Date | null {
        const date = typeof source === 'string' || typeof source === 'number' ? new Date(source) : null
        return date && !Number.isNaN(date.getTime()) ? date : null
    }

    constructor (search: string | null, levels: NonEmptyArray<CybusLogLevel> | null, private readonly end: Date | null) {
        this.search = search?.trim().toLowerCase() || null
        this.levels = levels && new Set(levels)
        this.collectionsNames = Object.keys(this.collectors) as typeof this.collectionsNames
    }

    private parse (line: string): [rawTimestamp: string, parsed: ReadonlyRecord<string, unknown>] {
        const firstSpaceIndex = line.indexOf(' ')

        /** Position of the timestamp */
        const rawTimestamp = line.substring(0, firstSpaceIndex)

        /** Position of the actual data */
        const rawData = line.substring(firstSpaceIndex + 1)

        let parsed: ReadonlyRecord<string, unknown>
        try {
            parsed = JSON.parse(rawData) as typeof parsed
        } catch (e) {
            parsed = { msg: rawData }
        }

        return [rawTimestamp, parsed]
    }

    process (text: string): CybusLog[] {
        return text.split('\n').reduce<CybusLog[]>((logs, rawMessage) => {
            if (!rawMessage) {
                return logs
            }

            const [rawTimestamp, parsed] = this.parse(rawMessage)

            try {
                const data = { parsed, rawTimestamp, rawMessage }
                logs.push(this.collectionsNames.reduce((log, field) => ({ ...log, [field]: this.collectors[field](data) }), {} as CybusLog))
            } catch (e) {
                if (e instanceof IgnoreSignal) {
                    return logs
                }
                throw e
            }

            return logs
        }, [])
    }
}
