import type { Intersection } from 'utility-types'

import type { CybusConnection, CybusConnectionConfiguration } from '../../../domain'
import { useTranslator } from '../Internationalization'

type Connection = Pick<
    Intersection<CybusConnection, CybusConnectionConfiguration>,
    'ipAddress' | 'agent' | 'localInterface' | 'brokers' | 'host' | 'cncType' | 'device' | 'port'
>

export const useConnectionHostTranslator = (): ((connection: Connection) => string | null) => {
    const translator = useTranslator()
    return (c) => c.ipAddress || c.agent || c.localInterface || (c.brokers && translator.formatList(c.brokers)) || c.host
}

export const useConnectionPortTranslator = (): ((connection: Connection) => string | null) => {
    return (c) => c.cncType || c.device || c.port
}
