import type { Intersection } from 'utility-types'

import { areObjectsDeepEqual } from '../../../utils'
import {
    arePermissionArrayEquals,
    areRoleArraysEquals,
    ConnectwareError,
    ConnectwareErrorType,
    CybusPermissionContext,
    type CybusRole,
    type CybusUser,
    CybusUserAuthenticationMethod,
    type EditableCybusPermissionInheritance,
    selectUsersManagementUserCreation,
    selectUsersManagementUserUpdating,
    Translation,
    type UserCreationForm,
    type UserEditingForm,
    type UserForm,
} from '../../../domain'
import { FormWithTemplatesUsecase } from './Template'
import { LoadingUsecase } from '../Loading'

export abstract class UserFormUsecase<Form extends UserForm> extends FormWithTemplatesUsecase<Form> {
    protected static derivedRolesValues: Pick<UserForm, 'roleInput' | 'allRoles' | 'rolesPermissions' | 'mqttPublishPrefixValidation'> = {
        roleInput: null,
        allRoles: null,
        rolesPermissions: [],
        mqttPublishPrefixValidation: null,
    }

    private async updateMQTTPublishPrefixValidation (): Promise<void> {
        await this.updateFormWithDelay(
            (a, b) =>
                a.mqttPublishPrefix === b.mqttPublishPrefix &&
                a.username === b.username &&
                arePermissionArrayEquals(a.permissions, b.permissions) &&
                areRoleArraysEquals(a.roles, b.roles),
            ({ mqttPublishPrefix, permissions, rolesPermissions, username }) => {
                if (mqttPublishPrefix && !this.topicsService.isExact(this.topicsService.parseTopic(mqttPublishPrefix))) {
                    /** Handling scenario with non exact prefix */
                    return {
                        mqttPublishPrefixValidation: new ConnectwareError(
                            ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
                            this.translationService.translate(Translation.MQTT_PUBLISH_PREFIX_VALIDATION, {
                                type: 'NOT_EXACT',
                            })
                        ),
                    } as Partial<Form>
                }

                if (
                    mqttPublishPrefix &&
                    !this.topicsService.isPrefix(
                        mqttPublishPrefix,
                        [...permissions, ...rolesPermissions].flatMap((permission) =>
                            permission.context === CybusPermissionContext.MQTT && permission.write ? [permission.resource] : []
                        )
                    )
                ) {
                    /** Handling scenario with prefix without permissions */
                    return {
                        mqttPublishPrefixValidation: new ConnectwareError(
                            ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION,
                            this.translationService.translate(Translation.MQTT_PUBLISH_PREFIX_VALIDATION, {
                                username,
                                prefix: mqttPublishPrefix,
                                type: 'NO_PERMISSIONS',
                            })
                        ),
                    } as Partial<Form>
                }

                /** Handling scenario with no prefix */
                return { mqttPublishPrefixValidation: null } as Partial<Form>
            }
        )
    }

    private updateRolePermissions (): void {
        const { roles } = this.getCurrentForm()
        const rolesPermissions = roles.flatMap<EditableCybusPermissionInheritance>((r) => r.permissions.map((p) => ({ ...p, role: r.name })))
        this.setPartialForm({ rolesPermissions } as Partial<Form>)
    }

    private async updateAllRoles (): Promise<void> {
        await this.updateFormWithDelay(
            (a, b) => a.roleInput === b.roleInput,
            async ({ roleInput }) => {
                if (roleInput === null) {
                    return { allRoles: null } as Partial<Form>
                }

                try {
                    const foundRoles = await this.userService.fetchRoles(roleInput)
                    const { roles } = this.getCurrentForm()
                    return { allRoles: [...foundRoles, ...roles.filter((r) => !foundRoles.some((f) => f.id === r.id))] } as Partial<Form>
                } catch (e: unknown) {
                    return { allRoles: e as ConnectwareError } as Partial<Form>
                }
            }
        )
    }

    protected initializeForm (form: Form): void {
        super.initializeForm(form)

        this.updateRolePermissions()
        void this.updateAllRoles()
        void this.updateMQTTPublishPrefixValidation()
    }

    protected setPartialForm (form: Partial<Form>): void {
        super.setPartialForm(form)

        if ('mqttPublishPrefix' in form || 'permissions' in form || 'rolesPermissions' in form || 'username' in form) {
            void this.updateMQTTPublishPrefixValidation()
        }

        if ('roles' in form) {
            void this.updateRolePermissions()
        }

        if ('roleInput' in form) {
            void this.updateAllRoles()
        }
    }

    searchRoles (roleInput: Form['roleInput']): void {
        this.setPartialForm({ roleInput } as Partial<Form>)
    }

    selectRoles (roles: CybusRole[]): void {
        this.setPartialForm({ roles, roleInput: null, allRoles: null } as Partial<Form>)
    }
}

abstract class CredentializedUserFormUsecase<Form extends Intersection<UserEditingForm, UserCreationForm>> extends UserFormUsecase<Form> {
    protected static derivedUserValues: Pick<Intersection<UserEditingForm, UserCreationForm>, 'passwordValidation' | 'usernameValidation'> = {
        passwordValidation: null,
        usernameValidation: null,
    }

    private createConfirmationValidationMessage ({
        password,
        passwordConfirmation,
    }: Pick<Form, 'password' | 'passwordConfirmation'>): string | ConnectwareError | null {
        if (passwordConfirmation === null) {
            return null
        }

        const matchMessage = this.translationService.translate(Translation.PASSWORD_VALIDATION, { type: 'match' })
        return password === passwordConfirmation ? matchMessage : new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, matchMessage)
    }

    private async validatePassword ({
        password,
        passwordConfirmation,
    }: Pick<Form, 'password' | 'passwordConfirmation'>): Promise<(ConnectwareError | string)[]> {
        if (password === null) {
            // No password is being set, so just don't do anything
            return []
        }

        return [this.createConfirmationValidationMessage({ password, passwordConfirmation }), ...(await this.userService.validatePassword(password))].filter(
            (v): v is ConnectwareError | string => Boolean(v)
        )
    }

    private async updatePasswordValidation (): Promise<void> {
        await this.updateFormWithDelay(
            (a, b) => a.password === b.password && a.passwordConfirmation === b.passwordConfirmation,
            (form) =>
                this.validatePassword(form)
                    .catch((e: ConnectwareError) => e)
                    .then((passwordValidation) => ({ passwordValidation } as Partial<Form>))
        )
    }

    protected async updateUsernameValidation (): Promise<void> {
        await this.updateFormWithDelay(
            (a, b) => a.username === b.username,
            ({ username }) => this.userService.validateUsername(username).then((usernameValidation) => ({ usernameValidation } as Partial<Form>))
        )
    }

    protected initializeForm (form: Form): void {
        super.initializeForm(form)

        void this.updatePasswordValidation()
        void this.updateUsernameValidation()
    }

    protected setPartialForm (form: Partial<Form>): void {
        super.setPartialForm(form)

        if ('password' in form || 'passwordConfirmation' in form) {
            void this.updatePasswordValidation()
        }

        if ('username' in form) {
            void this.updateUsernameValidation()
        }
    }

    togglePasswordMode (): void {
        const { passwordConfirmation } = this.getCurrentForm()
        this.setPartialForm({ passwordConfirmation: passwordConfirmation === null ? '' : null } as Partial<Form>)
    }
}

export class EditUserUsecase extends CredentializedUserFormUsecase<UserEditingForm> {
    protected readonly selected = 'users'

    protected readonly formName = 'userUpdating'

    protected readonly selectForm = selectUsersManagementUserUpdating

    private static getModifiedFields<T> (origin: T, compare: T): Partial<T> {
        const diff: Partial<T> = {}

        for (const key in compare) {
            if (!areObjectsDeepEqual(origin[key], compare[key])) {
                diff[key] = compare[key]
            }
        }

        return diff
    }

    protected async request (): Promise<void> {
        const initial = this.getInitialForm()
        const current = this.getCurrentForm()
        // only returns changed fields
        const { username, permissions, roles, authenticationMethods, mqttPublishPrefix, password, isMfaEnabled, isMfaEnforced } =
            EditUserUsecase.getModifiedFields<UserEditingForm>(initial, current)
        await this.userService.updateUser({
            id: current.id,
            username,
            permissions,
            roles,
            authenticationMethods,
            mqttPublishPrefix,
            password,
            isMfaEnabled,
            isMfaEnforced,
        })
    }

    protected async updateUsernameValidation (): Promise<void> {
        const { isProtected } = this.getCurrentForm()
        if (!isProtected) {
            await super.updateUsernameValidation()
        }
    }

    async delete (id: CybusUser['id']): Promise<void> {
        await this.userService.deleteUser(id)
        await this.reload()
    }

    async load (userOrUsername: CybusUser['username'] | CybusUser): Promise<void> {
        const userPromise = typeof userOrUsername === 'string' ? this.userService.fetchUser(userOrUsername) : Promise.resolve(userOrUsername)
        const loadindUsecase = this.getUsecase(LoadingUsecase)
        await loadindUsecase.withLoading(
            userPromise
                .then((user) => Promise.all([user, Promise.all(user.roles.map((roleName) => this.userService.fetchRole(roleName)))]))
                .then(([user, roles]) =>
                    this.initializeForm({
                        username: user.username,
                        isMfaEnabled: user.isMfaEnabled,
                        mqttPublishPrefix: user.mqttPublishPrefix,
                        id: user.id,
                        isProtected: user.isProtected,
                        password: null,
                        passwordConfirmation: null,
                        roles,
                        permissions: user.permissions,
                        authenticationMethods: user.authenticationMethods,
                        editMode: user.editMode,
                        isMfaEnforced: user.isMfaEnforced,

                        ...EditUserUsecase.derivedRolesValues,
                        ...EditUserUsecase.derivedUserValues,
                        ...EditUserUsecase.derivedTemplateValues,
                    })
                )
        )
    }
}

export class CreateUserUsecase extends CredentializedUserFormUsecase<UserCreationForm> {
    protected readonly selected = 'users'

    protected readonly formName = 'userCreation'

    protected readonly selectForm = selectUsersManagementUserCreation

    protected async request (): Promise<void> {
        const { username, mqttPublishPrefix, authenticationMethods, roles, permissions, password, isMfaEnforced } = this.getCurrentForm()
        await this.userService.createUser({
            username,
            mqttPublishPrefix,
            authenticationMethods,
            roles,
            permissions,
            password,
            isMfaEnforced,
        })
    }

    start (): void {
        this.initializeForm({
            username: '',
            mqttPublishPrefix: null,
            password: '',
            passwordConfirmation: '',
            roles: [],
            permissions: [],
            authenticationMethods: [CybusUserAuthenticationMethod.PASSWORD, CybusUserAuthenticationMethod.TOKEN],
            isMfaEnforced: false,

            ...CreateUserUsecase.derivedUserValues,
            ...CreateUserUsecase.derivedRolesValues,
            ...CreateUserUsecase.derivedTemplateValues,
        })
    }
}
