import type { Mutable } from 'utility-types'

import { areArrayEquals, ChangeEventListener, Droppable, entries, isObjectEmpty, type ReadonlyRecord } from '../../../utils'
import {
    type AppState,
    ConnectwareError,
    ConnectwareErrorType,
    type CybusPubSub,
    isTopicSubscriptionResponseSuccessful,
    selectLoadedMappingEntries,
    selectLoadedMappingEntriesTopics,
    selectMapping,
    selectMappingEntries,
} from '../../../domain'

import type { TopicsSubscription } from '../..'
import { Usecase } from '../Usecase'
import { TopicSubscriptionErrorMapper } from './SubscriptionErrorMapper'

type EntriesToTopics = ReadonlyRecord<CybusPubSub['id'], CybusPubSub['topics']>

export class SubscribeToMappingEntriesUsecase extends Usecase {
    private setMappingEntries (mappingEntries: AppState['mappingEntries']): void {
        this.setState({ mappingEntries })
    }

    private listenToEntriesStateChanges (subscription: TopicsSubscription, idTopicsMap: ChangeEventListener<EntriesToTopics>): VoidFunction {
        const droppable = new Droppable()

        /**
         * Even as the entries get updated
         * This function is responsible to keep everything up to date
         * In one move it maps all relevant topics and entries responsible for such topics
         * So the state can be reliably updated later on
         */
        const refreshEntitiesDerivedValues: VoidFunction = () => {
            const [allTopics, idToTopics] = selectLoadedMappingEntriesTopics(this.getState())

            const promise = subscription.subscribe(allTopics)

            void promise.then((response) => {
                const mapping = selectMapping(this.getState())

                if (isTopicSubscriptionResponseSuccessful(response) || ConnectwareError.is(mapping) || !mapping) {
                    /**
                     * Either there are no errors, or the mapping entries can't really be edited
                     */
                    return
                }

                const mapWithErrors = new TopicSubscriptionErrorMapper(response).remapWithErrors(mapping)

                if (mapWithErrors) {
                    this.setState({ mapping: mapWithErrors })
                }
            })

            idTopicsMap.change(idToTopics)
        }

        /** Update subscription with initial value */
        refreshEntitiesDerivedValues()

        /** Detect further changes to perform further updates */
        droppable.onDrop(
            this.subscribeToState(
                (previous, current) => areArrayEquals(selectLoadedMappingEntries(previous), selectLoadedMappingEntries(current)),
                refreshEntitiesDerivedValues
            )
        )

        return () => droppable.drop()
    }

    private listenToTopicMessages (subscription: TopicsSubscription, entriesMap: ChangeEventListener<EntriesToTopics>): VoidFunction {
        const droppable = new Droppable()

        /** Wait for messages and errors to arrive */
        droppable.onDrop(
            subscription.onBatch((batch) => {
                const mappingEntries = selectMappingEntries(this.getState())

                if (mappingEntries === null || ConnectwareError.is(mappingEntries)) {
                    /** Should not be updated as something else is wrong */
                    this.setMappingEntries(new ConnectwareError(ConnectwareErrorType.STATE, 'Could not update mapping entires as it is in unexpected state'))
                    return
                }

                const newEntries: Mutable<typeof mappingEntries> = {}

                /**
                 * Iterate every entry
                 * And attempt to find from the published source
                 * What the entry it comes from
                 */
                entries(entriesMap.value).forEach(([id, topics]) =>
                    batch.forEach((m) => {
                        if (this.topicsService.isSubscribeable(m.topic, topics)) {
                            newEntries[id] = m
                        }
                    })
                )

                if (!isObjectEmpty(newEntries)) {
                    /** Merge values */
                    this.setMappingEntries({ ...mappingEntries, ...newEntries })
                }
            })
        )

        droppable.onDrop(
            /** Error implies that the client will be closed */
            subscription.onError((error) => this.setMappingEntries(error))
        )

        return () => droppable.drop()
    }

    /**
     * This function subscribes to the topics on the mapping.entries on the application state
     * And writes to the mappingEntries property what is listened from said subscribed topics
     */
    subscribe (): VoidFunction {
        const droppable = new Droppable()

        /** Initialize state */
        this.setMappingEntries({})

        /** Reset state of the application is done subscribing */
        droppable.onDrop(() => this.setMappingEntries(null))

        const idTopicsMap = new ChangeEventListener<EntriesToTopics>({})
        const subscription = this.topicsService.create()

        /** Drop subscription once everything is gone */
        droppable.onDrop(() => subscription.end())

        /** If the mapping entries change, these will keep the auxiliar and subscription variables up to date */
        droppable.onDrop(this.listenToEntriesStateChanges(subscription, idTopicsMap))

        /** Listen to the inbound topic messages */
        droppable.onDrop(this.listenToTopicMessages(subscription, idTopicsMap))

        return () => droppable.drop()
    }
}
