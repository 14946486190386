import { ConnectwareError, ConnectwareErrorType, type CybusDetailedCoreContainer, type CybusDetailedServiceContainer } from '../../../../../../../domain'

import { mapDetailedCoreContainer, mapDetailedServiceContainer, mapToStatusType } from '../../../../../../Connectware'

import type { ContainerManagerOrchestratorProxy } from '../../../../../proxies'
import type { VrcpEntityHandler, VrpcInstanceDomainMapperArgs, VrpcInstanceMapper } from '../..'
import { VrpcContainerManagerOrchestratorProxyBaseMapper } from '../Base'
import { type ContainerInspectionResponse, coreConfiguration, serviceConfiguration } from './Base'

const mapValidContainer = <C>(
    id: string,
    container: ContainerInspectionResponse,
    mapper: (id: string, container: Exclude<ContainerInspectionResponse, void>) => C
): C => {
    if (!container) {
        throw new ConnectwareError(ConnectwareErrorType.NOT_FOUND, 'Container not found', { id })
    }

    return mapper(id, container)
}

class VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerMapper
    extends VrpcContainerManagerOrchestratorProxyBaseMapper<CybusDetailedServiceContainer>
    implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, CybusDetailedServiceContainer> {
    async mapToDomain ({ instance, instanceName }: VrpcInstanceDomainMapperArgs<ContainerManagerOrchestratorProxy>): Promise<CybusDetailedServiceContainer> {
        return instance.inspectContainer(instanceName).then((container) =>
            mapValidContainer(instanceName, container, (i, c) => {
                const status = mapToStatusType(c.State.Status?.toLowerCase())
                return mapDetailedServiceContainer(i, c, status)
            })
        )
    }
}

class VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerMapper
    extends VrpcContainerManagerOrchestratorProxyBaseMapper<CybusDetailedCoreContainer>
    implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, CybusDetailedCoreContainer> {
    async mapToDomain ({ instance, instanceName }: VrpcInstanceDomainMapperArgs<ContainerManagerOrchestratorProxy>): Promise<CybusDetailedCoreContainer> {
        return instance.inspectContainer(instanceName).then((container) =>
            mapValidContainer(instanceName, container, (i, c) => {
                const status = mapToStatusType(c.State.Status?.toLowerCase())
                return mapDetailedCoreContainer(i, i, c, status)
            })
        )
    }
}

export class VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerHandler
    implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusDetailedServiceContainer> {
    readonly configuration = serviceConfiguration

    readonly remoteMapper = null

    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToDetailedServiceContainerMapper()
}

export class VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerHandler
    implements VrcpEntityHandler<ContainerManagerOrchestratorProxy, CybusDetailedCoreContainer> {
    readonly configuration = coreConfiguration

    readonly remoteMapper = null

    readonly instanceMapper = new VrpcContainerManagerOrchestratorProxyToDetailedCoreContainerMapper()
}
