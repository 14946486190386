import { entries, type NonEmptyArray, type ReadonlyRecord } from '../../../../utils'
import {
    type BooleanArrayCommissioningFileField,
    type BooleanCommissioningFileField,
    type CommissioningFileField,
    CommissioningFileFieldType,
    ConnectwareError,
    ConnectwareErrorType,
    type IntegerArrayCommissioningFileField,
    type IntegerCommissioningFileField,
    type NumberArrayCommissioningFileField,
    type NumberCommissioningFileField,
    type StringArrayCommissioningFileField,
    type StringCommissioningFileField,
    type StringIndexCommissioningFileField,
} from '../../../../domain'
import {
    type ExpectedBooleanArrayField,
    type ExpectedBooleanField,
    type ExpectedIntegerArrayField,
    type ExpectedIntegerField,
    type ExpectedNumberArrayField,
    type ExpectedNumberField,
    type ExpectedStringArrayField,
    type ExpectedStringField,
    type ExpectedStringIndexField,
    isExpectedBooleanArrayField,
    isExpectedBooleanField,
    isExpectedIntegerArrayField,
    isExpectedIntegerField,
    isExpectedNumberArrayField,
    isExpectedNumberField,
    isExpectedStringArrayField,
    isExpectedStringField,
    isExpectedStringIndexField,
    isMinimalObjectSchema,
    type MinimalObjectSchema,
} from '../types'

type AllExpectedFields =
    | ExpectedBooleanArrayField
    | ExpectedBooleanField
    | ExpectedIntegerArrayField
    | ExpectedIntegerField
    | ExpectedNumberArrayField
    | ExpectedNumberField
    | ExpectedStringArrayField
    | ExpectedStringField
    | ExpectedStringIndexField

type CommonProps = keyof CommissioningFileField

class FieldMapper<Validated extends AllExpectedFields, Mapped extends CommissioningFileField> {
    constructor (
        private readonly type: Mapped['type'],
        private readonly isOfType: (obj: unknown) => obj is Validated,
        private readonly fromValidated: (validated: Validated) => Omit<Mapped, CommonProps>
    ) {}

    map (base: Pick<Mapped, 'optional'>, raw: unknown): Mapped | null {
        if (!this.isOfType(raw)) {
            return null
        }

        const common: Omit<Pick<Mapped, CommonProps>, keyof typeof base> = {
            type: this.type,
            description: raw.description ?? raw.title ?? null,
            default: raw.default ?? null,
            allowableValues: raw.const !== undefined ? ([raw.const] as Mapped['allowableValues']) : raw.enum ?? null,
        }

        const variation: Omit<Mapped, CommonProps> = this.fromValidated(raw)

        return { ...base, ...common, ...variation } as Mapped
    }
}

type ExpectedNumberFields = ExpectedIntegerField | ExpectedNumberField
type NumberCommissioningFileFields = IntegerCommissioningFileField | NumberCommissioningFileField

const minMaxResolver = (props: Pick<ExpectedNumberFields, 'minimum' | 'maximum'>): Pick<NumberCommissioningFileFields, 'max' | 'min'> => ({
    min: props.minimum ?? null,
    max: props.maximum ?? null,
})

type ExpectedArrayFields = ExpectedStringArrayField | ExpectedNumberArrayField | ExpectedIntegerArrayField | ExpectedBooleanArrayField
type ArrayCommissioningFileFields =
    | StringArrayCommissioningFileField
    | NumberArrayCommissioningFileField
    | IntegerArrayCommissioningFileField
    | BooleanArrayCommissioningFileField

const uniqueItemsResolver = ({ uniqueItems = false }: Pick<ExpectedArrayFields, 'uniqueItems'>): Pick<ArrayCommissioningFileFields, 'uniqueItems'> => ({
    uniqueItems,
})

const fieldMappers = [
    new FieldMapper<ExpectedStringField, StringCommissioningFileField>(
        CommissioningFileFieldType.STRING,
        isExpectedStringField,
        ({ minLength = null, maxLength = null, pattern = null }) => ({ minLength, maxLength, pattern })
    ),
    new FieldMapper<ExpectedStringIndexField, StringIndexCommissioningFileField>(
        CommissioningFileFieldType.STRING_INDEX,
        isExpectedStringIndexField,
        ({ $id, propertyNames: { pattern } }) => ({ name: $id, pattern })
    ),
    new FieldMapper<ExpectedIntegerField, IntegerCommissioningFileField>(CommissioningFileFieldType.INTEGER, isExpectedIntegerField, minMaxResolver),
    new FieldMapper<ExpectedNumberField, NumberCommissioningFileField>(CommissioningFileFieldType.NUMBER, isExpectedNumberField, minMaxResolver),
    new FieldMapper<ExpectedBooleanField, BooleanCommissioningFileField>(CommissioningFileFieldType.BOOLEAN, isExpectedBooleanField, () => ({})),
    new FieldMapper<ExpectedStringArrayField, StringArrayCommissioningFileField>(
        CommissioningFileFieldType.STRING_ARRAY,
        isExpectedStringArrayField,
        uniqueItemsResolver
    ),
    new FieldMapper<ExpectedNumberArrayField, NumberArrayCommissioningFileField>(
        CommissioningFileFieldType.NUMBER_ARRAY,
        isExpectedNumberArrayField,
        uniqueItemsResolver
    ),
    new FieldMapper<ExpectedIntegerArrayField, IntegerArrayCommissioningFileField>(
        CommissioningFileFieldType.INTEGER_ARRAY,
        isExpectedIntegerArrayField,
        uniqueItemsResolver
    ),
    new FieldMapper<ExpectedBooleanArrayField, BooleanArrayCommissioningFileField>(
        CommissioningFileFieldType.BOOLEAN_ARRAY,
        isExpectedBooleanArrayField,
        uniqueItemsResolver
    ),
]

type ObjectSchemaMapperPredicateArgs = Readonly<{ context: NonEmptyArray<string>, property: unknown, optional: boolean }>
export type ObjectSchemaMapperConfig = Readonly<{
    prepareProperty: (args: ObjectSchemaMapperPredicateArgs) => unknown
    ignoreProperty: (args: ObjectSchemaMapperPredicateArgs) => boolean
    recursive: boolean
}>

export class ObjectSchemaMapper {
    /**
     * Aux variable to whoever is using this mapper to make decisions
     */
    private readonly context: string[] = []

    private readonly config: ObjectSchemaMapperConfig

    private static readonly defaultConfig: ObjectSchemaMapperConfig = {
        ignoreProperty: () => false,
        prepareProperty: ({ property }) => property,
        recursive: false,
    }

    constructor (config?: Partial<ObjectSchemaMapperConfig>) {
        this.config = { ...ObjectSchemaMapper.defaultConfig, ...config }
    }

    private withAppendedContext<T> (context: string, executer: (context: NonEmptyArray<string>) => T): T {
        this.context.push(context)

        try {
            return executer(this.context as NonEmptyArray<string>)
        } finally {
            this.context.pop()
        }
    }

    private runWithNewContext<V, T> (schema: unknown, isSchemaValid: (v: unknown) => v is V, callback: (value: V) => T): T {
        if (!isSchemaValid(schema)) {
            this.throwError('Schema is invalid', { schema })
        }

        return callback(schema)
    }

    private throwError (message: string, extras?: ReadonlyRecord<string, unknown>): never {
        throw new ConnectwareError(ConnectwareErrorType.MAPPING_ERROR, message, { ...extras, context: this.context })
    }

    private mapCommissioningFileFieldRecord ({ properties = {}, required = [] }: MinimalObjectSchema): ReadonlyRecord<string, CommissioningFileField> {
        return entries(properties).reduce(
            (fields, [name, property]) =>
                this.withAppendedContext(name, (context) => {
                    const args = { context, property, optional: !required.includes(name) }

                    if (this.config.ignoreProperty(args)) {
                        return fields
                    }

                    if (this.config.recursive && isMinimalObjectSchema(property)) {
                        /** Recursion is on and is valid object to be mapped recursively */
                        return { ...fields, ...this.mapCommissioningFileFieldRecord(property) }
                    }

                    /** In case some modification needs to be added */
                    property = this.config.prepareProperty(args)

                    const base = { optional: args.optional }
                    const field = fieldMappers.reduce<CommissioningFileField | null>((r, mapper) => r ?? mapper.map(base, property), null)

                    if (field === null) {
                        this.throwError('Could not map a sub property', { name, property })
                    }

                    return { ...fields, [context.join('.')]: field }
                }),
            {}
        )
    }

    private mapFields<T extends CommissioningFileFieldType[]> (
        schema: MinimalObjectSchema,
        expectedTypes: T
    ): ReadonlyRecord<string, Extract<CommissioningFileField, { type: T }>> {
        return entries(this.mapCommissioningFileFieldRecord(schema)).reduce((r, [name, field]) => {
            if (!expectedTypes.includes(field.type)) {
                this.throwError('Found an unexpected field type', { name, field, expectedTypes })
            }

            return { ...r, [name]: field }
        }, {})
    }

    mapTypedFields<T extends CommissioningFileFieldType> (
        schema: unknown,
        expectedTypes: T[]
    ): ReadonlyRecord<string, Extract<CommissioningFileField, { type: T }>> {
        return this.runWithNewContext(schema, isMinimalObjectSchema, (s) => this.mapFields(s, expectedTypes))
    }
}
