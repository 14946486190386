import { areArrayEquals, entries, type ReadonlyRecord } from '../../../utils'
import { type CybusDetailedMapping, type CybusPubSub, type TopicSubscriptionError, type TopicSubscriptionResponse } from '../../../domain'

/**
 * This is a utility class help to map subscription errors
 * And spit out updated mappers
 */
export class TopicSubscriptionErrorMapper {
    /** Aux variable to detect changes on remapping */
    private hasChanges: boolean = false

    private readonly errorsByTopic: ReadonlyRecord<string, TopicSubscriptionError>

    constructor (response: TopicSubscriptionResponse) {
        this.errorsByTopic = entries(response).reduce<ReadonlyRecord<string, TopicSubscriptionError>>(
            (r, [responseType, topics]) => (responseType === 'subscribed' ? r : topics.reduce((r, topic) => ({ ...r, [topic]: responseType }), r)),
            {}
        )
    }

    private remapSubscriptionError (topics: string[], original: ReadonlySet<TopicSubscriptionError>): Set<TopicSubscriptionError> {
        const newErrors = topics.reduce((errors, topic) => {
            const error = this.errorsByTopic[topic]

            if (error) {
                errors.add(error)
            }

            return errors
        }, new Set<TopicSubscriptionError>())

        /** Update change tracking */
        this.hasChanges = this.hasChanges || !areArrayEquals(Array.from(newErrors), Array.from(original), { sort: false })

        return newErrors
    }

    /**
     * @returns null if the mapping has had no changes
     */
    remapWithErrors (mapping: CybusDetailedMapping): CybusDetailedMapping | null {
        /** Reset change tracking */
        this.hasChanges = false

        const copy: CybusDetailedMapping = {
            ...mapping,
            entries: mapping.entries.map((entry) => ({
                ...entry,
                subscribe: entry.subscribe.map<CybusPubSub>((subscribe) => ({
                    ...subscribe,
                    subscriptionErrors: this.remapSubscriptionError(subscribe.topics, subscribe.subscriptionErrors),
                })),
                publish: {
                    ...entry.publish,
                    subscriptionErrors: this.remapSubscriptionError(entry.publish.topics, entry.publish.subscriptionErrors),
                },
            })),
        }

        return this.hasChanges ? copy : null
    }
}
