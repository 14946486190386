import type { CybusEndpoint } from '../../../../../../domain'

import { CybusEndpointMapper, type EndpointProxyParams, mapToStatusType } from '../../../../../Connectware'
import { ENDPOINT_CLASSNAME_FILTER, PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER } from '../../../../constants'
import type { EndpointProxy, ResourceStateListenerProxy } from '../../../../proxies'
import { createProxyEventsHandler, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceDomainMapperArgs, type VrpcInstanceMapper } from '..'

/** Retrieve the content on the pattern */
const ENDPOINT_PATTERN = ENDPOINT_CLASSNAME_FILTER.toString().replace(/\//g, '')

class MapCybusEndpointMapper extends CybusEndpointMapper {
    readonly endpoints = new Map<string, CybusEndpoint>()

    protected collect (endpoint: CybusEndpoint): void {
        this.endpoints.set(endpoint.id, endpoint)
    }
}

class VrpcResourceStateListenerEndpointProxyInstanceMapper implements VrpcInstanceMapper<ResourceStateListenerProxy, CybusEndpoint> {
    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ResourceStateListenerProxy>('state', 'registered', 'unregistered')

    async mapToDomain ({
        instance,
        filter: { connection, service },
    }: VrpcInstanceDomainMapperArgs<ResourceStateListenerProxy>): Promise<Map<string, CybusEndpoint>> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance
            .getParams<EndpointProxy>(ENDPOINT_PATTERN, { serviceIds: service ? [service] : undefined, connectionIds: connection ? [connection] : undefined })
            .then((params: EndpointProxyParams[]) => {
                const mapper = new MapCybusEndpointMapper({})

                /**
                 * @deprecated update code block so status is fetched externally
                 * @see https://cybusio.atlassian.net/browse/CC-1251
                 */
                params.forEach((params) => mapper.push(params, mapToStatusType(params.currentState)))

                return mapper.endpoints
            })
    }
}

export class VrpcResourceStateListenerEndpointProxyInstanceHandler implements VrcpEntityHandler<ResourceStateListenerProxy, CybusEndpoint> {
    readonly instanceMapper = new VrpcResourceStateListenerEndpointProxyInstanceMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: PROTOCOL_MAPPER_RESOURCE_STATE_LISTENER, supportedFilters: ['connection', 'service'] }
}
