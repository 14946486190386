import type { ReadonlyRecord } from '../../../utils'

export type EndpointProxyParams = Readonly<{
    id: string
    /**
     * @deprecated this will no longer be relevant once RST is introduced
     * @todo consider no longer using this
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    currentState: string
    targetState: string

    /** @see https://docs.cybus.io/docs/1.5.0/user/services/structure/resources/endpoint.html#protocol */
    protocol:
        | 'Ads'
        | 'Bacnet'
        | 'EthernetIp'
        | 'GenericVrpc'
        | 'Hbmdaq'
        | 'Heidenhain'
        | 'Http'
        | 'Modbus'
        | 'Mqtt'
        | 'Mssql'
        | 'Opcda'
        | 'Opcua'
        | 'Profinet'
        | 'S7'
        | 'Shdr'
        | 'Sinumerik'
        | 'Sopas'
        | 'Sql'
        | 'Werma'
        | string

    operation: 'read' | 'subscribe' | 'write'

    connectionId?: string

    address: ReadonlyRecord<string, unknown>
    topicPrefix: string

    rules?: unknown[]

    /** @todo finish mapping */
    /** @todo move to backend @see https://cybusio.atlassian.net/browse/CYB-3713 */
}>
