import type { Intersection, PromiseType, ValuesType } from 'utility-types'

import { entries, type ExtendedMap, isArrayNotEmpty, type ReadonlyRecord } from '../../../../utils'

import type { Capability, CybusPersistedPermission } from '../../../../domain'
import type { AuthenticationResponse, SessionResponse } from '../../../../application'

import {
    capabilities,
    type OrchestratorResponse,
    type PermissionRequirement,
    unavailableByContainerImplementation,
    unavailableMfaConfiguration,
} from '../../../Connectware'
import type { SessionResponse as BackendSessionResponse, LoginResponse } from '../Types'
import { mapPermissions } from './Permission'

class Alternative {
    private static matches (permission: CybusPersistedPermission, requirement: PermissionRequirement): boolean {
        return (
            // Context matches
            requirement.context === permission.context &&
            // Resource is not required or matches
            (requirement.resource === null || requirement.resource === permission.resource) &&
            // Has read permission superior or equal to the required
            permission.read >= requirement.read &&
            // Has write permission superior or equal to the required
            permission.write >= requirement.write
        )
    }

    constructor (private requirements: PermissionRequirement[]) {}

    collect (permission: CybusPersistedPermission): boolean {
        this.requirements = this.requirements.filter((requirement) => !Alternative.matches(permission, requirement))
        return !isArrayNotEmpty(this.requirements)
    }
}

class PermissionCollector {
    private readonly alternatives: Alternative[]

    constructor (readonly capability: Capability, requirements: PermissionRequirement[][]) {
        this.alternatives = requirements.map((alternative) => new Alternative(alternative))
    }

    /**
     * @returns if it's done collecting
     */
    collect (permission: CybusPersistedPermission): boolean {
        return this.alternatives.some((a) => a.collect(permission))
    }
}

const mapPermissionsRequirements = (inputPermissions: CybusPersistedPermission[]): Capability[] => {
    const foundCapabilities: Capability[] = []

    let permissionsCollectors = entries(capabilities).map(([permission, requirements]) => new PermissionCollector(permission, requirements))

    inputPermissions.every((permission) => {
        permissionsCollectors = permissionsCollectors.filter((collector) => {
            if (!collector.collect(permission)) {
                /**
                 * Not done collecting
                 */
                return true
            }

            /**
             * Collector has matched all rules
             * So add them and remove this from the next iteration
             */
            foundCapabilities.push(collector.capability)

            return false
        })

        return Boolean(permissionsCollectors.length)
    })

    return Array.from(new Set(foundCapabilities))
}

export type CapabilityCheckers = Readonly<{ orchestrator: () => Promise<OrchestratorResponse['orchestrator'] | null>, isMfaActive: () => Promise<boolean> }>

/** This type forces the correct assigning of checkers */
type CheckersConstraints<T extends ReadonlyRecord<string, ValuesType<CapabilityCheckers>>> = {
    [P in keyof T]: ExtendedMap<PromiseType<ReturnType<T[P]>>, Capability[]>
}

const constraints: CheckersConstraints<CapabilityCheckers> = { orchestrator: unavailableByContainerImplementation, isMfaActive: unavailableMfaConfiguration }

const hasCapabilitiesToCheck = (indexedCapabilities: Set<Capability>, mayBeRemovedMap: Map<unknown, Capability[]>): boolean => {
    for (const capabilities of mayBeRemovedMap.values()) {
        if (capabilities.some((capability) => indexedCapabilities.has(capability))) {
            /** There are capabilities present that require to check if backend can perform them  */
            return true
        }
    }

    return false
}

const resolveCapabilities = (capabilities: Capability[], checkers: CapabilityCheckers): Promise<Capability[]> => {
    /** Can be used for multiple checks without performance degradation */
    const indexedCapabilities = new Set(capabilities)

    return (
        Promise.all(
            entries(constraints).map(([promiseGetterName, mayBeRemovedMap]) => {
                if (!hasCapabilitiesToCheck(indexedCapabilities, mayBeRemovedMap)) {
                    /** There is nothing to remove, so don't perform checks */
                    return Promise.resolve()
                }

                /**
                 * With the key it is possible to retrieve
                 * which capabilities need to be dropped
                 * as the backend cannot support them
                 */
                const keyPromise = checkers[promiseGetterName]()

                return keyPromise.then((key) => {
                    const castMap: ExtendedMap<typeof key, Capability[]> = mayBeRemovedMap

                    /** Finally drop them */
                    castMap.getDefault(key, []).forEach((c) => indexedCapabilities.delete(c))
                })
            })
        )
            /** Wait for everyone to resolve, then return capabilities that are left */
            .then(() => Array.from(indexedCapabilities))
    )
}

type LoginResponseWithToken = Extract<LoginResponse, Readonly<{ token: string }>>

const mapAuthentication = (
    { permissions, expiresAt }: Intersection<LoginResponseWithToken, BackendSessionResponse>,
    checkers: CapabilityCheckers
): Promise<Intersection<AuthenticationResponse, SessionResponse>> => {
    const capabilities = mapPermissionsRequirements(mapPermissions(permissions))
    return resolveCapabilities(capabilities, checkers).then((capabilities) => ({ capabilities, expiresAt: new Date(expiresAt) }))
}

export const mapLoginResponse = ({ token, ...args }: LoginResponseWithToken, checkers: CapabilityCheckers): Promise<AuthenticationResponse> =>
    mapAuthentication(args, checkers).then((response) => ({ ...response, token }))

export const mapSessionResponse = ({ username, ...args }: BackendSessionResponse, checkers: CapabilityCheckers): Promise<SessionResponse> =>
    mapAuthentication(args, checkers).then((response) => ({ ...response, username }))
