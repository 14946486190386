import type { ReadonlyRecord } from '../../../../utils'
import {
    type ClientRegistryRequest,
    type ClientRegistryRequestForm,
    ConnectwareError,
    ConnectwareErrorType,
    type CybusRole,
    selectUsersManagementRegistryRequest,
} from '../../../../domain'

import { UserFormUsecase } from '../User'
import { LoadingUsecase } from '../../Loading'

export class AllowClientRegistryRequestUsecase extends UserFormUsecase<ClientRegistryRequestForm> {
    protected readonly selected = 'users'

    protected readonly formName = 'registryRequest'

    protected readonly selectForm = selectUsersManagementRegistryRequest

    protected async request (): Promise<void> {
        const { username, mqttPublishPrefix, authenticationMethods, roles, permissions } = this.getCurrentForm()
        await this.clientRegistryService.authorize({ username, mqttPublishPrefix, authenticationMethods, roles, permissions })
    }

    selectRoles (input: CybusRole[]): void {
        const { request, roles, allRoles } = this.getCurrentForm()

        /** Retrieve all available roles */
        const indexedRoles = [...(Array.isArray(allRoles) ? allRoles : []), ...roles].reduce<ReadonlyRecord<CybusRole['name'], CybusRole>>(
            (r, role) => ({ ...r, [role.name]: role }),
            {}
        )

        /** The input of the user + required selection */
        const selection = input.reduce((r, { name }) => r.add(name), new Set(request.roles))

        super.selectRoles(
            Array.from(selection, (name) => {
                const role = indexedRoles[name]
                if (!role) {
                    throw new ConnectwareError(ConnectwareErrorType.STATE, 'Could not find role by name', { name })
                }
                return role
            })
        )
    }

    async load (request: ClientRegistryRequest): Promise<void> {
        const loadindUsecase = this.getUsecase(LoadingUsecase)
        await loadindUsecase.withLoading(
            Promise.all(request.roles.map((roleName) => this.userService.fetchRole(roleName))).then((selectedRoles) =>
                this.initializeForm({
                    username: request.username,
                    authenticationMethods: [request.authenticationMethod],
                    roles: selectedRoles,
                    permissions: request.permissions,
                    mqttPublishPrefix: null,
                    request,

                    ...AllowClientRegistryRequestUsecase.derivedRolesValues,
                    ...AllowClientRegistryRequestUsecase.derivedTemplateValues,
                })
            )
        )
    }
}
