import type { PickByValueExact } from 'utility-types'

import { capture, type EventListener, type ReadonlyRecord } from '../../../utils'

import { Capability, ConnectwareError, ConnectwareErrorType, ResourceType, Translation } from '../../../domain'
import type { ConnectwareResourcesService, TranslationService } from '../../../application'

import { type ConnectwareHTTPServiceOptions, FetchConnectwareHTTPService, type HttpRequestArgs, type HttpRequestBody } from '../Base'
import type {
    ConnectionOperationRequest,
    DockerContainerOperationRequest,
    EndpointOperationRequest,
    MappingOperationRequest,
    ServerOperationRequest,
    ServiceOperationRequest,
} from '../../Connectware'

type Action = keyof PickByValueExact<ConnectwareResourcesService, (type: ResourceType, ids: string[]) => Promise<void>>
type ResourceRequestConfig<ReqBody extends HttpRequestBody> = Pick<HttpRequestArgs<ReqBody, never>, 'method' | 'path' | 'pathParams' | 'body' | 'capability'>
type RequestsConfig = [requests: ResourceRequestConfig<ReadonlyRecord<string, unknown>>[], errorTranslation: Translation] | null

const strategies: ReadonlyRecord<ResourceType, ((action: Action, ids: string[]) => RequestsConfig) | null> = {
    [ResourceType.SERVICE]: (action, ids) => {
        switch (action) {
            case 'disable':
            case 'enable':
                return [
                    ids.map<ResourceRequestConfig<ServiceOperationRequest>>((id) => ({
                        capability: Capability.SERVICES_MANAGE,
                        method: 'PUT',
                        path: '/api/services/+/operation',
                        pathParams: [id],
                        body: { operation: action === 'enable' ? 'enable' : 'disable' },
                    })),
                    action === 'enable' ? Translation.BULK_ACTION_ENABLE_SERVICES_ERROR : Translation.BULK_ACTION_DISABLE_SERVICES_ERROR,
                ]
            case 'delete':
                return [
                    ids.map<ResourceRequestConfig<never>>((id) => ({
                        capability: Capability.SERVICES_MANAGE,
                        method: 'DELETE',
                        path: '/api/services/+',
                        pathParams: [id],
                    })),
                    Translation.BULK_ACTION_DELETE_SERVICES_ERROR,
                ]
            default:
                return null
        }
    },
    [ResourceType.ENDPOINT]: (action, ids) => {
        switch (action) {
            case 'disable':
            case 'enable':
                return [
                    ids.map<ResourceRequestConfig<EndpointOperationRequest>>((id) => ({
                        capability: Capability.ENDPOINTS_MANAGE,
                        method: 'PUT',
                        path: '/api/endpoints/+/operation',
                        pathParams: [id],
                        body: { operation: action === 'enable' ? 'enable' : 'disable' },
                    })),
                    action === 'enable' ? Translation.BULK_ACTION_ENABLE_ENDPOINTS_ERROR : Translation.BULK_ACTION_DISABLE_ENDPOINTS_ERROR,
                ]
            default:
                return null
        }
    },
    [ResourceType.CONNECTION]: (action, ids) => {
        switch (action) {
            case 'disable':
            case 'enable':
                return [
                    ids.map<ResourceRequestConfig<ConnectionOperationRequest>>((id) => ({
                        capability: Capability.CONNECTIONS_MANAGE,
                        method: 'PUT',
                        path: '/api/connections/+/operation',
                        pathParams: [id],
                        body: { operation: action === 'enable' ? 'connect' : 'disconnect' },
                    })),
                    action === 'enable' ? Translation.BULK_ACTION_CONNECT_CONNECTIONS_ERROR : Translation.BULK_ACTION_DISCONNECT_CONNECTIONS_ERROR,
                ]
            default:
                return null
        }
    },
    [ResourceType.MAPPING]: (action, ids) => {
        switch (action) {
            case 'disable':
            case 'enable':
                return [
                    ids.map<ResourceRequestConfig<MappingOperationRequest>>((id) => ({
                        capability: Capability.MAPPINGS_MANAGE,
                        method: 'PUT',
                        path: '/api/mappings/+/operation',
                        pathParams: [id],
                        body: { operation: action === 'enable' ? 'enable' : 'disable' },
                    })),
                    action === 'enable' ? Translation.BULK_ACTION_ENABLE_MAPPINGS_ERROR : Translation.BULK_ACTION_DISABLE_MAPPINGS_ERROR,
                ]
            default:
                return null
        }
    },
    [ResourceType.CORE_CONTAINER]: (action, ids) => {
        switch (action) {
            case 'enable':
            case 'disable':
            case 'reenable':
                return [
                    ids.map<ResourceRequestConfig<DockerContainerOperationRequest>>((id) => ({
                        capability: Capability.CORE_CONTAINERS_MANAGE,
                        method: 'PUT',
                        path: '/api/core-containers/+/operation',
                        pathParams: [id],
                        body: { operation: action === 'enable' ? 'start' : action === 'disable' ? 'stop' : 'restart' },
                    })),
                    action === 'enable'
                        ? Translation.BULK_ACTION_ENABLE_CONTAINERS_ERROR
                        : action === 'disable'
                        ? Translation.BULK_ACTION_DISABLE_CONTAINERS_ERROR
                        : Translation.BULK_ACTION_RE_ENABLE_CONTAINERS_ERROR,
                ]
            default:
                return null
        }
    },
    [ResourceType.SERVICE_CONTAINER]: (action, ids) => {
        switch (action) {
            case 'enable':
            case 'disable':
            case 'reenable':
                return [
                    ids.map<ResourceRequestConfig<DockerContainerOperationRequest>>((id) => ({
                        capability: Capability.SERVICE_CONTAINERS_MANAGE,
                        method: 'PUT',
                        path: '/api/containers/+/operation',
                        pathParams: [id],
                        body: { operation: action === 'enable' ? 'start' : action === 'disable' ? 'stop' : 'restart' },
                    })),
                    action === 'enable'
                        ? Translation.BULK_ACTION_ENABLE_CONTAINERS_ERROR
                        : action === 'disable'
                        ? Translation.BULK_ACTION_DISABLE_CONTAINERS_ERROR
                        : Translation.BULK_ACTION_RE_ENABLE_CONTAINERS_ERROR,
                ]
            default:
                return null
        }
    },
    [ResourceType.SERVER]: (action, ids) => {
        switch (action) {
            case 'enable':
            case 'disable':
                return [
                    ids.map<ResourceRequestConfig<ServerOperationRequest>>((id) => ({
                        capability: Capability.SERVERS_MANAGE,
                        method: 'PUT',
                        path: '/api/servers/+/operation',
                        pathParams: [id],
                        body: { operation: action === 'enable' ? 'enable' : 'disable' },
                    })),
                    action === 'enable' ? Translation.BULK_ACTION_ENABLE_SERVERS_ERROR : Translation.BULK_ACTION_DISABLE_SERVERS_ERROR,
                ]
            default:
                return null
        }
    },
    [ResourceType.AGENT]: null,
    [ResourceType.VOLUME]: null,
}

export class ConnectwareHTTPResourcesService extends FetchConnectwareHTTPService implements ConnectwareResourcesService {
    constructor (
        options: ConnectwareHTTPServiceOptions,
        private readonly translationService: TranslationService,
        private readonly changeDoneListeners: Pick<EventListener<void>, 'trigger'>
    ) {
        super(options)
    }

    private async makeRequest (type: ResourceType, action: Action, ids: string[]): Promise<void> {
        const configs = strategies[type]?.(action, ids)

        if (!configs) {
            return Promise.reject(new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Not implemented.', { type, action, ids }))
        }

        const [requestConfigs, error] = configs

        await Promise.all(requestConfigs.map((args) => this.request({ ...args, authenticate: true, handlers: { 200: () => Promise.resolve() } }))).catch(
            ({ extras }: ConnectwareError) => {
                const translatedError = new ConnectwareError(
                    ConnectwareErrorType.SERVER_ERROR,
                    this.translationService.translate(error, { count: requestConfigs.length }),
                    extras
                )
                capture(translatedError, this[action])
                throw translatedError
            }
        )

        /**
         * Signal changes to who sent this listener
         */
        this.changeDoneListeners.trigger()
    }

    delete (type: ResourceType, ids: string[]): Promise<void> {
        return this.makeRequest(type, 'delete', ids)
    }

    disable (type: ResourceType, ids: string[]): Promise<void> {
        return this.makeRequest(type, 'disable', ids)
    }

    enable (type: ResourceType, ids: string[]): Promise<void> {
        return this.makeRequest(type, 'enable', ids)
    }

    reenable (type: ResourceType, ids: string[]): Promise<void> {
        return this.makeRequest(type, 'reenable', ids)
    }
}
