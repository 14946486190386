import { createExtendedState } from 'react-extended-state'
import { useMemo } from 'react'

import { type PickByValue, type PickByValueExact } from 'utility-types'

import { areRecordsEquals, createArrayComparator, createEqualityChecker, entries, isArrayNotEmpty, nameFunction, type ReadonlyRecord } from '../../../../utils'

import {
    type CommissioningFileField,
    type CommissioningFileFields,
    CommissioningFileFieldType,
    type CommissioningFileFinder,
    type CommissioningFileValidatedValue,
    type CommissioningFileValidatedValues,
    type CommissioningFileValue,
    type CommissioningFileValues,
    ConnectionsCommissioningFileFinder,
    ConnectwareError,
    ConnectwareErrorType,
    EndpointsCommissioningFileFinder,
    MetadataCommissioningFileFinder,
} from '../../../../domain'

/** The state that should be read from to render the component */
type CommissioningFileState = Readonly<{ fields: CommissioningFileFields, values: CommissioningFileValidatedValues }>

const createCommissioningFileStateSelector =
    <P extends keyof CommissioningFileState,>(prop: P): ((s: CommissioningFileState) => CommissioningFileState[P]) =>
    (s) =>
        s[prop]
const selectFields = createCommissioningFileStateSelector('fields')
const selectValues = createCommissioningFileStateSelector('values')

const { Provider: CommissioningFileProvider, useExtendedState } = createExtendedState<CommissioningFileState>()

export { CommissioningFileProvider }

/** Helps to sort two fields */
const fieldSorter = <F extends CommissioningFileField, E extends [name: string, field: F],>([aName, aField]: E, [bName, bField]: E): number => {
    /** First, prioritize STRING_INDEX type fields */
    const stringIndexPriority =
        Number(Boolean(bField.type === CommissioningFileFieldType.STRING_INDEX)) - Number(Boolean(aField.type === CommissioningFileFieldType.STRING_INDEX))

    if (stringIndexPriority !== 0) {
        return stringIndexPriority
    }

    /** Next, compare based on field name depth (less nested comes first) */
    const depthA = aName.split('.').length
    const depthB = bName.split('.').length
    if (depthA !== depthB) {
        return depthA - depthB
    }

    /** Then, sort by optionality (non-optional fields come first) */
    const optionalA = Number(aField.optional)
    const optionalB = Number(bField.optional)
    if (optionalA !== optionalB) {
        return optionalA - optionalB
    }

    /** Finally, if all previous criteria are same, sort fields alphabetically by their name */
    return aName.localeCompare(bName)
}

/**
 * This recursive functions allows quick traversal of the fields
 * And convert it to a plain object
 */
class FieldsFlattener<T,> {
    private flattened: ReadonlyRecord<string, T> = {}

    constructor (private readonly mapper: (field: CommissioningFileField) => T, private readonly resolve: (a: T, b: T) => T) {}

    feed (additions: ReadonlyRecord<string, CommissioningFileField>): this {
        entries(additions).forEach(([name, field]) => {
            const current = this.flattened[name]
            const mapped = this.mapper(field)
            this.flattened = { ...this.flattened, [name]: current ? this.resolve(current, mapped) : mapped }

            if (field.type === CommissioningFileFieldType.OPTIONS) {
                Object.values(field.options).forEach((fields) => this.feed(fields))
            }
        })

        return this
    }

    retrieve (): ReadonlyRecord<string, T> {
        return this.flattened
    }
}

export type FieldData<F extends CommissioningFileField = CommissioningFileField,> = CommissioningFileValidatedValue<F> & Readonly<{ field: F }>
const areFieldDatumEquals = createEqualityChecker<FieldData>({ field: null, value: null, validation: null })
export const isFieldDataOfType = <F extends CommissioningFileField,>(data: FieldData, type: F['type']): data is FieldData<F> => data.field.type === type

const stringArrayComparator = createArrayComparator<string>()
export const useMetadataFieldNames = (): (keyof CommissioningFileState['values']['metadata'])[] =>
    useExtendedState(
        (s) =>
            Object.entries(selectFields(s).metadata)
                .sort(fieldSorter)
                .map(([n]) => n),
        stringArrayComparator
    )

const createFinderHook = <Args extends unknown[], Field extends CommissioningFileField, HandledErrors extends ConnectwareErrorType | never,>(
    Finder: new (fields: ReturnType<typeof selectFields>, values: ReturnType<typeof selectValues>) => CommissioningFileFinder<
        Args,
        Field,
        [CommissioningFileValidatedValues, CommissioningFileValidatedValue<Field>] | [CommissioningFileValues, CommissioningFileValue<Field>]
    >,
    ignoreErrors: HandledErrors[]
): ((...args: Args) => FieldData<Field> | (HandledErrors extends ConnectwareErrorType ? null : never)) =>
    nameFunction(
        (...args) =>
            useExtendedState(
                (s) => {
                    try {
                        const finder = new Finder(selectFields(s), selectValues(s)) as CommissioningFileFinder<
                            Args,
                            Field,
                            [CommissioningFileValidatedValues, CommissioningFileValidatedValue<Field>]
                        >
                        const [field, { value, validation }] = finder.find(...args)
                        return { field, value, validation }
                    } catch (e) {
                        if (!isArrayNotEmpty(ignoreErrors) || !ConnectwareError.isOfTypes(e, ...ignoreErrors)) {
                            throw e
                        }

                        /**
                         * Expected error, so just sent null value
                         */
                        return null as FieldData<Field> | (HandledErrors extends ConnectwareErrorType ? null : never)
                    }
                },
                args,
                (a, b) => Boolean(a !== null && b !== null && areFieldDatumEquals(a, b))
            ),
        `use${Finder.name}`
    )

export const useMetadata = createFinderHook(MetadataCommissioningFileFinder, [])
export const useConnection = createFinderHook(ConnectionsCommissioningFileFinder, [ConnectwareErrorType.STATE])
export const useEndpoint = createFinderHook(EndpointsCommissioningFileFinder, [ConnectwareErrorType.STATE])

export const useResourceFieldsNames = <
    V extends keyof PickByValue<CommissioningFileValidatedValues, ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>>[]>,
    F extends keyof PickByValueExact<CommissioningFileFields, ReadonlyRecord<string, CommissioningFileField>>,
>(
    resourcesValues: V,
    resourceField: F
): ReadonlyRecord<string, boolean> => {
    const resourceFields = useExtendedState((s) => selectFields(s)[resourceField], [resourceField])

    /** Get all fields indexed by name, list them as required if there is a conflict */
    const resourceFieldsRequiredness = useMemo(
        () =>
            new FieldsFlattener(
                (f) => f,
                (a, b) => (a.optional > b.optional ? a : b)
            )
                .feed(resourceFields)
                .retrieve(),
        [resourceFields, resourceField]
    )

    /**
     * Iterate through the currently used fields,
     * and create a subset of fields as actually being used
     */
    const usedFields = useExtendedState(
        (s) => {
            const usedFields: Record<string, CommissioningFileField> = {}

            const resources = selectValues(s)[resourcesValues] as ReadonlyRecord<string, CommissioningFileValidatedValue<CommissioningFileField>>[]

            resources.forEach((resource) =>
                Object.keys(resource).forEach((propName) => {
                    usedFields[propName] = resourceFieldsRequiredness[propName] as CommissioningFileField
                })
            )

            return usedFields
        },
        areRecordsEquals,
        [resourceFieldsRequiredness, resourcesValues]
    )

    /** Finally sort the fields and yield a simpler object */
    return useMemo(
        () =>
            Object.entries(usedFields)
                .sort(fieldSorter)
                .reduce<ReturnType<typeof useResourceFieldsNames>>((r, [name, field]) => {
                    return { ...r, [name]: field.optional }
                }, {}),
        [usedFields]
    )
}

export const useResourcesCount = <K extends keyof PickByValue<CommissioningFileValidatedValues, unknown[]>,>(resourceName: K): number =>
    useExtendedState(
        (s) => {
            const { [resourceName]: values } = selectValues(s)
            return (values as unknown[]).length
        },
        [resourceName]
    )
