import type { ContainerManagerOrchestratorProxy, DockerProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    type UnsubFromInstanceFunction,
    type VrpcInstanceChangeArgs,
    type VrpcInstanceDomainMapperArgs,
    type VrpcInstanceMapper,
} from '..'
import { fetchImplementationResolver } from './Implementation'

export abstract class VrpcContainerManagerOrchestratorProxyBaseMapper<T> implements VrpcInstanceMapper<ContainerManagerOrchestratorProxy, T> {
    private readonly listenToDocker = createProxyEventsHandler<DockerProxy>('dockerEvent')

    abstract mapToDomain (args: VrpcInstanceDomainMapperArgs<ContainerManagerOrchestratorProxy>): Promise<T | Map<string, T>>

    /**
     * Deals with the setup of the container manager listeners
     */
    async onChange (args: VrpcInstanceChangeArgs<ContainerManagerOrchestratorProxy>): Promise<UnsubFromInstanceFunction | void> {
        const resolver = await fetchImplementationResolver(args.instance)

        if (resolver.isDocker(args.instance)) {
            /**
             * Docker has events to listen
             * @deprecated this handling will be dropped
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            return this.listenToDocker(args as VrpcInstanceChangeArgs<typeof args.instance>)
        }
    }
}
