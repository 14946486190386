import { entries, type ReadonlyRecord } from '../../../utils'
import { ConnectwareError, ConnectwareErrorType, Translation } from '../../../domain'
import type { TranslationService } from '../../../application'
import type { PasswordPolicy, UsernamePolicy } from './Types'

class Validator<Policy extends ReadonlyRecord<string, number>> {
    constructor (private readonly translation: Translation, private readonly translationService: TranslationService) {}

    private validatePolicy (policy: Policy, value: string, type: keyof Policy, scoreOrScoreExtractor: number | RegExp): string | ConnectwareError | null {
        const { [type]: scoreRequirement = 0 } = policy

        if (scoreRequirement === 0) {
            /**
             * No need to check
             * As there is no requirement
             */
            return null
        }

        /**
         * The translated validation message
         */
        const message = this.translationService.translate(this.translation, { count: scoreRequirement, type })

        /**
         * The actual score of the field
         */
        const score = typeof scoreOrScoreExtractor === 'number' ? scoreOrScoreExtractor : scoreOrScoreExtractor.exec(value)?.length || 0

        if (score >= scoreRequirement) {
            /**
             * The score is enough
             */
            return message
        }

        /**
         * Score is not enough
         */
        return new ConnectwareError(ConnectwareErrorType.GENERAL_BUSINESS_RULE_INFRACTION, message)
    }

    async validate (
        policyPromise: Promise<Policy>,
        value: string,
        rules: ReadonlyRecord<keyof Policy, number | RegExp>
    ): Promise<(string | ConnectwareError)[]> {
        const policy = await policyPromise

        return entries(rules).reduce<(string | ConnectwareError)[]>((r, [rule, scoreOrScoreExtractor]) => {
            const result = this.validatePolicy(policy, value, rule, scoreOrScoreExtractor)
            return result ? [...r, result] : r
        }, [])
    }
}

/**
 * Utility class to centralize username and password validation
 */
export class PolicyValidator {
    private readonly usernameValidator: Validator<UsernamePolicy>

    private readonly passwordValidator: Validator<PasswordPolicy>

    constructor (translationService: TranslationService) {
        this.usernameValidator = new Validator(Translation.USER_NAME_VALIDATION, translationService)
        this.passwordValidator = new Validator(Translation.PASSWORD_VALIDATION, translationService)
    }

    validatePassword (policy: Promise<PasswordPolicy>, password: string): Promise<(string | ConnectwareError)[]> {
        return this.passwordValidator.validate(policy, password, {
            min: password.length,
            symbol: /\p{Symbol}|\p{Punctuation}/gu,
            lower: /\p{Lowercase_Letter}/gu,
            upper: /\p{Uppercase_Letter}/gu,
            numeric: /\p{Number}/gu,
        })
    }

    validateUsername (policy: Promise<UsernamePolicy>, username: string): Promise<(string | ConnectwareError)[]> {
        return this.usernameValidator.validate(policy, username, { min: username.length })
    }
}
