import type { ReadonlyRecord } from '../../../../../utils'
import type { SubscriptionFilterArgs } from '../../../../../application'

import { mapResourceNames } from '../../../../Connectware'
import type { VrpcDomainType } from '../../..'
import type { ManagedVrpcRemote } from '../../../utils'

type VrpcHandlerBaseConfiguration = Readonly<{
    /**
     * The class name used to filter out irrelevant instances
     */
    classNameFilter: RegExp | string

    /**
     * The instance name used to filter out irrelevant instance
     */
    instanceNameFilter: RegExp | string

    /**
     * The agent of the desired instances
     *
     * If null then will atemp to read **all** agents
     */
    agent: string

    /**
     * If any extra remote with custom domains need to be added for this handler to tackle
     */
    domains: VrpcDomainType[]

    /**
     * This ought to be defined on every handler
     * Mapping out what types of filters it supports
     */
    supportedFilters: (keyof SubscriptionFilterArgs)[]

    /**
     * Some instances follow a pattern that makes it useful to exclude their loading by their name
     * This function enables this behaviour
     */
    excludeByInstanceName: (instanceName: string, args: SubscriptionFilterArgs) => boolean
}>

export type VrpcHandlerConfiguration =
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'agent'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'agent' | 'instanceNameFilter'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'instanceNameFilter'>
    | Pick<VrpcHandlerBaseConfiguration, 'classNameFilter' | 'supportedFilters' | 'instanceNameFilter' | 'excludeByInstanceName'>
    // Configuration for remote based handlers
    | Pick<VrpcHandlerBaseConfiguration, 'domains' | 'supportedFilters'>

type UnsubFunction<Args extends unknown[]> = (...args: Args) => Promise<void> | void

/**
 * @param isGone if the unsub function was called because the instance was removed
 * @throws `Error`, convertion to `ConnectwareError` is expected to be done in `mapError`
 */
export type UnsubFromInstanceFunction = UnsubFunction<[isGone: boolean]>

export type VrpcInstanceDomainMapperArgs<VrpcInstance> = Readonly<{ instance: VrpcInstance, instanceName: string, filter: SubscriptionFilterArgs }>

export type VrpcInstanceChangeArgs<VrpcInstance> = Readonly<{ instance: VrpcInstance, listener: VoidFunction }>

/**
 * This mapper helps the Instance Manager to convert VRPC instances into domain instances
 */
export interface VrpcInstanceMapper<VrpcInstance, Domain> {
    /**
     * @description will be called once initially, and after every `onChange` call
     * @returns
     *  the domain specific entity or a Map of entities
     *
     *  if a map is yielded, then it will cause a replacement of other entities
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly mapToDomain: (args: VrpcInstanceDomainMapperArgs<VrpcInstance>) => Promise<Domain | Map<string, Domain>>

    /**
     * @param listener function, should be called once there is an internal change on the vrpc instance
     * @returns Promise of an unsub method (Which also yields a promise, look around simba, everything this interface touches is async 🦁)
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly onChange?: (args: VrpcInstanceChangeArgs<VrpcInstance>) => Promise<UnsubFromInstanceFunction | void>
}

export type UnsubFromRemoteFunction = UnsubFunction<[]>

export type VrpcRemoteDomainMapperArgs = Readonly<{ remote: ManagedVrpcRemote }>

export type VrpcRemoteChangeArgs = Readonly<{ remote: ManagedVrpcRemote, listener: VoidFunction }>

/**
 * This mapper helps the Remote Manager to convert the VRPCRemote into domain entities
 */
export interface VrpcRemoteMapper<Domain> {
    /**
     * @description will be called once initially, and after every `onChange` call
     * @returns
     *  the domain specific entity or a Map of entities
     *
     *  if a map is yielded, then it will cause a replacement of other entities
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly mapToDomain: (args: VrpcRemoteDomainMapperArgs) => Promise<Domain | Map<string, Domain>>

    /**
     * @param listener function, should be called once there is an internal change on the vrpc remote
     * @returns Promise of an unsub method (Which also yields a promise, look around simba, everything this interface touches is async 🦁)
     * @throws `Error`, convertion to `ConnectwareError` is expected to be done outside
     */
    readonly onChange?: (args: VrpcRemoteChangeArgs) => Promise<UnsubFromRemoteFunction>
}

export type VrcpEntityHandler<VrpcInstance, Domain> = Readonly<{
    configuration: VrpcHandlerConfiguration
    remoteMapper: VrpcRemoteMapper<Domain> | null
    instanceMapper: VrpcInstanceMapper<VrpcInstance, Domain> | null
}>

type ListenerInstance<EventName extends string> = ReadonlyRecord<'on' | 'off', (eventNames: EventName, handler: VoidFunction) => Promise<unknown>>
type ListenerEvents<Instance> = Instance extends ListenerInstance<infer E> ? E : never

export const createProxyEventsHandler = <Instance extends ListenerInstance<any & string>>(
    ...eventNames: ListenerEvents<Instance>[]
): ((args: VrpcInstanceChangeArgs<Instance>) => Promise<UnsubFromInstanceFunction>) => {
    /**
     * Create listener
     */
    return async ({ instance, listener }) => {
        /**
         * Start actually listening
         */

        /** Listen to all events */
        await Promise.all(eventNames.map((eventName) => instance.on(eventName, listener)))

        return (isGone) => {
            /**
             * Time to drop listeners
             */
            if (isGone) {
                /**
                 * @see https://i.imgur.com/MPbX6OD.jpg
                 *
                 * No need to drop anything
                 */
                return Promise.resolve()
            }

            /** Drop all listeners */
            return Promise.all(eventNames.map((eventName) => instance.off(eventName, listener))).then(() => Promise.resolve())
        }
    }
}

/**
 * This function filters out the instances to be loaded by their name
 * If their name is part of a service that is being filtered out
 * It will exclude it
 */
export const excludeInstanceByServiceName = (instance: string, { service }: SubscriptionFilterArgs): boolean => {
    if (!service) {
        return false
    }
    const [serviceName] = mapResourceNames(instance)
    return service !== serviceName
}
