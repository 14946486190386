import { SERVICE_AGENT } from '../../../../../Connectware'

import { SERVICE_CLASSNAME } from '../../../../constants'
import type { ServiceProxy } from '../../../../proxies'
import {
    createProxyEventsHandler,
    type VrcpEntityHandler,
    type VrpcHandlerConfiguration,
    type VrpcInstanceDomainMapperArgs,
    type VrpcInstanceMapper,
} from '../Handler'

export abstract class BaseServiceMapper<T> implements VrpcInstanceMapper<ServiceProxy, T> {
    /**
     * @deprecated these handlings will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<ServiceProxy>('state', 'deviation')

    abstract mapToDomain (args: VrpcInstanceDomainMapperArgs<ServiceProxy>): Promise<T | Map<string, T>>
}

export abstract class BaseVrpcServiceProxyInstanceHandler<T> implements VrcpEntityHandler<ServiceProxy, T> {
    abstract readonly instanceMapper: VrpcInstanceMapper<ServiceProxy, T>

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: SERVICE_CLASSNAME, agent: SERVICE_AGENT, supportedFilters: [] }
}
