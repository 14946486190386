import type { GetInstanceOptions, InstanceInfo } from 'vrpc'

import { EventListener } from '../../../../../utils'

import type { SubscriptionData, SubscriptionsTypes } from '../../../../../application'

import type { VrpcRemoteManager } from '../../../utils'
import { type InstanceAddress, RemotesManager, VrpcInstancesManager } from '../managers'
import { VrpcSubscription } from './Base'

export abstract class VrpcEntitiesSubscription<T extends keyof SubscriptionsTypes> extends VrpcSubscription<T> {
    private readonly changeListeners = new EventListener<SubscriptionData<T>[]>()

    private readonly instanceManager: VrpcInstancesManager<T> | null

    private readonly remoteManager: RemotesManager<T> | null

    protected static mapInstanceAddress (instanceName: string, options?: InstanceInfo | GetInstanceOptions): InstanceAddress {
        const agent = options?.agent || null
        const className = options?.className

        return typeof className === 'string' ? [agent, className, instanceName] : instanceName
    }

    constructor (eventName: T, remote: VrpcRemoteManager) {
        super(eventName, remote)

        /**
         * Setup the manager of those instances
         */
        this.instanceManager = this.instanceMapper ? new VrpcInstancesManager(this.instanceMapper, (...args) => this.changeListeners.trigger(...args)) : null
        this.remoteManager = this.remoteMapper ? new RemotesManager(this.remoteMapper, (...args) => this.changeListeners.trigger(...args)) : null
    }

    protected withInstanceManager<R> (callback: (instanceManager: VrpcInstancesManager<T>) => R): R | void {
        if (this.instanceManager) {
            return callback(this.instanceManager)
        }
    }

    protected withRemoteManager<R> (callback: (remoteManager: RemotesManager<T>) => R): R | void {
        if (this.remoteManager) {
            return callback(this.remoteManager)
        }
    }

    protected async onDropped (): Promise<void> {
        await Promise.all([this.withInstanceManager((manager) => manager.drop()), this.withRemoteManager((manager) => manager.drop())])
    }

    onChange (handler: (data: SubscriptionData<T>[]) => void): this {
        this.changeListeners.on(handler)
        return this
    }
}
