import type { UnionToIntersection } from 'utility-types'

export type ExcludeValues<T, E> = { [P in keyof T]: Exclude<T[P], E> }

export type NullableValues<T, K extends keyof T = keyof T> = Omit<T, K> & { [P in keyof T & K]: T[P] | null }

export type NonNullableValues<T, K extends keyof T = keyof T> = Omit<T, K> & { [P in keyof T & K]: NonNullable<T[P]> }

export type NullValues<T> = { [P in keyof T]: null }

export type ObjectOrArray<T> = T | T[]

export type ArrayType<T extends Array<unknown> | readonly unknown[]> = T extends Array<infer U> ? U : never

export type ArrayIntersection<A extends Array<unknown>> = ArrayType<A>[]

export type NonEmptyArray<T> = [T, ...T[]] & T[]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ReadonlyRecord<K extends keyof any, T> = Readonly<Record<K, T>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialReadonlyRecord<K extends keyof any, T> = Partial<ReadonlyRecord<K, T>>

/**
 * This type extracts all possible keys of an Union
 *
 * It works based on UnionToIntersection, but since
 * UnionToIntersection does not allow for mismatched unions to become
 * an impossible intersection (so we can extract all the keys all of it)
 * we first then need to make all of the keys to have a common type
 * the easiest to get to being to use Partial
 *
 * @see https://github.com/piotrwitek/utility-types/issues/192
 */
export type AllUnionKeys<U> = keyof UnionToIntersection<Partial<U>>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SubProperty<T, P extends keyof any> = T extends PartialRecord<P, unknown> ? T[P] : never
