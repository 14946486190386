import type { PickByValueExact } from 'utility-types'

import { Capability, ConnectwareError, ConnectwareErrorType, type StatusType } from '../../../../../../domain'

import type { SubscriptionFilterArgs, SubscriptionsTypes } from '../../../../../../application'

import {
    ContainersListFetcher,
    type DockerContainerResponse,
    type DockerContainersResponse,
    type KubernetesContainerResponse,
    mapToStatusType,
    type OrchestratorResponse,
} from '../../../../../Connectware'

import { type BaseStrategyRequestArgs, BaseSubscriptionStrategy } from '../Base'

type Config = Pick<BaseStrategyRequestArgs<never, never>, 'path' | 'method' | 'capability'>

class ServiceContainersListFetcher extends ContainersListFetcher {
    constructor (
        isDocker: Promise<boolean>,
        filter: SubscriptionFilterArgs,
        private readonly config: [containers: Config, inspect: Config],
        private readonly request: <R, D>(args: BaseStrategyRequestArgs<R, D>) => Promise<D>
    ) {
        super(isDocker, filter)
    }

    protected fetchContainersResponse (): Promise<DockerContainersResponse> {
        return this.request({ ...this.config[0], mapper: (data: DockerContainersResponse) => data })
    }

    protected fetchContainerMetadata<T extends DockerContainerResponse | KubernetesContainerResponse> (name: string): Promise<T | null> {
        return this.request({ ...this.config[1], pathParams: [name], mapper: (data: T) => data })
    }
}

abstract class ContainerStrategy<T extends keyof SubscriptionsTypes> extends BaseSubscriptionStrategy<T> {
    /** Cache of the orchestrator */
    private isDocker: Promise<boolean> | null = null

    protected abstract readonly containersConfig: [containers: Config, inspect: Config, orchestrator: Config]
    protected abstract readonly inspectConfig: Config

    /**
     * The mapper that maps the response of single retrieval
     * If left null, it will cause the `retrieveOne` function to yield a not implemented error
     */
    protected readonly mapSingleRetrieval:
        | ((id: string, data: DockerContainerResponse | KubernetesContainerResponse, status: StatusType) => SubscriptionsTypes[T])
        | null = null

    /**
     * Method name to fetch the containers
     * If left null, it will cause the `retrieveAll` function to yield a not implemented error
     */
    protected readonly fetchAllMethod: keyof PickByValueExact<(typeof ContainersListFetcher)['prototype'], () => Promise<SubscriptionsTypes[T][]>> | null = null

    retrieveAll (filter: SubscriptionFilterArgs): Promise<SubscriptionsTypes[T][]> {
        const { fetchAllMethod } = this

        if (!fetchAllMethod) {
            /** Not operating in fetch list mode */
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Method not implemented', { method: 'retrieveAll', filter })
        }

        /** Finally call if the docker is running */
        this.isDocker = this.isDocker || this.retrieve({ ...this.containersConfig[2], mapper: (data: OrchestratorResponse) => data.orchestrator === 'Docker' })

        /** Create fetcher to fetch the list */
        const fetcher = new ServiceContainersListFetcher(this.isDocker, filter, [this.containersConfig[0], this.containersConfig[1]], (args) =>
            this.retrieve(args)
        )

        return fetcher[fetchAllMethod]() as Promise<SubscriptionsTypes[T][]>
    }

    retrieveOne (id: string): Promise<SubscriptionsTypes[T]> {
        const { mapSingleRetrieval } = this

        if (!mapSingleRetrieval) {
            /** Not operating in fetch single mode */
            throw new ConnectwareError(ConnectwareErrorType.UNEXPECTED, 'Method not implemented', { id, method: 'retrieveOne' })
        }

        return this.retrieve({
            ...this.inspectConfig,
            pathParams: [id],
            mapper: (data: DockerContainerResponse | KubernetesContainerResponse) => {
                const status = mapToStatusType(data.State.Status?.toLowerCase())

                return mapSingleRetrieval(id, data, status)
            },
            handleNotFound: true,
        })
    }
}

export abstract class BaseCoreContainerStrategy<T extends keyof SubscriptionsTypes> extends ContainerStrategy<T> {
    protected readonly containersConfig: [containers: Config, inspect: Config, orchestrator: Config] = [
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.CORE_CONTAINERS_READ, method: 'GET', path: '/api/core-containers' },
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.CORE_CONTAINERS_READ, method: 'GET', path: '/api/core-containers/+/inspect' },
        { capability: Capability.CORE_CONTAINERS_READ, method: 'GET', path: '/api/core-containers/orchestrator' },
    ]

    protected readonly inspectConfig: Config = { capability: Capability.CORE_CONTAINER_READ, method: 'GET', path: '/api/core-containers/+/inspect' }
}

export abstract class BaseServiceContainerStrategy<T extends keyof SubscriptionsTypes> extends ContainerStrategy<T> {
    protected readonly containersConfig: [containers: Config, inspect: Config, orchestrator: Config] = [
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.SERVICE_CONTAINERS_READ, method: 'GET', path: '/api/containers' },
        /**
         * @deprecated this endpoint will no longer provide useful statuses
         * @todo consider using another endpoint
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        { capability: Capability.SERVICE_CONTAINERS_READ, method: 'GET', path: '/api/containers/+/inspect' },
        { capability: Capability.SERVICE_CONTAINERS_READ, method: 'GET', path: '/api/containers/orchestrator' },
    ]

    protected readonly inspectConfig: Config = { capability: Capability.SERVICE_CONTAINER_READ, method: 'GET', path: '/api/containers/+/inspect' }
}
