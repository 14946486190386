import { areArrayEquals, createArrayComparator, createEqualityChecker, entries, isArrayNotEmpty, isObjectEmpty, Unique } from '../../utils'

import {
    type AppState,
    type AvailableTopic,
    indexExplorerFilteredResources,
    indexExplorerFilteredTypes,
    selectExplorerFilteredResources,
    selectExplorerFilteredTypes,
    selectExplorerTopics,
    type SystemTopicSource,
    type TopicPath,
    type TopicSubscriptionError,
    TopicType,
} from '..'

type RelevantTopic = Pick<AvailableTopic, 'path' | 'source' | 'selected' | 'subscriptionErrors'>

export type TopicTree = Readonly<{
    /** The whole path, to be used for actual selection */
    path: AvailableTopic['path']
    source: AvailableTopic['source'] | null
    subscriptionErrors: Set<TopicSubscriptionError>
    /** The path label, to be used for rendering */
    label: AvailableTopic['path']
    selected: AvailableTopic['selected'] | null
    children: TopicTree[]
}>

class TopicTreeBuilder {
    private readonly children: Record<string, TopicTreeBuilder> = {}

    private value: RelevantTopic | null = null

    constructor (private readonly depth: number = 0) {}

    protected build (previous: TopicPath): TopicTree {
        if (this.value) {
            const { path, source, selected, subscriptionErrors } = this.value
            return { path, label: path, source, subscriptionErrors: new Set(subscriptionErrors), selected, children: [] }
        }

        let hasSourcelessChild = false
        let hasCustomChild = false
        const resourceSources: Record<string, SystemTopicSource> = {}

        let subscriptionErrors: TopicSubscriptionError[] = []

        const uniqueSelected = new Unique<TopicTree['selected']>()
        const children: TopicTree['children'] = []

        for (const [top, child] of entries(this.children)) {
            const exported = child.build([...previous, top])

            /** Decide selection */
            uniqueSelected.add(exported.selected)
            /** Decide subscription error type */
            subscriptionErrors = [...subscriptionErrors, ...exported.subscriptionErrors]

            /** Decide topic source */
            switch (exported.source) {
                case null:
                    hasSourcelessChild = true
                    break
                case TopicType.CUSTOM:
                    hasCustomChild = true
                    break
                default:
                    exported.source.forEach((s) => (resourceSources[s.id] = s))
            }

            children.push(exported)
        }

        if (children[0] && children.length === 1) {
            /** There is only once children, so pass it instead */
            return children[0]
        }

        const sources = Object.values(resourceSources)

        return {
            path: previous,
            label: previous,
            source: hasSourcelessChild || (hasCustomChild && isArrayNotEmpty(sources)) ? null : hasCustomChild ? TopicType.CUSTOM : sources,
            subscriptionErrors: new Set(subscriptionErrors),
            selected: uniqueSelected.getUniqueValue(null),
            children: children
                /** Trim from the children, what is already on the parent's path */
                .map((c) => ({ ...c, label: c.path.slice(previous.length, c.path.length) }))
                .sort((cA, cB) => (cA.label > cB.label ? 1 : -1)),
        }
    }

    add (topic: RelevantTopic): void {
        const topPath = topic.path[this.depth]

        if (typeof topPath === 'string') {
            const builder = this.children[topPath] ?? new TopicTreeBuilder(this.depth + 1)
            this.children[topPath] = builder
            builder.add(topic)
        }

        /**
         * Update the actual value
         * If there are children, then this topic no longer has value
         * Otherwise, it is the end of the line and should keep the value
         */
        this.value = isObjectEmpty(this.children) ? topic : null
    }
}

class RootTopicTreeBuilder extends TopicTreeBuilder {
    private static readonly rootPath: AvailableTopic['path'] = []

    buildTrees (): TopicTree[] {
        const root = this.build(RootTopicTreeBuilder.rootPath)

        /**
         * If root is being returned, it is irrelevant
         * then return the children,
         * otherwise, return the root
         */
        return root.path === RootTopicTreeBuilder.rootPath ? root.children : [root]
    }
}

export const selectExplorerTopicTrees = (appState: Pick<AppState, 'explorer'>): TopicTree[] => {
    const areResourcesSelected = indexExplorerFilteredResources(selectExplorerFilteredResources(appState))
    const areTypesSelected = indexExplorerFilteredTypes(selectExplorerFilteredTypes(appState))
    const topics = selectExplorerTopics(appState)

    const root = new RootTopicTreeBuilder()
    topics.forEach((topic) => {
        /**
         * Topics are displayed if
         *
         * - They are service related and their type is selected or resource is being searched
         * - They are custom and the custom type is selected
         */
        if (Array.isArray(topic.source) ? topic.source.some((s) => areTypesSelected(s.type) || areResourcesSelected(s.id)) : areTypesSelected(topic.source)) {
            root.add(topic)
        }
    })

    return root.buildTrees()
}

const areSystemTopicSourceEquals = createEqualityChecker<SystemTopicSource>({ id: null, type: null })

const areTopicTreeEquals = createEqualityChecker<TopicTree>({
    subscriptionErrors: (a, b) => areArrayEquals(Array.from(a), Array.from(b), { sort: false }),
    selected: null,
    path: createArrayComparator(),
    source: (a, b) => Array.isArray(a) && Array.isArray(b) && areArrayEquals(a, b, { sort: false, equals: areSystemTopicSourceEquals }),
    label: createArrayComparator(),
    children: createArrayComparator((a: TopicTree, b: TopicTree): boolean => areTopicTreeEquals(a, b)),
})

export const areTopicTreesEquals = (a: TopicTree[], b: TopicTree[]): boolean => areArrayEquals(a, b, { sort: false, equals: areTopicTreeEquals })
