import { areArrayEquals, ChangeEventListener, Droppable, EventListener, isArrayNotEmpty, type NonEmptyArray, type ReadonlyRecord } from '../../utils'
import { ConnectwareError, ConnectwareErrorType, type TopicMessageEvent, type TopicPath, type TopicSubscriptionResponse, Translation } from '../../domain'

import type { TopicsService, TopicsSubscription } from '../../application'
import { type MqttErrorFromWorker, type MqttTopicsServiceArgs, type SerializableMqttTopicMessageEvent, TopicMatchingHelper } from '.'

const errorMap: ReadonlyRecord<MqttErrorFromWorker, ConnectwareErrorType> = {
    genericError: ConnectwareErrorType.SERVER_ERROR,
    couldNotReconnect: ConnectwareErrorType.UNEXPECTED,
    couldNotConnect: ConnectwareErrorType.CONFIGURATION_ERROR,
    state: ConnectwareErrorType.UNEXPECTED,
}

const errorMessageMap: ReadonlyRecord<MqttErrorFromWorker, Translation> = {
    genericError: Translation.MQTT_GENERIC_ERROR,
    couldNotReconnect: Translation.MQTT_GENERIC_ERROR,
    couldNotConnect: Translation.MQTT_COULD_NOT_CONNECT_ERROR,
    state: Translation.MQTT_GENERIC_ERROR,
}

type ArgsGetter = () => MqttTopicsServiceArgs

const deserializeTopicMessageEvent = ({ payload, ...line }: SerializableMqttTopicMessageEvent): TopicMessageEvent => ({
    ...line,
    payload: Buffer.from(payload),
})

type TopicSubscriptionPayload = Readonly<{ id: number, topics: string[] }>

class MqttTopicsSubscription implements TopicsSubscription {
    private readonly droppable = new Droppable()

    private readonly messages = new EventListener<NonEmptyArray<TopicMessageEvent>>()

    private readonly errors = new EventListener<ConnectwareError>()

    private readonly topics = new ChangeEventListener<TopicSubscriptionPayload>({ id: NaN, topics: [] })

    private readonly subscriptions = new EventListener<[id: number, response: TopicSubscriptionResponse]>()

    private subscriptionId = 0

    constructor (argsGetter: ArgsGetter) {
        const { url, username, password, worker, timeout, cycle, maxTopicDepth } = argsGetter()

        /** Connect the created worker */
        worker.send({ action: 'connect', url: url.toString(), username, password, timeout, cycle, maxTopicDepth })

        /** Setup drop listener, once droped, disconnect mqtt and terminate worker */
        this.droppable.onDrop(() => {
            worker.send({ action: 'disconnect' })
            worker.terminate()
        })

        /** If there is a change in topics being subscribed, update them */
        this.droppable.onDrop(this.topics.onChange(([, { id, topics }]) => worker.send({ action: 'subscribe', topics, id })))

        /** Handles mqtt errors and new incoming message batches */
        this.droppable.onDrop(
            worker.listen((data) => {
                switch (data.action) {
                    case 'messages':
                        // The worker can take some time to load batches...
                        if (isArrayNotEmpty(data.batch) && areArrayEquals(data.topics, this.topics.value.topics)) {
                            // ...If you are here, it means the batch has relevant topics to the currently subscribed topics
                            this.messages.trigger(data.batch.map(deserializeTopicMessageEvent) as NonEmptyArray<TopicMessageEvent>)
                        }
                        break
                    case 'error':
                        this.errors.trigger(
                            new ConnectwareError(
                                errorMap[data.type],
                                errorMessageMap[data.type],
                                data.type === 'couldNotConnect' ? { url, username, timeout } : { originalMessage: data.message }
                            )
                        )
                        /** Stop the presses */
                        this.end()
                        break
                    case 'subscription':
                        this.subscriptions.trigger([data.id, data.response])
                        break
                }
            })
        )
    }

    onBatch (listener: (batch: NonEmptyArray<TopicMessageEvent>) => void): VoidFunction {
        return this.messages.on(listener)
    }

    onLastMessage (listener: (message: TopicMessageEvent) => void): VoidFunction {
        return this.onBatch((batch) => listener(batch[batch.length - 1] as TopicMessageEvent))
    }

    onError (listener: (error: ConnectwareError) => void): VoidFunction {
        return this.errors.on(listener)
    }

    subscribe (topics: string[]): Promise<TopicSubscriptionResponse> {
        return new Promise((resolve) => {
            /** Define id that will be used to identify returning messages */
            const id = ++this.subscriptionId

            /** Setup drop listener flow */
            const droppable = new Droppable()
            this.droppable.onDrop(() => droppable.drop())

            /** Setup a new listener that will wait for a subscription response */
            droppable.onDrop(
                this.subscriptions.on(([returnId, response]) => {
                    if (id !== returnId) {
                        return
                    }

                    /** Matched, drop this listener */
                    droppable.drop()

                    /** Finalize promise */
                    resolve(response)
                })
            )

            /** Finally trigger the topics to change */
            this.topics.change({ id, topics })
        })
    }

    end (): void {
        return this.droppable.drop()
    }
}

export class MqttTopicsService implements TopicsService {
    private readonly helper = new TopicMatchingHelper()

    constructor (private readonly argsGetter: ArgsGetter) {}

    isSubscribeable (topic: string, sources: string[]): boolean {
        return this.helper.isSubscribeable(topic, sources)
    }

    /** This can't be static as {MqttTopicsService} needs to implement TopicsService */
    // eslint-disable-next-line class-methods-use-this
    isExact (path: TopicPath): boolean {
        return !path.includes('#') && !path.includes('+')
    }

    /** This can't be static as {MqttTopicsService} needs to implement TopicsService */
    // eslint-disable-next-line class-methods-use-this
    parseTopic (topic: string): TopicPath {
        return topic.split('/')
    }

    create (): TopicsSubscription {
        return new MqttTopicsSubscription(this.argsGetter)
    }

    isPrefix (prefix: string, topics: string[]): boolean {
        return this.helper.isPrefix(prefix, topics)
    }
}
