import type { CybusNode } from '../../../../../../domain'

import { CybusNodeMapper, mapToStatusType } from '../../../../../Connectware'

import { NODE_CLASSNAME_FILTER } from '../../../../constants'
import type { NodeProxy } from '../../../../proxies'
import { createProxyEventsHandler, type VrcpEntityHandler, type VrpcHandlerConfiguration, type VrpcInstanceDomainMapperArgs, type VrpcInstanceMapper } from '..'

class MapCybusNodeMapper extends CybusNodeMapper {
    readonly nodes = new Map<string, CybusNode>()

    protected collect (node: CybusNode): void {
        this.nodes.set(node.id, node)
    }
}

class VrpcNodeProxyToCybusNodeMapper implements VrpcInstanceMapper<NodeProxy, CybusNode> {
    /**
     * @deprecated this handling will be dropped
     * @see https://cybusio.atlassian.net/browse/CC-1251
     */
    readonly onChange = createProxyEventsHandler<NodeProxy>('state')

    mapToDomain ({ instance, filter }: VrpcInstanceDomainMapperArgs<NodeProxy>): Promise<Map<string, CybusNode>> {
        /**
         * @deprecated this method will no longer provide useful statuses
         * @todo consider using another method of fetching them
         * @see https://cybusio.atlassian.net/browse/CC-1251
         */
        return instance.getParams().then((params) => {
            /**
             * @deprecated update code block so status is fetched externally
             * @see https://cybusio.atlassian.net/browse/CC-1251
             */
            const currentStatus = mapToStatusType(params.currentState)

            const mapper = new MapCybusNodeMapper(filter)
            mapper.push(params, currentStatus)
            return mapper.nodes
        })
    }
}

export class VrpcNodeProxyToCybusNodeHandler implements VrcpEntityHandler<NodeProxy, CybusNode> {
    readonly instanceMapper = new VrpcNodeProxyToCybusNodeMapper()

    readonly remoteMapper = null

    readonly configuration: VrpcHandlerConfiguration = { classNameFilter: NODE_CLASSNAME_FILTER, supportedFilters: ['server'] }
}
